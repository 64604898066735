import ReactAudioPlayer from 'react-audio-player';
import React from "react";

const AudioPlayer = ({selectedAudio}) => {

    const handleOnPlay = () => {

    }

    return(
        <div className="flex item-center justify-center mb-6">
            <ReactAudioPlayer
                className=""
                src={selectedAudio}
                controls
                onPlay={handleOnPlay}
            />
        </div>
    )
}
export default AudioPlayer;