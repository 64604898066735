import { fabric } from 'fabric';

export class StaticInput extends fabric.Group {
  static type = 'input';

  initialize(options) {
    const defaultOpts = {
      width: 200,
      height: 40,
      fill: 'white',
      borderColor: 'black',
      borderWidth: 1,
      placeholder: 'Enter text...',
      fontSize: 16,
      fontFamily: 'Open Sans',
      iconFill: 'gray',
      iconScale: 0.05,
      onChange: () => {},
    };

    const {
      width,
      height,
      fill,
      borderColor,
      borderWidth,
      placeholder,
      fontSize,
      fontFamily,
      svgPath,
      iconFill,
      iconScale,
    } = { ...defaultOpts, ...options };

    const inputRect = new fabric.Rect({
      width,
      height,
      fill,
      strokeWidth: borderWidth,
      stroke: borderColor,
      rx: 3,
      ry: 3,
    });

    const icon = svgPath ? new fabric.Path(svgPath, {
      fill: iconFill,
      scaleX: iconScale,
      scaleY: iconScale,
      left: 20,
      top: height / 2,
      originX: 'center',
      originY: 'center',
    }) : null;

    const iconWidth = icon ? icon.width * icon.scaleX : 0;

    const inputField = new fabric.StaticText({
      text: placeholder,
      left: svgPath ? iconWidth + 10 : 10,
      top: height / 2,
      fontSize,
      fontFamily,
      fill: 'black',
      originY: 'center',
      originX: 'left',
      width: svgPath ? width - iconWidth - 10 : width
    });
    const textWidth = inputField.width * inputField.scaleX;
    if (svgPath) {
      inputRect.set('width', textWidth + iconWidth + 10);
    } 

    super.initialize(icon ? [inputRect, icon, inputField] : [inputRect, inputField], 
      options);
  
    this.setControlsVisibility({
      mt: false,
      mb: false,
      ml: false,
      mr: false,
      mtr: false,
    });
    // Disable scaling flip
    this.lockScalingFlip = true;
    // Add scaling event listener to lock aspect ratio
    this.on('scaling', function () {
      const w = this.width * this.scaleX;
      const h = this.height * this.scaleY;
      const aspectRatio = w / h;

      // Determine the aspect ratio based on initial scale
      if (aspectRatio > 1) {
        // Width is greater than height, lock horizontal scaling to vertical
        this.scaleX = this.scaleY;
      } else {
        // Height is greater than width, lock vertical scaling to horizontal
        this.scaleY = this.scaleX;
      }
    });
    inputField.on('changed', () => {
      this.onChange(inputField.text);
    });

    return this;
  }

  onChange(text) {
    // Custom onChange logic
    console.log('Input changed:', text);
  }

  toObject(propertiesToInclude = []) {
    return fabric.util.object.extend(
      super.toObject.call(this, propertiesToInclude),
      {}
    );
  }

  toJSON(propertiesToInclude = []) {
    return fabric.util.object.extend(
      super.toObject.call(this, propertiesToInclude),
      {}
    );
  }
}

fabric.StaticInput = fabric.util.createClass(StaticInput, {
  type: StaticInput.type,
});

if (!fabric.StaticInput) {
  fabric.StaticInput = StaticInput;
}
