import React, {useEffect, useRef, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown, faAngleUp, faSquare, faEyeDropper} from "@fortawesome/free-solid-svg-icons";
import {SketchPicker} from "react-color";
import {useSelector} from "react-redux";
import {isShape} from "../../../utils/helpers";
import { convertHexToRGB } from "../../../utils/helpers";

const BorderControl = () => {

    const {activeObject, canvas} = useSelector(state => state.fabricCanvas);
    const [borderStyle, setBorderStyle] = useState('None');
    const [borderWidth, setBorderWidth] = useState(0);
    const [borderFill, setBorderFill] = useState("rgb(0,0,0)")
    const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
    const colorPickerRef = useRef(null);

    const toggleColorPicker = () => {
        setIsColorPickerOpen((prevIsColorPickerOpen) => !prevIsColorPickerOpen);
    };

    const handleClickOutside = (event) => {
        if (colorPickerRef.current && !colorPickerRef.current.contains(event.target)) {
            setIsColorPickerOpen(false);
        }
    };

    useEffect(() => {
        if(activeObject) {
            const {borderStyle, borderWidth, borderFill, stroke, strokeWidth} = activeObject;
            if(!isShape(activeObject))setBorderStyle(borderStyle);
            setBorderWidth(isShape(activeObject)?strokeWidth:borderWidth);
            setBorderFill(isShape(activeObject)?stroke:borderFill);
        }
    }, [activeObject])

    useEffect(() => {
        // Add event listener on mount
        document.addEventListener('mousedown', handleClickOutside);

        // Remove event listener on component unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []); // Empty dependency array means this effect runs only once, on mount

    const handleBorderStyleChange = (event) => {
        const newValue = event.target.value;
        if(borderWidth === 0) {
            setBorderWidth(1);
            activeObject.set({
                borderWidth: 1
            });
        }
        activeObject.set({
            borderStyle: newValue
        });
        canvas.renderAll();
        setBorderStyle(newValue);
    }

    const handleBorderWidthChange = (event) => {
        const newValue = parseInt(event.target.value);
        if(isShape(activeObject)){
            activeObject.set({
                strokeWidth: newValue
            })
        }
        else{
            activeObject.set({
                borderWidth: newValue
            })
        }

        canvas.renderAll();
        setBorderWidth(newValue);
    }

    const handleBorderFillChange = (color) => {
        let newValue;

        // If the color is selected form eye drop api it will have sRGBHex value else rgb
        if (color.sRGBHex) {
            newValue = convertHexToRGB(color.sRGBHex);
        } else {
            newValue = color.rgb;
        }

        if(isShape(activeObject)){
            activeObject.set({
                stroke: `rgba(${newValue.r}, ${newValue.g}, ${newValue.b}, ${newValue.a})`,
            });
        }
        else{
            activeObject.set({
                borderFill: `rgba(${newValue.r}, ${newValue.g}, ${newValue.b}, ${newValue.a})`,
            });
        }
        setBorderFill(`rgba(${newValue.r}, ${newValue.g}, ${newValue.b}, ${newValue.a})`);
        canvas.renderAll();
    }

    const handleEyeDropperClick = () => {
        // eslint-disable-next-line no-undef
        const eyeDropper = new EyeDropper();
        eyeDropper.open().then((color) => {
            handleBorderFillChange(color);
        });
    };

    return (
        <div>
            <label className="mb-2 block text-xs font-medium text-gray-600">Border</label>
            <div className="mb-6 flex items-start justify-center flex-col gap-2">
                <div className="flex items-center w-fit">
                    {!isShape(activeObject) && <select
                        id="fontSelect"
                        className="flex size-10 px-2 flex-grow items-center w-[80px] justify-center rounded-l-md border bg-white text-xs"
                        onChange={handleBorderStyleChange}
                        defaultValue={'None'}
                        value={borderStyle}
                    >
                        <option key={1} value={'none'}>
                            None
                        </option>
                        <option key={2} value={'solid'}>
                            Solid
                        </option>
                        <option key={3} value={'dashed'}>
                            Dashed
                        </option>
                        <option key={4} value={'dotted'}>
                            Dotted
                        </option>
                        <option key={5} value={'double'}>
                            Double
                        </option>
                    </select>}
                    <input
                        className="flex size-10 flex-grow items-center justify-center border bg-white p-2 text-xs text-gray-600 transition-colors duration-200 rounded-r-md"
                        type="number"
                        onChange={handleBorderWidthChange}
                        value={borderWidth}
                        min={0}
                        max={15}
                    />
                </div>
                <div ref={colorPickerRef} className={`relative flex size-10 cursor-pointer items-center w-fit justify-center rounded-md border text-xs transition-colors duration-200 ${isColorPickerOpen ? 'bg-gray-100' : 'bg-white'}`}>
                    <button
                        className="flex items-center justify-center px-3"
                        onClick={toggleColorPicker}
                    >
                        <FontAwesomeIcon icon={faSquare} color={borderFill} className="mr-2 size-3"/>
                        {isColorPickerOpen && <FontAwesomeIcon icon={faAngleUp} className="size-3 text-gray-600"/>}
                        {!isColorPickerOpen && <FontAwesomeIcon icon={faAngleDown} className="size-3 text-gray-600"/>}
                    </button>
                    {isColorPickerOpen && (
                        <div className="absolute top-full right-0 mt-2 z-50">
                            <SketchPicker color={borderFill} onChange={handleBorderFillChange}/>
                        </div>
                    )}
                    <div className="h-10 flex items-center px-3 border-y border rounded-r-md">
                        <FontAwesomeIcon icon={faEyeDropper} className="size-3" onClick={handleEyeDropperClick}/>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default BorderControl;