import React from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { GoogleIcon } from "../../utils/iconHelper";
import { api } from "../../api/api";
import { useNavigate } from "react-router";
import { useAlert } from "../../context/AlertContext";

function SignupForm() {
  const navigate = useNavigate();
  const { showAlert } = useAlert();
  const validationSchema = yup.object().shape({
    username: yup.string().required("Username is required"),
    email: yup.string().email("Invalid email").required("Email is required"),
    password: yup
      .string()
      .trim()
      .required("Password is required")
      .min(6, "Password should have minimum 6 charachters"),
    confirmPassword: yup
      .string()
      .trim()
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .required("confirm Password is required"),
  });

  const formMethods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = formMethods;

  const onSubmit = async (data) => {
    try {
      const response = await api.post("/api/auth/local/register", {
        email: data.email,
        username: data.username,
        password: data.password,
      });
      if (response) {
        navigate("/login");
      }
    } catch (error) {
      showAlert("error", error?.response?.data?.error?.message);
    }
  };

  const onGoogle = async () => {
    window.open(
      `${process.env.REACT_APP_BASE_URL}/api/connect/google`,
      "_self"
    );
  };

  return (
    <div className="h-full bg-gray-100 flex flex-col justify-center">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="text-center text-3xl font-extrabold text-gray-700">
          Sign Up to your account
        </h2>
        <div className="mt-2 text-center text-sm text-gray-600 max-w">
          <p>Or</p>
          <p>
            <a
              href="/login"
              className="font-medium text-blue-600 hover:text-blue-500 block"
            >
              login to account
            </a>
          </p>
        </div>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-5">
              <label
                htmlFor="username"
                className="mb-3 block text-sm font-medium text-gray-700"
              >
                Username
              </label>
              <input
                type="text"
                name="username"
                placeholder="Email"
                {...register("username")}
                className={`appearance-none rounded-md relative block w-full px-3 py-2 border ${
                  errors.username
                    ? "border-red-500 focus:ring-red-500 focus:border-red-500"
                    : "border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                } placeholder-gray-500 text-gray-900 focus:outline-none focus:z-10 sm:text-sm`}
              />
              {errors.username && (
                <p className="text-xs text-red-500">{errors.username.message}</p>
              )}
            </div>
            <div className="mb-5">
              <label
                htmlFor="email"
                className="mb-3 block text-sm font-medium text-gray-700"
              >
                Email Address
              </label>
              <input
                type="text"
                name="email"
                placeholder="Email"
                {...register("email")}
                className={`appearance-none rounded-md relative block w-full px-3 py-2 border ${
                  errors.email
                    ? "border-red-500 focus:ring-red-500 focus:border-red-500"
                    : "border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                } placeholder-gray-500 text-gray-900 focus:outline-none focus:z-10 sm:text-sm`}
              />
              {errors.email && (
                <p className="text-xs text-red-500">{errors.email.message}</p>
              )}
            </div>
            <div className="mb-5">
              <label
                htmlFor="password"
                className="mb-3 block text-sm font-medium text-gray-700"
              >
                Password
              </label>
              <input
                type="password"
                name="password"
                placeholder="Password"
                {...register("password")}
                className={`appearance-none rounded-md relative block w-full px-3 py-2 border ${
                  errors.password
                    ? "border-red-500 focus:ring-red-500 focus:border-red-500"
                    : "border-gray-600 focus:ring-blue-500 focus:border-blue-500"
                } placeholder-gray-500 text-gray-500 focus:outline-none focus:z-10 sm:text-sm`}
              />
              {errors.password && (
                <p className="text-xs text-red-500">
                  {errors.password.message}
                </p>
              )}
            </div>
            <div className="mb-5">
              <label
                htmlFor="password"
                className="mb-3 block text-sm font-medium text-gray-700"
              >
               Confirm Password
              </label>
              <input
                type="password"
                name="confirm-password"
                placeholder="Confirm Password"
                {...register("confirmPassword")}
                className={`appearance-none rounded-md relative block w-full px-3 py-2 border ${
                  errors.confirmPassword
                    ? "border-red-500 focus:ring-red-500 focus:border-red-500"
                    : "border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                } placeholder-gray-500 text-gray-900 focus:outline-none focus:z-10 sm:text-sm`}
              />
              {errors.confirmPassword && (
                <p className="text-xs text-red-500">
                  {errors.confirmPassword.message}
                </p>
              )}
            </div>
            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Sign Up
              </button>
            </div>
          </form>
          <div className="mt-4">
            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300"></div>
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-2 bg-gray-100 text-gray-500">
                  Or continue with
                </span>
              </div>
            </div>
            <div className="flex justify-center items-center mt-4">
              <button
                onClick={onGoogle}
                className="w-screen flex items-center justify-center px-8 py-1 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
              >
                <GoogleIcon />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default SignupForm;
