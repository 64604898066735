import {clearClipBoard, setClipBoard} from "../store/reducers/canvasSlice";
import { setAllMediaToSlide } from "../store/reducers/slideListSlice";
import {deleteObj, handleAddVideo, optionsToAdd, stopAllVideos} from "./helpers";
import { fabric } from 'fabric';
import { v4 as uuidv4 } from 'uuid';

export class CanvasActions {
    constructor(props) {
        const {activeSlide, dispatch, clipBoard} = props;
        const {canvas} = activeSlide;
        this.canvas = canvas || null;
        this.dispatch = dispatch || null;
        this.clipBoard = clipBoard || null;
        this.optionsToAdd = optionsToAdd;
    }

    /**
     * Gets the action type and directs it towards the relevant function
     * @param action
     */

    handleActions(action){
        if(this.canvas && this.dispatch){
            switch (action) {
                case 'Cut':
                    this.cutCopy(true);
                    break;
                case 'Copy':
                    this.cutCopy();
                    break;
                case 'Paste':
                    this.paste();
                    break;
                case 'Duplicate':
                    this.duplicate();
                    break;
                case 'Delete':
                    this.delete();
                    break;
                default:
                    return
            }
        }

    }

    /**
     * Delete the current object and copies it to the clipbord.
     * if isCut is false then it won't delete the object
     * @param isCut
     */

    cutCopy(isCut=false){
        const activeObject = this.canvas.getActiveObject();
        if (!activeObject) {
            return;
        }
        stopAllVideos(this.canvas);
        if(isCut){
            deleteObj(this.canvas);
        }

        if (activeObject.hasOwnProperty('group')) {
            this.handleGroupSelectionCopy();
        } else {
            activeObject.clone((clonedObject) => {

                if(this.isATextObject(clonedObject)) {
                    clonedObject.selectable = true;
                }

                if (activeObject.type === 'activeSelection') {
                    clonedObject._restoreObjectsState();
                }

                this.dispatch(setClipBoard(clonedObject));
            }, this.optionsToAdd);
        }
    }

    /**
     * Get the object from the clipboard if available and copies it to
     * canvas and clears the clipboard
     */
    paste(){
        const copiedObject  = this.clipBoard;
        if (!this.clipBoard) {
            return;
        }
        stopAllVideos(this.canvas);
        if ((copiedObject && copiedObject.hasOwnProperty('group')) || copiedObject.length > 1) {
            this.handleGroupSelectionPaste();
        } else if (copiedObject.type === 'activeSelection') {
            this.canvas.discardActiveObject();
            for (let i = 0; i < copiedObject._objects.length; i++) {
                if(copiedObject._objects[i].type === 'image' && copiedObject._objects[i].name === 'video'){
                    this.addStaticVideo(copiedObject._objects[i])
                }
                else{
                    copiedObject._objects[i].clone((clonedObject) => {
                        this.addClonedObject(clonedObject);
                    }, this.optionsToAdd);
                }

            }
            this.canvas.renderAll();
        } else {
            if(copiedObject.type === 'image' && copiedObject.name === 'video'){
                this.addStaticVideo(copiedObject)
            }
            else{
                copiedObject.clone((clonedObject) => {
                    this.addClonedObject(clonedObject);
                }, this.optionsToAdd);
            }
        }
        this.dispatch(clearClipBoard())
    }

    addStaticVideo(obj){
        let videoSrc = obj.videoSrc;
        handleAddVideo(videoSrc, this.canvas, obj, this.dispatch)
    }

    /**
     * Deletes the selected object
     */
    delete(){
        deleteObj(this.canvas, this.dispatch)
    }

    /**
     * Duplicates the selected fabric js object by making its clone using built-in
     * clone funtionality of fabric js
     */
    duplicate(){
        const activeObject = this.canvas.getActiveObject();
        if (!activeObject) {
            return;
        }
        stopAllVideos(this.canvas);
        if (activeObject.hasOwnProperty('group')) {
            this.handleGroupDuplicate();
        } else if (activeObject.type === 'activeSelection') {
            this.canvas.discardActiveObject();
            for (let i = 0; i < activeObject._objects.length; i++) {
                if(activeObject._objects[i].type === 'image' && activeObject._objects[i].type === 'video'){
                    this.addStaticVideo(activeObject._objects[i])
                }
                else{
                    activeObject._objects[i].clone((clonedObject) => {
                        this.addClonedObject(clonedObject);
                    }, this.optionsToAdd);
                }
            }
        } else {
            if(activeObject.type === 'image' && activeObject.name === 'video'){
                this.addStaticVideo(activeObject)
            }       
            else if (activeObject.type === 'card') {
                const id= uuidv4();
                const card = new fabric.StaticCard(
                  {
                    id,
                    top: activeObject.top + 30,
                    left: activeObject.left + 30,
                    label: activeObject.label,
                    labelLong: activeObject.labelLong,
                    fill: activeObject.fill,
                    color: activeObject.color,
                    fontWeight: activeObject.fontWeight,
                    cardType: activeObject.cardType,
                    type: 'card',
                    cardImg: activeObject.cardImg,
                    slideId: activeObject?.slideId,
                  },
                  this.canvas
                ); 
                this.dispatch(setAllMediaToSlide({ type: 'card', data: { label: activeObject.label, id } }));
                this.canvas.add(card);
                this.canvas.renderAll();
              } 
            else{
                activeObject.clone((clonedObject) => {
                    if(this.isATextObject(clonedObject)) {
                        clonedObject.selectable = true;
                    }
                    clonedObject.type = activeObject.type
                    this.addClonedObject(clonedObject);
                }, this.optionsToAdd);
            }
        }
    }

    /**
     * Duplicates the objects if multiple objects are selected together
     * @param optionsToAdd
     */

     handleGroupDuplicate = (optionsToAdd=[]) => {
         const activeObject = this.canvas.getActiveObject();
        const fabricObjectList = [...activeObject.group._objects];
        this.canvas.discardActiveObject().renderAll();
        fabricObjectList.forEach((obj) => {
            obj.clone((clonedObject) => {
                this.addClonedObject(clonedObject);
            }, optionsToAdd);
        });
    };

    /**
     * If the multiple objects in the form of group exist in clipboard,
     * then the function is used to paste all objects on canvas
     */
    handleGroupSelectionPaste = () => {
        const activeObject = this.canvas.getActiveObject();
        if (activeObject) {
            this.canvas.discardActiveObject().renderAll();
        }
        this.clipBoard.forEach((copiedObject) => {
            copiedObject.clone((clonedObject) => {
                this.addClonedObject(clonedObject);
            }, this.optionsToAdd);
        });
    };
    /**
     * Copies multiple objects selected together onto clipboard
     * @param optionsToAdd
     */

    handleGroupSelectionCopy = (optionsToAdd=[]) => {
        const activeObject = this.canvas.getActiveObject()
        const fabricObjectList = [...activeObject.group._objects];
        const copiedFabricObjectList = [];
        fabricObjectList.forEach((obj) => {
            obj.clone((clonedObject) => {
                copiedFabricObjectList.push(clonedObject);
                if (fabricObjectList.length === copiedFabricObjectList.length) {
                    console.log(copiedFabricObjectList);
                    this.dispatch(setClipBoard(copiedFabricObjectList));
                }
            }, optionsToAdd);
        });
    };

    /**
     * Adds the object on canvas
     * @param obj
     */
    addClonedObject(obj){
        if(this.isATextObject(obj) || obj.type === 'line') {
            obj.setControlsVisibility({
                mt: false,
                mb: false,
                tl: false,
                tr: false,
                bl: false,
                br: false,

            })
        }
        const {left, top} = obj;
        obj.set({left:left+30,top:top+30})
        this.canvas.add(obj)
    }

    /**
     * Check the objects type
     * @param object
     * @returns {boolean}
     */

    isATextObject = (object) => {
        return object.type === "i-text" ||
            object.type === "textbox" ||
            object.type === "text";
    }

}