import React from 'react';
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
const Spinner = ()=>{
    return(
        <div className="flex h-[295px] justify-center items-center overflow-hidden">
            <FontAwesomeIcon icon={faSpinner} className="animate-spin text-gray-600 size-8 text-xs mt-2"/>
        </div>
    )
}
export default Spinner;