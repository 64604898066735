import React, { useEffect, useState } from "react";
import { useAuth, useCheckSubscription } from "../../../context/AuthContext";
import { LogoutIcon } from "../../../utils/iconHelper";
import { useNavigate, useLocation } from "react-router-dom";

const Topbar = () => {
  const { isAuthenticated, logout, user } = useAuth();
  const checkSubscription = useCheckSubscription();
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (isAuthenticated && user) {
      checkSubscription(user).then((isActive) => {
        if (isActive) {
          setIsActive(true);
        }
      });
    }
  }, [isAuthenticated, user, checkSubscription]);

  useEffect(() => {
    const handleHashChange = () => {
      if (location.hash) {
        const section = document.getElementById(location.hash.substring(1));
        if (section) {
          section.scrollIntoView({ behavior: "smooth" });
        }
      }
    };

    window.addEventListener("hashchange", handleHashChange);

    // Call the handler initially in case the component mounts with a hash
    handleHashChange();

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, [location]);

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  const scrollToSection = (id) => {
    if (location.pathname !== "/") {
      navigate(`/#${id}`);
    } else {
      const section = document.getElementById(id);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <nav className="bg-white shadow-lg">
        <div className="container mx-auto px-4">
          <div className="flex justify-between items-center h-16">
            <div className="flex-shrink-0">
              <a href="/" className="text-xl font-bold flex items-center">
                {/* <img src="/logo.png" alt="Logo" className="h-8 w-8 mr-2" style={{ width: "120px" }} /> */}
                AILMS.co
              </a>
            </div>
            <div className="hidden md:flex items-center space-x-4"> {/* Standard menu for desktop */}
              <a href="/" className="text-gray-800 hover:text-gray-600">Home</a>
              <a href="#about" className="text-gray-800 hover:text-gray-600">About</a>
              <a href="#pricing" className="text-gray-800 hover:text-gray-600">Pricing</a>
              <a href="/docs" className="text-gray-800 hover:text-gray-600">Docs</a>
              {isAuthenticated ? (
                <>
                  {!isActive && (
                    <a
                      href="/subscription-details"
                      className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded transition duration-300"
                    >
                      Buy Subscription
                    </a>
                  )}
                  <button
                    onClick={handleLogout}
                    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300 flex"
                  >
                    <LogoutIcon
                      fontSize="small"
                      style={{ marginRight: "8px" }}
                    />
                    Logout
                  </button>
                </>
              ) : (
                <>
                  <a
                    href="/login"
                    className="text-gray-800 hover:text-gray-600"
                  >
                    Login
                  </a>
                  <a
                    href="/signup"
                    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300"
                  >
                    Sign Up
                  </a>
                </>
              )}
            </div>
            <div className="flex md:hidden">
              <button onClick={toggleMenu} className="focus:outline-none">
                <svg
                  className="h-6 w-6 text-gray-800 hover:text-gray-600"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path d="M4 6h16M4 12h16m-7 6h7"></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
        {/* Mobile Menu */}
        <div className={`md:hidden ${menuOpen ? 'block' : 'hidden'}`}>
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <a
              href="/"
              onClick={() => scrollToSection("home")}
              className="block px-3 py-2 rounded-md text-base font-medium text-gray-800 hover:text-gray-600"
            >
              Home
            </a>
            <a
              href="#about"
              onClick={() => scrollToSection("about")}
              className="block px-3 py-2 rounded-md text-base font-medium text-gray-800 hover:text-gray-600"
            >
              About
            </a>
            <a
              href="#pricing"
              onClick={() => scrollToSection("contact")}
              className="block px-3 py-2 rounded-md text-base font-medium text-gray-800 hover:text-gray-600"
            >
              Pricing
            </a>
            <a
              href="/docs"
              className="block px-3 py-2 rounded-md text-base font-medium text-gray-800 hover:text-gray-600"
            >
              Docs
            </a>
            {isAuthenticated ? (
              <>
                {!isActive && (
                  <a
                    href="/subscription-details"
                    className="block bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded transition duration-300"
                  >
                    Buy Subscription
                  </a>
                )}
                <button
                  onClick={handleLogout}
                  className="block bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300 flex"
                >
                  <LogoutIcon
                    fontSize="small"
                    style={{ marginRight: "8px" }}
                  />
                  Logout
                </button>
              </>
            ) : (
              <>
                <a
                  href="/login"
                  className="block px-3 py-2 rounded-md text-base font-medium text-gray-800 hover:text-gray-600"
                >
                  Login
                </a>
                <a
                  href="/signup"
                  className="block bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300"
                >
                  Sign Up
                </a>
              </>
            )}
          </div>
        </div>
      </nav>
    </>
  );
};

export default Topbar;
