import { useState, useEffect } from 'react';
import axios from 'axios';

const useArticles = () => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await axios.get('https://backend.ailms.co/api/articles');
        const articlesData = response.data.data.map(item => ({
          id: item.id,
          title: item.attributes.Title,
          content: item.attributes.Content.map(contentItem => contentItem.children.map(child => child.text).join(' ')).join(' '),
          slug: item.attributes.Slug,
        }));
        setArticles(articlesData);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchArticles();
  }, []);

  return { articles, loading, error };
};

export default useArticles;