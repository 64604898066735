import React, { useState, useEffect } from 'react';

const DocsPanel = () => {
    const [docs, setDocs] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedDoc, setSelectedDoc] = useState(null);

    useEffect(() => {
        fetch('https://ailms.co/wp-json/wp/v2/docs')
            .then(response => response.json())
            .then(data => setDocs(data))
            .catch(error => console.error('Error fetching documents:', error));
    }, []);

    const filteredDocs = docs.filter(doc => 
        doc.title.rendered.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="flex flex-col w-[280px] svg-box overflow-y-auto bg-gray-50 p-4">
          <label htmlFor="fontSelector" className="mb-2 block text-xs font-medium text-gray-600">Search
                    Documentation</label>
          <input
                type="text"
                placeholder="Search docs..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="mb-4 w-full rounded-md border-2 border-gray-300 p-2 text-xs focus:border-blue-500 focus:outline-none"
            />
            <ul className="space-y-2">
                {filteredDocs.map(doc => (
                    <li 
                        key={doc.id} 
                        className="cursor-pointer rounded-lg p-2 text-xs text-gray-700 hover:bg-blue-500 hover:text-white"
                        onClick={() => setSelectedDoc(doc)}
                    >
                        {doc.title.rendered}
                    </li>
                ))}
            </ul>
            {selectedDoc && (
                <div className="mt-4 rounded-md bg-white p-4 shadow">
                    <h2 className="mb-2 text-sm font-semibold">{selectedDoc.title.rendered}</h2>
                    <div dangerouslySetInnerHTML={{ __html: selectedDoc.content.rendered }} />
                </div>
            )}
        
        </div>
    );
};

export default DocsPanel;
