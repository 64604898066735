import React from "react";

const PaymentSuccessModal = ({ open, onClose }) => {
  if (!open) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white border-2 border-black shadow-lg p-4 w-96 rounded-lg">
        <h2 className="text-lg font-semibold" id="modal-title">
          Payment Success
        </h2>
        <p className="mt-2" id="modal-description">
          Your payment was processed successfully.
        </p>
        <button
          onClick={onClose}
          className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition"
        >
          Go to Dashboard
        </button>
      </div>
    </div>
  );
};

export default PaymentSuccessModal;
