import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useRef, useState} from "react";
import {setLoaderMessage, setLoaderVisibility} from "../../../store/reducers/loaderSlice";
import {setupPexelsVideoList} from "../../../utils/helpers";
import {debounce} from "lodash";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import { shallowEqual } from "react-redux";

let nextLink = '';

const PexelsVideoGallery = ({handleAddVideo}) => {
    const { activeSlide } = useSelector(state => state.slideList, shallowEqual);
    const {pexelsVideoList, nextVideoPage} = useSelector(state => state.imageBucket);
    const containerRef = useRef(null);
    const {canvas} = useSelector(state => state.fabricCanvas);
    const {visibility} = useSelector(state => state.customLoader);
    const dispatch = useDispatch();
    const [searchInput, setSearchInput] = useState('');
    const [searchLoader, setSearchLoader] = useState(false);


    useEffect(() => {
        const handleScroll = async () => {
            const container = containerRef.current;
            if (container) {
                const {scrollTop, clientHeight, scrollHeight} = container;
                // Check if the user has scrolled to the bottom
                if (scrollTop + clientHeight >= scrollHeight) {
                    if (!visibility) {
                        dispatch(setLoaderVisibility(true))
                        dispatch(setLoaderMessage('Loading more videos.'))
                        await setupPexelsVideoList(dispatch, null, nextLink);
                        dispatch(setLoaderVisibility(false))
                        dispatch(setLoaderMessage(''))
                    }
                }
            }
        };
        const container = containerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
        }

        // Remove the event listener when the component is unmounted
        return () => {
            if (container) {
                container.removeEventListener('scroll', handleScroll);
            }
        };
    }, [dispatch, visibility])

    useEffect(() => {
        nextLink = nextVideoPage;
    }, [nextVideoPage]);

    const debouncedSearch = debounce((query) => {
        setupPexelsVideoList(dispatch, query, null).then(()=> { setSearchLoader(false);});
    }, 1000);


    // Function to handle changes in the search input
    const handleSearchChange = (event) => {
        setSearchLoader(true);
        const input = event.target.value;
        const filteredInput = event.target.value.replace(/[^a-zA-Z0-9]/g, '');
        setSearchInput(input);
        debouncedSearch(filteredInput);
    };

    const clearSearch = () => {
        setSearchInput('');
        debouncedSearch('');
    }

    return (
        <>
            <div
                className="flex items-center justify-center w-full bg-white overflow-hidden border border-t-0 border-b-0">
                <div className="flex items-center justify-center w-full border rounded-md p-2 mx-2">
                    <input
                        type="text"
                        className="w-full text-gray-600 focus:outline-none text-xs"
                        placeholder="Search for videos"
                        value={searchInput}
                        onChange={handleSearchChange}
                    />
                    <button className="focus:outline-none">
                        {
                            searchLoader && (
                                <FontAwesomeIcon icon={faSpinner} className="animate-spin text-gray-600 size-3"/>
                            )
                        }
                        {
                            !searchLoader && (
                                <>
                                    {
                                        searchInput.length === 0  && <FontAwesomeIcon className="size-3 text-gray-600" icon={"search"}/>
                                    }
                                    {
                                        searchInput.length > 0  && <FontAwesomeIcon className="size-3 text-gray-600" icon={"close"} onClick={clearSearch}/>
                                    }
                                </>
                            )
                        }
                    </button>
                </div>
            </div>
            <div ref={containerRef}
                 className="svg-box flex mb-6 items-start justify-center bg-white overflow-x-hidden rounded-md border-t-0 rounded-t-none h-[165px] overflow-y-auto p-2 border">
                {pexelsVideoList && pexelsVideoList.length !== 0 && (
                    <div className="grid grid-cols-3 gap-2">
                        {pexelsVideoList.map((video, index) => (
                            <div onClick={() => handleAddVideo(video,canvas,null,dispatch, activeSlide?.id)} key={index}
                                 className="group relative size-[45px] transition-all duration-200 flex-none cursor-pointer overflow-hidden rounded-md bg-white hover:opacity-85 hover:ring ring-gray-200 active:ring-gray-300">
                                <video
                                    className="VideoInput_video"
                                    style={{display:'none'}}
                                    src={video.url}
                                />
                                <img src={video.thumbnail} alt={index}
                                     className="h-full w-full object-cover rounded-md"/>
                            </div>
                        ))}
                    </div>
                )}
                {
                    pexelsVideoList && pexelsVideoList.length === 0 && (
                        <div
                            className="flex flex-col flex-grow items-center justify-center w-full h-full text-center text-gray-600 text-xs">
                            <div className="flex flex-row items-center justify-center w-full mb-2">
                                <FontAwesomeIcon icon={"file-image"} className="flex size-10"/>
                            </div>
                            <div className="flex flex-row items-center justify-center w-full">
                                No video files found.
                            </div>
                        </div>
                    )
                }
            </div>
        </>
    )
}
export default PexelsVideoGallery;