// @ts-nocheck
import { fabric } from "fabric";
export class StaticVideoObject extends fabric.Image {
    static type = "image";
    speedFactor;
    currentTime;
    initialize(video, options) {
        console.log('initialize in initializer')
        const defaultOpts = {
            objectCaching: false,
            cacheProperties: ["time"],
        };
        options = options || {};
        let cut = {
            from: options?.cut?.from || 0,
            to: options?.cut?.to || options.totalDuration,
        };
        this.cut = cut;
        super.initialize(video, Object.assign({}, defaultOpts, {
            ...options,
            cut,
            erasable: false,
        }));
        this.setControlsVisibility({
            mt: false, // middle top disable
            mb: false, // middle bottom disable
            ml: false, // middle left disable
            mr: false, // middle right disable
            mtr: false, //rotation point
        });
        // Disable scaling flip
        this.lockScalingFlip = true;
        // Register the aspect ratio locking listener
        this.on('scaling', function() {
            const w = this.width * this.scaleX;
            const h = this.height * this.scaleY;
            const aspectRatio = w / h;

            if (aspectRatio > 1) {
                this.scaleX = this.scaleY;
            } else {
                this.scaleY = this.scaleX;
            }
        });

        return this;
    }
    _draw(video, ctx, w, h) {
        const d = {
            x: -this.width / 2,
            y: -this.height / 2,
            w: this.width,
            h: this.height,
        };
        ctx.drawImage(video, d.x, d.y, d.w, d.h);
    }
    play(tmpCanvas){
        const canvas = this.canvas || tmpCanvas;
        const videoE = this.getElement();
        videoE.play().catch(error => {
            console.error("Video play failed:", error);
        });
        canvas.isPlaying = false;
        if(!canvas.isPlaying){
            canvas.isPlaying = true;
            function render() {
                fabric.util.requestAnimFrame(function renderFrame() {
                    if (!canvas.isPlaying) {
                        return;
                    }
                    if (videoE.currentTime === videoE.duration) {
                        canvas.isPlaying = false;
                        canvas.renderAll();
                        return
                    }
                    canvas.renderAll();
                    fabric.util.requestAnimFrame(renderFrame);
                });
            }
            render()
        }
    }
    stop(tmpCanvas){
        const videoE = this.getElement();
        const canvas = this.canvas;
        videoE.pause();
        videoE.currentTime = 0;
        canvas.isPlaying = false;
    }
    pause(){
        const videoE = this.getElement();
        const canvas = this.canvas;
        videoE.pause();
        canvas.isPlaying = false;
    }
    _render(ctx) {
        this._draw(this.getElement(), ctx);
    }
    toObject(propertiesToInclude = []) {
        console.log('to object called')
        return fabric.util.object.extend(super.toObject.call(this, propertiesToInclude), {});
    }
    toJSON(propertiesToInclude = []) {
        return fabric.util.object.extend(super.toObject.call(this, propertiesToInclude), {
            speedFactor: this.speedFactor
        });
    }
}
fabric.StaticVideo = fabric.util.createClass(StaticVideoObject, {
    type: StaticVideoObject.type,
});

if (!fabric.StaticVideo) {
    fabric.StaticVideo = StaticVideoObject;
}


