const ProgressBar = ({ width }) => {
  return (
    <div className="w-full p-2 bg-gray-200 rounded-full h-2.5 dark:bg-gray-700 relative">
      <div
        className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none h-full rounded-full absolute top-0 left-0"
        style={{ width: `${width}%` }}
      >
        {width}%
      </div>
    </div>
  );
};

export default ProgressBar;
