import React from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { Navigate, Route, Routes } from "react-router-dom";
import { fas } from "@fortawesome/free-solid-svg-icons";
import HomepageContainer from "./container/HomepageContainer";
import EditorContainer from "./container/EditorContainer";
import AuthContainer from "./container/AuthContainer";
import ProtectedRoute from "./modules/ProtectedRoute";
import AccessDenied from "./components/AccessDenied/AccessDenied";
import DashboardContainer from "./container/DashboardContainer";
import SubscriptionContainer from "./container/SubscriptionContainer/SubscriptionContainer";
import PaymentSuccessContainer from "./container/PaymentSuccessContainer";
import ListofProjectContainer from "./container/ListofProjectContainer";
import Docs from "./modules/LayoutModule/Content/Docs/Docs"; // Add this import
import CategoryArticles from "./modules/LayoutModule/Content/Docs/CategoryArticles"; // Add this import

library.add(fas);

function App() {
  return (
    <div className="h-[100vh]">
      <Routes>
        <Route path="/" element={<HomepageContainer />} />
        <Route path="/login" element={<AuthContainer login />} />
        <Route path="/signup" element={<AuthContainer signup />} />
        <Route
          path="/payment-success"
          element={<ProtectedRoute element={<PaymentSuccessContainer />} />}
        />
        <Route
          path="/subscription-details"
          element={<ProtectedRoute element={<SubscriptionContainer />} />}
        />
        <Route
          path="/dashboard"
          element={<ProtectedRoute element={<DashboardContainer />} />}
        />
        <Route
          path="/projects"
          element={<ProtectedRoute element={<ListofProjectContainer />} />}
        />
        <Route
          path="/editor/:id"
          element={<ProtectedRoute element={<EditorContainer />} />}
        />
        <Route path="/access-denied" element={<AccessDenied />} />
        <Route path="*" element={<Navigate to="/" />} />
        <Route path="/docs" element={<Docs />} />
        <Route path="/docs/category/:slug" element={<CategoryArticles />} /> {/* Add this route */}
      </Routes>
    </div>
  );
}

export default App;
