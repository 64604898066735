import React, { useState } from "react";

function TrueFalseQuestionPreview({
  data,
  previewRef,
  showResult = true,
  setResultData,
}) {
  const [ans, setAns] = useState("");
  const {
    question = "",
    falseAnsPropmt = "",
    trueAnsPropmt = "",
    falseBtnText = "false",
    trueBtnText = "true",
    id=''
  } = data || {};

  return (
    <div className="container mx-auto">
      {data && question ? (
        <div className="mx-auto bg-white p-0 rounded-lg shadow-md">
          <div
            className="mx-auto bg-white p-3"
            ref={previewRef}
            id="preview-quiz"
          >
            <h2 className="text-sm text-left font-semibold mb-4 break-words">
              {question}
            </h2>
            <div className="flex items-center justify-start my-5 gap-[10px] flex-wrap mb-6">
              <button
                id="trueButton"
                className="px-4 py-2 mr-0 bg-blue-500 text-white text-sm rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                onClick={() => {
                  setAns("true");
                  setResultData?.({
                    id: id,
                    answer: 'true',
                  });
                }}
              >
                {trueBtnText}
              </button>
              <button
                id="falseButton"
                className="px-4 py-2 bg-red-500 text-white text-sm rounded-lg shadow-md hover:bg-red-600 focus:outline-none focus:bg-red-600"
                onClick={() => {
                  setAns("false");
                  setResultData?.({
                    id: id,
                    answer: 'false',
                  });
                }}
              >
                {falseBtnText}
              </button>
            </div>
          </div>
          {showResult && (
            <div className="text-left">
              {ans === "true" && (
                <div id="correctFeedback" className="mb-4">
                  <p className="text-green-600 font-semibold">Correct!</p>
                  <p className="text-sm text-gray-600">{trueAnsPropmt}</p>
                </div>
              )}

              {ans === "false" && (
                <div id="incorrectFeedback" className="mb-4">
                  <p className="text-red-600 font-semibold">Incorrect!</p>
                  <p className="text-sm text-gray-600">{falseAnsPropmt}</p>
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        <p className="max-w-lg mx-auto text-left text-slate-400">
          No preview
        </p>
      )}
    </div>
  );
}

export default TrueFalseQuestionPreview;
