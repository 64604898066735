import React from 'react';
import {
  NewsfeedIcon,
  TemplatesIcon,
  DocumentationIcon,
  CommunityIcon,
} from '../../../utils/iconHelper';
import Pro from '../../../utils/Images/Pro.png';

const Sidebar = () => {
  return (
    <div className="w-64 md:w-80 lg:w-96 h-screen bg-black text-white flex flex-col p-4 overflow-auto">
      <h1 className="text-sm mb-8 font-montserrat font-extrabold text-[14px] leading-[17.07px] tracking-wide">
        SLIDEDECK
      </h1>
      <ul className="flex flex-col w-full mb-4">
        <li className="text-center mb-4 flex items-center space-x-2 font-montserrat font-bold text-[18px] leading-[21.94px] text-white">
          <div className="w-12 h-12 flex items-center justify-center border-2 border-gray-700 rounded-full">
            <NewsfeedIcon className="text-gray-700 w-6 h-6" />
          </div>
          <span>Newsfeed</span>
        </li>
        <li className="text-center mb-4 flex items-center space-x-2 font-montserrat font-bold text-[18px] leading-[21.94px] text-white">
          <div className="w-12 h-12 flex items-center justify-center border-2 border-gray-700 rounded-full">
            <TemplatesIcon className="text-gray-700 w-6 h-6" />
          </div>
          <span>Templates</span>
        </li>
        <li className="text-center mb-4 flex items-center space-x-2 font-montserrat font-bold text-[18px] leading-[21.94px] text-white">
          <div className="w-12 h-12 flex items-center justify-center border-2 border-gray-700 rounded-full">
            <DocumentationIcon className="text-gray-700 w-6 h-6" />
          </div>
          <span>Documentation</span>
        </li>
        <li className="text-center flex items-center space-x-2 font-montserrat font-bold text-[18px] leading-[21.94px] text-white">
          <div className="w-12 h-12 flex items-center justify-center border-2 border-gray-700 rounded-full">
            <CommunityIcon className="text-gray-700 w-6 h-6" />
          </div>
          <span>Community</span>
        </li>
      </ul>
      <div className="flex-grow flex items-center justify-center mt-auto">
        <div className="bg-gray-900 text-white p-6 rounded-lg shadow-lg border-2 border-gray-600 flex flex-col items-center">
          <img
            src={Pro}
            alt="Pro Icon"
            className="w-14 h-14 mb-4"
            style={{
              filter:
                'grayscale(1.2) sepia(2) hue-rotate(280deg) saturate(3.5) brightness(3.5)',
            }}
          />
          <h2 className="text-xl font-bold mb-2">PRO Account</h2>
          <p className="mb-4">Unlock all abilities</p>
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">
            Become a PRO
          </button>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
