import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const Dialog = ({ title, isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="relative p-4 bg-white rounded-lg shadow-lg max-w-lg w-full">
        <div className="flex items-center justify-between">
          {title && <h2 className="text-2xl font-semibold">{title}</h2>}
          <button
            className=" text-gray-400 hover:text-gray-600"
            onClick={onClose}
          >
            <FontAwesomeIcon icon={faXmark} className="size-5 text-gray-400" />
          </button>
        </div>
        <div>{children}</div>
      </div>
    </div>
  );
};

export default Dialog;
