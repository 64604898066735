import { v4 as uuidv4 } from "uuid";
let slideCount = 0;

class Slide {
  constructor(defaultData) {
    this.id = uuidv4();
    this.canvas = null;
    this.audio = [];
    this.image = [];
    this.video = [];
    this.shape = [];
    this.textbox = [];
    this.button = [];
    this.textInput = [];
    this.card = [];
    this.quiz = [];
    slideCount = (defaultData?.slideCount || 0) + 1; // Increment the slide count for each new instance
    this.name = `${slideCount}- Untitled `;
    this.thumbnail = "";
    this.transition = "";
    this.timeFrameItemCount = 0;
  }

  getId = () => {
    return this.id;
  };

  setId = (id) => {
    this.id = id;
  };

  getCanvas = () => {
    return this.canvas;
  };

  setCanvas = (canvas) => {
    this.canvas = canvas;
  };

  setSlideItem = (type) => {
    this[type] = type;
  };

  pushNewItem = (data, type) => {
    this[type] = [
      ...this[type],
      { ...data, sequence: data?.sequence || this.timeFrameItemCount, type },
    ];
    this.timeFrameItemCount = this.timeFrameItemCount + 1;
  };

  updateItem = (updatedData, type) => {
    this[type] = this[type].map((item) => {
      if (item.id === updatedData.id) {
        return { ...item, ...updatedData, type };
      }
      return item;
    });
  };

  deleteItem = (id, type) => {
    let tempList = [...(this[type] || [])];
    tempList = tempList.filter((item) => item.id !== id);
    this[type] = tempList;
  };

  setTimeFrameItemCount = () => {
    this.timeFrameItemCount = this.timeFrameItemCount + 1;
  };

  setThumbnail = (thumbnail) => {
    this.thumbnail = thumbnail;
  };

  getDuplicateAudioList = () => {
    const newList = [...this.audio];
    newList.forEach((audioFile) => {
      audioFile.id = uuidv4();
    });
    return newList;
  };

  getSlideJSON = () => {
    return {
      id: this.id,
      audio: this.audio,
      image: this.image,
      video: this.video,
      shape: this.shape,
      textbox: this.textbox,
      button: this.button,
      textInput: this.textInput,
      card: this.card,
      quiz: this.quiz,
      canvas: this.canvas,
      name: this.name,
      thumbnail: this.thumbnail,
      timeFrameItemCount: this.timeFrameItemCount,
    };
  };

  // Updates slide name
  setSlideName = (name) => {
    this.name = name;
  };

  // Updates slide transition
  setSlideTransition = (transition) => {
    this.transition = transition;
  };
}

export default Slide;
