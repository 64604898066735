import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { api } from "../../../api/api";
import { useAlert } from "../../../context/AlertContext";
import { useDispatch } from "react-redux";
import {
  addProject,
  updateProject,
} from "../../../store/reducers/projectListSlice";
import { isObject } from "lodash";
import baseUrl from "../../../api/config";

const schema = yup.object().shape({
  name: yup.string().required("Project name is required"),
  //   start_date: yup.date().required('Start date is required'),
  //   end_date: yup
  //     .date()
  //     .required('End date is required')
  //     .min(yup.ref('start_date'), "End date can't be before start date"),
  project_image: yup
    .mixed()
    .test("required", "Project image is required", (value) => {
      return value?.[0]?.type || value?.url;
    })
    .test("fileType", "Unsupported File Format", (value) =>
      value && value.length > 0
        ? ["image/jpeg", "image/png"].includes(value[0].type)
        : true
    ),
});

const SaveProject = ({ initialValues = {}, onclose, isEdit }) => {
  const [imagePreview, setImagePreview] = useState(
    initialValues?.project_image
      ? `${baseUrl}${initialValues?.project_image?.url}`
      : null
  );
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    watch,
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { ...initialValues },
  });
  const { showAlert } = useAlert();
  const dispatch = useDispatch();

  const watchProjectImage = watch("project_image");

  React.useEffect(() => {
    if (
      watchProjectImage &&
      !watchProjectImage?.url &&
      watchProjectImage.length > 0
    ) {
      const file = watchProjectImage[0];
      const filePreview = URL.createObjectURL(file);
      setImagePreview(filePreview);

      // Cleanup: Revoke object URL to avoid memory leaks
      return () => URL.revokeObjectURL(filePreview);
    }
    if (isObject(watchProjectImage)) {
      clearErrors("project_image");
    }
  }, [watchProjectImage, clearErrors]);

  const onSubmit = async (data) => {
    let response = null;
    const formData = new FormData();
    formData.append("name", data.name);
    if (data?.project_image && !data?.project_image?.url) {
      formData.append("project_image", data?.project_image?.[0]);
    }
    try {
      if (isEdit) {
        response = await api.put(`/api/projects/${data.id}`, formData);
      } else {
        response = await api.post("/api/projects", formData);
      }
      if (response) {
        dispatch(isEdit? updateProject(response.data) : addProject(response.data));
        showAlert(
          "success",
          `Project ${isEdit ? "updated" : "added"} successfully`
        );
        onclose();
      }
    } catch (error) {
      showAlert("error", error?.response?.data?.error?.message);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mt-4 space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Project Name
        </label>
        <input
          type="text"
          {...register("name")}
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-blue-500 focus:border-blue-500"
        />
        {errors.name && (
          <p className="text-red-500 text-sm">{errors.name.message}</p>
        )}
      </div>

      {/* <div>
      <label className="block text-sm font-medium text-gray-700">Start Date</label>
      <input
        type="date"
        {...register('start_date')}
        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-blue-500 focus:border-blue-500"
      />
      {errors.start_date && <p className="text-red-500 text-sm">{errors.start_date.message}</p>}
    </div>

    <div>
      <label className="block text-sm font-medium text-gray-700">End Date</label>
      <input
        type="date"
        {...register('end_date')}
        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-blue-500 focus:border-blue-500"
      />
      {errors.end_date && <p className="text-red-500 text-sm">{errors.end_date.message}</p>}
    </div> */}

      <div>
        <label className="block text-sm font-medium text-gray-700">
          Project Image
        </label>
        <input
          type="file"
          {...register("project_image")}
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-blue-500 focus:border-blue-500"
        />
        {errors.project_image && (
          <p className="text-red-500 text-sm">{errors.project_image.message}</p>
        )}
        {imagePreview && (
          <div className="mt-4">
            <img
              src={imagePreview}
              alt="Selected Project"
              className="h-40 w-auto border rounded"
            />
          </div>
        )}
      </div>

      <div className="flex justify-end">
        <button
          type="button"
          onClick={onclose}
          className="px-4 py-2 bg-gray-500 text-white rounded mr-2"
        >
          Cancel
        </button>
        <button
          type="submit"
          className={`px-4 py-2 text-white rounded ${
            isDirty ? "bg-blue-500" : "bg-blue-300 cursor-not-allowed"
          }`}
          disabled={!isDirty}
        >
          Save
        </button>
      </div>
    </form>
  );
};

export default SaveProject;
