import React, { useEffect, useMemo, useRef, useState } from "react";
import TrueFalseQuestionsForm from "./TrueFalseQuestionsForm";
import TrueFalseQuestionPreview from "./TrueFalseQuestionPreview";
import { useSelector } from "react-redux";
import html2canvas from "html2canvas";
import { getFabricImageFromURL, handleLoader } from "../../../../utils/helpers";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { shallowEqual } from "react-redux";
import SingleSelectionPreview from "./SingleSelectionPreview";
import SingleSelectionForm from "./SingleSelectionForm";
import { isNumber } from "lodash";
import MultiSelectionForm from "./MultiSelectionForm";
import MultiSelectionPreview from "./MultiSelectionPreview";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const QuestionController = () => {
  const [formData, setFormData] = useState(null);
  const { canvas, activeObject } = useSelector((state) => state.fabricCanvas);
  const { activeSlide } = useSelector((state) => state.slideList, shallowEqual);
  const [isModalOpen, setModalOpen] = useState(false);
  const previewRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      activeObject &&
      activeObject.type === "quiz" &&
      activeObject?.formData
    ) {
      setFormData(activeObject.formData);
    }
  }, [activeObject]);

  const handleAddImage = (image) => {
    handleLoader(true, "load_image_on_canvas", dispatch);
    const imageId = activeObject ? activeObject.id : uuidv4();
    getFabricImageFromURL(
      image.url,
      (fabricImage) => {
        fabricImage.set({
          id: imageId,
          borderWidth: 0,
          borderStyle: "none",
          borderFill: "rgb(0,0,0)",
          listStyle: "none",
          link: "",
          jumpToSlide: 0,
          slideId: activeSlide?.id,
          type: "quiz",
          formData,
          questionType: activeObject.questionType,
        });
        fabricImage.setControlsVisibility({
          mt: false,
          mb: false,
        });
        fabricImage.scaleToWidth(canvas.width);
        if (activeObject) {
          fabricImage.set({
            left: activeObject.left,
            top: activeObject.top,
          });
          canvas.remove(activeObject);
        }
        canvas.add(fabricImage);
        canvas.setActiveObject(fabricImage);
        canvas.renderAll();
        setModalOpen(false);
        handleLoader(false, "", dispatch);
      },
      () => {
        handleLoader(false, "", dispatch);
      }
    );
  };

  const addPreviewToslide = async () => {
    const element = previewRef.current;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL("image/jpg");
    handleAddImage({ url: data });
  };

  const renderPreview = useMemo(() => {
    switch (activeObject?.questionType) {
      case "true-false":
        return (
          <TrueFalseQuestionPreview data={formData} previewRef={previewRef} />
        );
      case "single-selection":
        return (
          <SingleSelectionPreview data={formData} previewRef={previewRef} />
        );
      case "multiple-selection":
        return (
          <MultiSelectionPreview data={formData} previewRef={previewRef} />
        );
      default:
        return null;
    }
  }, [activeObject?.questionType, formData, previewRef]);

  const renderForm = useMemo(() => {
    switch (activeObject?.questionType) {
      case "true-false":
        return <TrueFalseQuestionsForm data={formData} setData={setFormData} />;
      case "single-selection":
        return <SingleSelectionForm data={formData} setData={setFormData} />;
      case "multiple-selection":
        return <MultiSelectionForm data={formData} setData={setFormData} />;
      default:
        return null;
    }
  }, [activeObject?.questionType, setFormData, formData]);

  const disabledUpdateToSlide = useMemo(
    () =>
      (activeObject?.questionType === "true-false" && !formData?.question) ||
      (!(formData?.title && isNumber(formData?.correctAnswer)) &&
        activeObject?.questionType === "single-selection") ||
      (!(formData?.title && formData?.correctAnswer?.length) &&
        activeObject?.questionType === "multiple-selection"),
    [formData, activeObject?.questionType]
  );

  return (
    <div>
      <div className="my-2">{renderForm}</div>
      <div className="flex items-center my-4">
        <input
          id="addToScoring"
          type="checkbox"
          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded cursor-pointer"
          checked={!!formData?.addToScoring}
          value={formData?.addToScoring || false}
          onChange={(e) => {
            setFormData((prevState) => ({
              ...prevState,
              addToScoring: e.target.checked,
            }));
          }}
        />
        <label
          htmlFor="addToScoring"
          name="addToScoring"
          className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300 cursor-pointer"
        >
          Add this question to final scoring?
        </label>
      </div>
      <div className="my-3 text-center">
        <button
          onClick={() => setModalOpen(true)}
          disabled={disabledUpdateToSlide}
          className={`bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded-full text-[14px] focus:outline-none ${
            disabledUpdateToSlide ? "opacity-50" : ""
          }`}
        >
          Update To Slide
        </button>
      </div>
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 z-50 overflow-y-auto h-full w-full flex items-center justify-center">
          <div className="relative mx-auto p-5 border w-[540px] shadow-lg rounded-[15px] bg-white">
            <div className="flex items-center justify-between  border-b pb-4">
              <h3 className="text-[16px] leading-6 text-gray-900 font-semibold">
                Update Question
              </h3>
              <button onClick={() => setModalOpen(false)}>
                <FontAwesomeIcon
                  icon={faXmark}
                  className="size-4 text-gray-400"
                />
              </button>
            </div>
            <div className="my-5">{renderPreview}</div>
            <div className="flex items-center justify-end  border-t pt-4 gap-[20px]">
              <button
                onClick={() => setModalOpen(false)}
                className="text-gray-400 text-[14px]"
              >
                Cancel
              </button>
              <button
                onClick={addPreviewToslide}
                disabled={disabledUpdateToSlide}
                className={`bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded-full text-[14px] focus:outline-none ${
                  disabledUpdateToSlide ? "opacity-50" : ""
                }`}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default QuestionController;
