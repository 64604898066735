import React, { useEffect, useRef, useState } from 'react';
import { useWavesurfer } from '@wavesurfer/react';
import { startCase } from 'lodash';

const TimeLineAudioWaveForm = ({ url, name }) => {
  const waveformRef = useRef(null);
  const [showWaveform, setShowWaveform] = useState(false);

  const { wavesurfer } = useWavesurfer({
    container: waveformRef,
    height: 12,
    width: 60,
    waveColor: '#dfdfdf',
    barWidth: 1,
    barGap: 1,
    barRadius: 2,
    barHeight: 2,
    progressColor: '#dfdfdf',
    url: url,
  })

  useEffect(() => {
    if (wavesurfer) {
      wavesurfer.on('ready', (duration) => {
        setShowWaveform(true);
      })

      wavesurfer.on('destroy', () => {
        setShowWaveform(false);
      })
    }
    // eslint-disable-next-line
  }, [wavesurfer]);

  return (
    <div className={'flex flex-row effect3 justify-center	gap-x-1'}>
      <div className='flex svg-box flex-row overflow-x-hidden items-center'>
        <div
          className={`flex text-gray-600 items-center w-full cursor-pointer h-full justify-between bg-none ${showWaveform ? '' : 'hidden'}`}>
          <div ref={waveformRef} className="pointer-events-none w-full" />
        </div>
      </div>
      {showWaveform && <div className={`effect-text`}>
        {`Audio: ${name}`.slice(0, 20) + '...'}
      </div>}
    </div>
  );
}

export const CustomRender = ({ action, row }) => {
  const renderComponent = React.useMemo(() => {
    switch (action?.data?.type) {
      case 'image':
        return (
          <div className={'effect1'}>
            <div className={`effect-text`}>
              <img
                src={action?.data?.src}
                className='h-4 w-auto pr-1'
                alt='timeline-img'
              />
              {`Image: ${action.data.name}`.slice(0, 20) + '...'}
            </div>
          </div>
        );
      case 'shape':
        return (
          <div className={'effect2'}>
            <div className={`effect-text`}>
              <img
                src={action?.data?.src}
                className='h-4 w-auto pr-1'
                alt='timeline-img'
              />
              {`Shape: ${action.data.name}`.slice(0, 20) + '...'}
            </div>
          </div>
        );
      case 'video':
        return (
          <div className={'effect0'}>
            <div className={`effect-text`}>
              <img
                src={action?.data?.thumbnail}
                className='h-4 w-auto pr-1'
                alt='timeline-img'
              />
              {`Video: ${action.data.name}`.slice(0, 10) + '...'}
            </div>
          </div>
        );
      case 'audio':
        return (

          <TimeLineAudioWaveForm
            url={action.data.src}
            name={action.data.name}
          />
        );
      case 'textbox':
        return (
          <div className={'effect4'}>
            <div className={`effect-text`}>
              {`Text: ${action?.data?.text?.text || action?.data?.text}`.slice(0, 100) + '...'}
            </div>
          </div>
        );
      case 'button':
      case 'textInput': 
      case 'card': 
      case 'quiz': 
        return (
          <div className={`effect${action?.data?.type}`}>
            <div className={`effect-text`}>
              {`${startCase(action?.data?.type)}: ${action.data.text}`.slice(0, 100) + '...'}
            </div>
          </div>
        )
      default:
        return null;
    }
  }, [action]);
  return renderComponent;
};
