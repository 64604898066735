import {createSlice} from '@reduxjs/toolkit'

const imageBucket = createSlice({
    name: 'imageBucket',
    initialState: {
        imageList: [],
        pexelsList: [],
        pexelsVideoList: [],
        videoList: [],
        audioList: [],
        recordedAudioList: [],
        svgList:[],
        jsonList: [],
        isLoading:false,
        nextPage:'',
        nextVideoPage: '',
        query:'people'
    },
    reducers: {
        addNewRecordedAudio: (state, action) => {
            state.recordedAudioList.unshift(action.payload);
        },
        setRecordedAudioList: (state, action) => {
            state.recordedAudioList = action.payload;
        },
        removeRecordedAudio: (state, action) => {
            state.recordedAudioList = state.recordedAudioList.filter(audio => audio.id !== action.payload);
        },
        addNewAudio: (state, action) => {
            state.audioList.unshift(action.payload);
        },
        setAudioList: (state, action) => {
            state.audioList = action.payload;
        },
        removeAudio: (state, action) => {
            state.audioList = state.audioList.filter(audio => audio.id !== action.payload);
        },
        setImageList: (state, action) => {
            state.imageList = action.payload;
        },
        addNewImage: (state, action) => {
            state.imageList.unshift(action.payload);
        },
        removeImage: (state, action) => {
            state.imageList = state.imageList.filter(image => image.id !== action.payload);
        },
        setSvgList: (state, action) => {
            state.svgList = action.payload;
        },
        addNewSvg: (state, action) => {
            state.svgList.unshift(action.payload);
        },
        setPexelsVideoList: (state, action) => {
            state.pexelsVideoList = action.payload;
        },
        setPexelsList: (state, action) => {
            state.pexelsList = action.payload;
        },
        setImageloading:(state, action) =>{
            state.isLoading = action.payload;
        },
        setNextPage:(state, action) =>{
            state.nextPage = action.payload
        },
        setNextVideoPage: (state, action) => {
            state.nextVideoPage = action.payload;
        },
        setQuery:(state, action) =>{
            state.query = action.payload
        },
        pushNewImagesInPexelsList: (state, action) => {
            state.pexelsList = [...state.pexelsList,...action.payload];
        },
        setVideoList: (state, action) => {
            state.videoList = action.payload;
        },
        addNewVideo: (state, action) => {
            state.videoList.unshift(action.payload);
        },
        removeVideo: (state, action) => {
            state.videoList = state.videoList.filter(video => video.id !== action.payload);
        },
        pushNewVideoInPexelsList: (state, action) => {
            state.pexelsVideoList = [...state.pexelsVideoList, ...action.payload];
        },
        addNewJson: (state, action) => {
            state.jsonList.unshift(action.payload);
        },
        setJsonList: (state, action) => {
            state.jsonList = action.payload;
        },
    }
});

export const {
    setImageList,
    addNewImage,
    removeImage,
    setSvgList,
    addNewSvg,
    setPexelsList,
    setNextPage,
    setQuery,
    setImageloading,
    pushNewImagesInPexelsList,
    setVideoList,
    addNewVideo,
    setPexelsVideoList,
    pushNewVideoInPexelsList,
    setNextVideoPage,
    setAudioList,
    addNewAudio,
    removeAudio,
    removeVideo,
    setRecordedAudioList,
    addNewRecordedAudio,
    removeRecordedAudio,
    addNewJson,
    setJsonList
} = imageBucket.actions;
export default imageBucket.reducer;
