import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";

const AudioContent = ({ list, onSelect, selectedData }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState("");

  const [filteredList, setFilteredList] = useState(list);

  const toggleDropdown = () => {
    setIsOpen((oldVal) => !oldVal);
  };

  useEffect(() => {
    const q = query.toLowerCase();
    const filteredData = list.filter((data) =>
      data.name.toLowerCase().includes(q)
    );

    setFilteredList(filteredData);
  }, [query, list]);

  const handleSelect = (data) => {
    onSelect(data);
    toggleDropdown();
  };

  return (
    <>
      <label
        htmlFor="fontSelector"
        className="mb-2 block text-xs font-medium text-gray-600"
      >
        Select audio
      </label>
      <div className="relative group">
        <button
          id="dropdown-button"
          className="inline-flex justify-between w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
          type="button"
          onClick={toggleDropdown}
        >
          <>
            {selectedData?.audio?.name && (
              <p className="text-gray-700 truncate overflow-hidden block select-none">
                {selectedData.audio.name.length > 20
                  ? `${selectedData.audio.name.substring(0, 20)}...`
                  : selectedData.audio.name}
              </p>
            )}

            {!selectedData?.audio?.name && (
              <span className="mr-2"> Select Audio </span>
            )}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-5 h-5 ml-2 -mr-1"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </>
        </button>
        <div
          id="dropdown-menu"
          className={`right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 p-1 space-y-1 z-10 ${
            isOpen ? "absolute" : "hidden"
          }`}
        >
          <input
            value={query}
            id="search-input"
            className="block w-full px-4 py-2 text-gray-800 border rounded-md  border-gray-300 focus:outline-none"
            type="text"
            placeholder="Search audio"
            autocomplete="off"
            onChange={(e) => {
              setQuery(e.target.value);
            }}
          />
          {!filteredList.length && (
            <p className="p-3 text-gray-800">No Audio File Found!</p>
          )}
          {filteredList.length > 0 && (
            <>
              {filteredList.map((data) => (
                <div
                  key={data.id}
                  className="flex items-center gap-2 hover:bg-gray-100 rounded-md cursor-pointer"
                  onClick={() => handleSelect(data)}
                >
                  <FontAwesomeIcon
                    icon={"file-audio"}
                    className="text-gray-600 size-5 pl-2"
                  />
                  <p className="text-gray-700 truncate overflow-hidden block py-3 select-none">
                    {data.name.length > 20
                      ? `${data.name.substring(0, 20)}...`
                      : data.name}
                  </p>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default AudioContent;
