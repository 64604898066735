import { characters } from "./util";
import AudioContent from "./AudioContent";
import { useSelector } from "react-redux";

const Confirmation = ({ data, setData }) => {
  const { audioList, recordedAudioList } = useSelector(
    (state) => state.imageBucket
  );
  const list = [...audioList, ...recordedAudioList];
  return (
    <>
      <div className="flex flex-col md:flex-row md:space-x-4 p-4 md:p-5">
        <div className="md:w-1/2 space-y-4">
          {/* Text container taking half space on medium screens */}
          <p className="pt-10 text-sm leading-relaxed text-gray-500 dark:text-gray-400">
            Here you can create final talking avatar video.
          </p>

          {characters.map((name) => (
            <label key={name} className="inline-flex items-center">
              <input
                type="radio"
                className="form-radio text-blue-600"
                name="character"
                value={name}
                checked={data.name === name}
                onChange={(e) =>
                  setData({
                    ...data,
                    name: e.target.value,
                  })
                }
              />
              <span className="ml-2 text-sm leading-relaxed text-gray-500 dark:text-gray-400 mr-2">
                {name}
              </span>
            </label>
          ))}

          <AudioContent
            list={list}
            selectedData={data}
            onSelect={(val) => {
              setData({
                ...data,
                audio: val,
              });
            }}
          />

          <p className="text-sm leading-relaxed text-gray-500 dark:text-gray-400">
            Your final video will be saved in Video section
          </p>
        </div>
        <div className="md:w-1/2 flex justify-center items-center">
          {/* Video container */}
          <img src="/dummy-video.png" alt="dummy-video" className="h-full w-full object-cover" />
        </div>
      </div>
    </>
  );
};

export default Confirmation;
