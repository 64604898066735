import React, { useEffect, useMemo, useState } from "react";
import { faRemove } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isArray } from "lodash";

const MultiSelectionForm = ({ data, setData }) => {
  const [question, setQuestion] = useState({
    title: data?.title || "",
    options: data?.options || [{ label: "" }, { label: "" }, { label: "" }],
    correctAnswer: data?.correctAnswer || [],
    addToScoring: data?.addToScoring || false
  });

  useEffect(() => {
    if (question) {
      setData(question);
    }
  }, [question, setData]);

  const addOption = () => {
    const updatedQuestion = { ...question, ...data };
    updatedQuestion.options.push({ label: "" });
    setQuestion(updatedQuestion);
  };

  const removeOption = (optionIndex) => {
    const updatedQuestion = { ...question, ...data };
    updatedQuestion.options.splice(optionIndex, 1);
    if (
      updatedQuestion.correctAnswer?.includes(optionIndex) ||
      (updatedQuestion.correctAnswer?.includes(optionIndex) &&
        !updatedQuestion.options[updatedQuestion.correctAnswer].label)
    ) {
      updatedQuestion.correctAnswer = updatedQuestion.correctAnswer.filter(
        (index) => index !== optionIndex
      );
    }

    setQuestion(updatedQuestion);
  };

  const handleQuestionChange = (value) => {
    setQuestion({ ...question, ...data, title: value });
  };

  const handleOptionChange = (optionIndex, value) => {
    const updatedQuestion = { ...question, ...data };
    updatedQuestion.options[optionIndex].label = value;
    if (!value &&  updatedQuestion?.correctAnswer?.includes?.(optionIndex)) {
      updatedQuestion.correctAnswer = updatedQuestion.correctAnswer.filter(
        (index) => index !== optionIndex
      );
    }
    setQuestion(updatedQuestion);
  };

  const handleCorrectAnswerChange = (optionIndex) => {
    let updatedcorrectAnswer = [...question.correctAnswer];
    if (updatedcorrectAnswer.includes(optionIndex)) {
      updatedcorrectAnswer = updatedcorrectAnswer.filter(
        (index) => index !== optionIndex
      );
    } else {
      updatedcorrectAnswer.push(optionIndex);
    }
    setQuestion({
      ...question,
      ...data,
      correctAnswer: updatedcorrectAnswer,
    });
  };

  const formDefaultData = useMemo(() => data || question, [data, question]);
  return (
    <div>
      <div className="my-4">
        <label
          htmlFor="question"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Enter Your Question
        </label>
        <input
          type="text"
          value={formDefaultData?.title}
          onChange={(e) => handleQuestionChange(e.target.value)}
          placeholder="Enter question"
          className="border  w-full rounded px-2 py-1 mb-2"
        />
        <label className="block my-2 text-sm font-medium text-gray-900 dark:text-white">
          Possible Answers
        </label>
        {formDefaultData?.options?.map((option, optionIndex) => (
          <div key={optionIndex} className="flex items-center mb-2">
            <input
              type="text"
              value={option.label}
              onChange={(e) => handleOptionChange(optionIndex, e.target.value)}
              placeholder="Enter option"
              className="border rounded px-2 w-full py-1 mr-2"
            />
            <input
              type="checkbox"
              checked={isArray(formDefaultData?.correctAnswer) && formDefaultData?.correctAnswer?.includes(optionIndex)}
              onChange={() => handleCorrectAnswerChange(optionIndex)}
              className="mr-2"
              disabled={!option.label}
            />
            {formDefaultData?.options?.length > 3 && (
              <button onClick={() => removeOption(optionIndex)}>
                <FontAwesomeIcon
                  icon={faRemove}
                  className="w-4 h-4 text-red-500"
                />
              </button>
            )}
          </div>
        ))}
        <button
          onClick={addOption}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Add Option
        </button>
      </div>
    </div>
  );
};

export default MultiSelectionForm;
