import { characters } from "./util";
import { useSelector } from "react-redux";
import AudioContent from "./AudioContent";

const Selection = ({ data, setData, sampleVideo }) => {
  const { audioList, recordedAudioList } = useSelector(
    (state) => state.imageBucket
  );
  const list = [...audioList, ...recordedAudioList];

  return (
    <>
      <div className="flex flex-col md:flex-row md:space-x-4 p-4 md:p-5">
        <div className="md:w-1/2 space-y-4">
          {/* Text container taking half space on medium screens */}
          <p className="pt-10 text-sm leading-relaxed text-gray-500 dark:text-gray-400">
            Select one of Your audio files and character type to see 5s sample,
            if you will like it create full audio character.
          </p>

          {characters.map((name) => (
            <label key={name} className="inline-flex items-center">
              <input
                required
                type="radio"
                className="form-radio text-blue-600"
                name="character"
                value={name}
                checked={data.name === name}
                onChange={(e) =>
                  setData({
                    ...data,
                    name: e.target.value,
                  })
                }
              />
              <span className="ml-2 text-sm leading-relaxed text-gray-500 dark:text-gray-400 mr-2">
                {name}
              </span>
            </label>
          ))}
          <AudioContent
            list={list}
            selectedData={data}
            onSelect={(val) => {
              setData({
                ...data,
                audio: val,
              });
            }}
          />
        </div>
        <div className="md:w-1/2 flex justify-center items-center">
          {sampleVideo && (
            <video
              className="rounded-md md:max-w-xs lg:max-w-xs p-5"
              autoplay="autoplay"
              controls
            >
              <source src={sampleVideo} />
              Your browser does not support the video tag.
            </video>
          )}
          {!sampleVideo && (
            <img src="/dummy-video.png" alt="dummy-video" className="h-full w-full object-cover" />
          )}
        </div>
      </div>
    </>
  );
};

export default Selection;
