import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Reveal from 'reveal.js';
import 'reveal.js/dist/reveal.css';
import {
    duplicateSlideCanvas,
    initFabricEvents,
    initializeFabricCanvasInstance,
    loadGoogleFonts, setupAudioList,
    setupImageList,
    setupPexelsList,
    setupPexelsVideoList, setupRecordedAudioList,
    setupSlideList,
    setupVideoList, stopAllVideos
} from "../../utils/helpers";
import '../../utils/Objects/StaticText';
import '../../utils/Objects/StaticImage';
import '../../utils/Objects/StaticButton';
import '../../utils/Objects/StaticInput';
import '../../utils/Objects/StaticCard';
import {useDispatch, useSelector} from "react-redux";
import {setActiveSlide, setActiveSlideIndex} from "../../store/reducers/slideListSlice";
import SlidesViewer from "../SlidesViewer/SlidesViewer";
import {setActiveCanvas, setContext, setCtxCoords, setVideoControl} from "../../store/reducers/canvasSlice";
import LoaderPopup from "../LoaderPopup";
import {loadSvgs} from "../../utils/svgHelper";
import {CanvasActions} from "../../utils/canvasActions";
import {fabric} from "fabric";
import CropperPanel from "../CropperPanel/CropperPanel";
import { useAuth } from '../../context/AuthContext';
import { useLocation } from 'react-router';
//import AudioWaveform from "../AudioWaveform";

fabric.Object.NUM_FRACTION_DIGITS = 17;

function RevealSlide() {
    const dispatch = useDispatch();
    const { user } = useAuth();
    const {slideList, activeSlideIndex, activeSlide, newSlideAdded} = useSelector(state => state.slideList);
    const {clipBoard, activeObject, canvas, videoControl} = useSelector(state => state.fabricCanvas);
    const {show: showCropper} = useSelector(state => state.cropper);
    const canvasRef = useRef(null);
    const location = useLocation();
    const projectId = useMemo(() => location.pathname.split('/').pop() || '', [location]);
    

    const [zoomLevel, setZoomLevel] = useState(60);
    const adjustZoom = (newZoomLevel) => {
      setZoomLevel(newZoomLevel);
      if (videoControl) {
        dispatch(setVideoControl(false));
      }
    };


    const zoomIn = () => {
      if (zoomLevel < 500) {
        adjustZoom(zoomLevel + 2);
      }
    };

    const zoomOut = () => {
      if (zoomLevel > 50) {
        adjustZoom(zoomLevel - 2);
      }
    };

    const handleRangeChange = (e) => {
      const value = parseInt(e.target.value);
      adjustZoom(value);
    };
    

    const slideChangeHandler = (event) => {
        const previousSlide = window.slideList[activeSlideIndex];
        const newCanvasInstance = window.slideList[event.indexh].canvas;
        previousSlide && previousSlide.canvas.discardActiveObject().renderAll();
        previousSlide && stopAllVideos(previousSlide.canvas);
        window.fabricCanvas = newCanvasInstance;
        dispatch(setActiveCanvas(newCanvasInstance));
        dispatch(setActiveSlide(window.slideList[event.indexh]));
        dispatch(setActiveSlideIndex(event.indexh));
    };

    Reveal.on('slidechanged', event => {
        slideChangeHandler(event);
    });

    Reveal.off('slidechanged', slideChangeHandler);

    useEffect(() => {
      if (user?.user_id && projectId) {
          setupImageList(dispatch, user?.user_id);
          setupPexelsVideoList(dispatch);
          setupPexelsList(dispatch);
          setupVideoList(dispatch, user?.user_id);
          loadSvgs(dispatch);
          loadGoogleFonts(dispatch);
          setupAudioList(dispatch, user?.user_id);
          setupRecordedAudioList(dispatch, user?.user_id);
          setupSlideList(dispatch, projectId)
      }
      // eslint-disable-next-line
  }, [user, projectId]);

    useEffect(() => {
        if(slideList.length !== 1) {
            const latestSlide = slideList[slideList.length - 1];
            if(latestSlide && !latestSlide.canvas) {
                initializeFabricCanvasInstance(latestSlide, slideList.length, canvasRef);
                if(latestSlide.hasOwnProperty('isDuplicate')) {
                    duplicateSlideCanvas(latestSlide).then(() => {
                        initFabricEvents(latestSlide.canvas, dispatch);
                    });
                } else {
                    initFabricEvents(latestSlide.canvas, dispatch, true);
                }
            }
        }
        // eslint-disable-next-line
    }, [newSlideAdded]);

    useEffect(() => {
        let canvasZoom = 0;
        slideList.forEach((slide, index) => {
          
            if (!slide.canvas) {
                initializeFabricCanvasInstance(slide, index, canvasRef);
                initFabricEvents(slide.canvas, dispatch);
                if (index === 0) {
                    dispatch(setActiveCanvas(slide.canvas));
                    dispatch(setActiveSlide(slide));
                    canvasZoom = slide.canvas.getZoom();
                }
            }

        });

        window.slideList = slideList;

        const handleGlobalClick = (e) => {
            if (e.button === 0) {
                // Left-click outside the canvas, hide the context menu
                dispatch(setContext(false));
            }
        };
        Reveal.initialize({
            // Reveal.js options
            hash: true,
            width: 1200 * canvasZoom,  // Slide width
            height: 700 * canvasZoom, // Slide height
            //margin: 0.1, // Margin around each slide
            //minScale: 1, // Minimum scale for the slide
            //maxScale: 1.5, // Maximum scale for the slide
            controls: false,
            progress: false,
            slideNumber: true,
            jumpToSlide: false,
            scrollActivationWidth: null,
            touch: false,
            transition: 'none'
        });
        // Add a global click event listener to the document
        document.addEventListener('click', handleGlobalClick);
        // disable default context menu every where

        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener('click', handleGlobalClick);
        };

        // eslint-disable-next-line
    }, [slideList]);

    useEffect(() => {
        document.addEventListener('keydown', keyboardEvents);
        document.addEventListener("contextmenu", handleContextMenu);
        return () => {
            document.removeEventListener('keydown', keyboardEvents);
            document.removeEventListener("contextmenu", handleContextMenu)
        }
        // eslint-disable-next-line
    }, [canvas, activeObject, clipBoard, activeSlide])

    const handleContextMenu = (event) => {
       if(canvas){ const {pageTop, pageLeft} = window.visualViewport;
        const {clientX, clientY} = event;
        const {top: canvasOffsetTop, left: canvasOffsetLeft} = canvas._offset;
        if (
            clientX < canvasOffsetLeft ||
            clientY < canvasOffsetTop ||
            clientX > canvasOffsetLeft + canvas.width ||
            clientY > canvasOffsetTop + canvas.height
        ) {
            return;
        }
        event.preventDefault();
        dispatch(setCtxCoords({
            left: clientX + pageLeft,
            top: clientY + pageTop
        }));
        dispatch(setContext(true));
        const clickPoint = new fabric.Point(event.offsetX, event.offsetY);
        const objectList = [];

        canvas.forEachObject((obj) => {
            if (obj.containsPoint(clickPoint) && !obj.hasOwnProperty('isBoundingRect')) {
                objectList.push(obj);
            }
        });

    if (objectList.length > 0) {
      canvas.setActiveObject(objectList[objectList.length - 1]);
      canvas.renderAll();
    }}
  }
  const updateCanvasSize = useCallback(() => {
    const container = canvasRef.current;
    if (container && canvas && activeSlide) {
      const aspectRatio = 16 / 9;  // Maintain aspect ratio of 16:9
      const containerWidth = container.offsetWidth; // Use offsetWidth to get width

      // Calculate new canvas dimensions maintaining aspect ratio
      const canvasWidth = containerWidth * (zoomLevel / 100);
      const canvasHeight = canvasWidth / aspectRatio;
      canvas.setWidth(canvasWidth);
      canvas.setHeight(canvasHeight);
      canvas.setWidth(canvasWidth);
      canvas.setZoom(zoomLevel / 100);
      canvas.renderAll();
    }
  }, [canvas, activeSlide, zoomLevel]);

  useEffect(() => {
    window.addEventListener('resize', updateCanvasSize);
    return () => {
      window.removeEventListener('resize', updateCanvasSize);
    };
  }, [updateCanvasSize]);

  useEffect(() => {
    if (zoomLevel) {
      updateCanvasSize();
    }
  }, [zoomLevel, updateCanvasSize]);


  const keyboardEvents = (e) => {
    const actions = new CanvasActions({ activeSlide, dispatch, clipBoard });
    if (e.keyCode === 46) {
      //Delete
      e.preventDefault();
      actions.delete();
    } else if (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) {
      //Copy
      e.preventDefault();
      actions.cutCopy();
    } else if (e.keyCode === 68 && (e.ctrlKey || e.metaKey)) {
      //Duplicate
      e.preventDefault();
      actions.duplicate();
    } else if (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) {
      // Paste
      e.preventDefault();
      actions.paste();
    } else if (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) {
      // Cut
      e.preventDefault();
      actions.cutCopy(true);

    }
  }

  return (
    <div className="relative reveal flex flex-col items-center justify-center bg-slate-300">
      {showCropper &&
        (
        <>
          <CropperPanel />
          <div className="fixed top-0 left-0 w-full h-full bg-transparent opacity-50 z-5000 " />
        </>
        )}
      <div className="flex-grow relative !h-full !w-full">
        <div className="slides flex items-center z-6000 !-translate-y-1/2 !-translate-x-1/2 !h-[calc(100%-40px)] !w-[calc(100%-40px)] !overflow-auto !pointer-events-auto" ref={canvasRef}>
          {slideList &&
            slideList.map((slide) => (
              <section key={slide.id} style={{ opacity: slide?.id === activeSlide?.id ? 1 : 0}}>
                <canvas id={`slide_deck_canvas_${slide.id}`} />
              </section>
            ))}
        </div>
      </div>
      <div className="flex bottom-0 shrink-0 w-full z-50 relative">
        <div className="zoom-controls absolute top-[5px] right-0">
          <div className="flex justify-end items-center select-none">
            <div
              className="w-5 h-5 bg-gray-400 rounded-full flex items-center justify-center text-white text-lg font-bold cursor-pointer mx-1"
              onClick={zoomOut}>
              <span className="select-none">-</span>
            </div>
            <input
              type="range"
              min="50"
              max="500"
              step="2"
              value={zoomLevel}
              onChange={handleRangeChange}
              className="h-1 bg-gray-300 rounded-lg appearance-none cursor-pointer accent-gray-400 select-none"
            />
            <div className="mx-2 text-xxs font-bold">
              {zoomLevel}
            </div>
            <div
              className="w-5 h-5 bg-gray-400 rounded-full flex items-center justify-center text-white text-lg font-bold cursor-pointer mx-1"
              onClick={zoomIn}>
              <span className="select-none">+</span>
            </div>
          </div>
        </div>
        <SlidesViewer />
      </div>
      <LoaderPopup />
    </div>
  );
}

export default RevealSlide;