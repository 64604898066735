import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";
import { api } from "../api/api";
import { useAlert } from "./AlertContext";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const idToken = urlParams.get("id_token");

  const [authState, setAuthState] = useState({
    isAuthenticated: false,
    user: null,
  });
  const { showAlert } = useAlert();

  const fetchUserInfo = useCallback(async () => {
    try {
      const response = await api.get("/api/users/me");
      if (response) {
        setAuthState({ isAuthenticated: true, user: response });
      }
    } catch (error) {
      console.error("Error fetching user info:", error);
    }
  }, []);

  const login = async (data) => {
    try {
      const response = await api.post(
        "/api/auth/local",
        {
          identifier: data.email,
          password: data.password,
        },
        true
      );
      if (response?.jwt) {
        localStorage.setItem("token", response.jwt);
        setAuthState({ isAuthenticated: true, user: response.user });

        const redirectTo = location.state?.redirectTo || "/projects";
        navigate(redirectTo);
      }
    } catch (error) {
      showAlert("error", error?.response?.data?.error?.message);
    }
  };

  const logout = () => {
    setAuthState({ isAuthenticated: false, user: null });
    localStorage.clear();
  };

  useEffect(() => {
    if (idToken) {
      const registerUser = async () => {
        const response = await api.post(
          "/api/google/auth/callback",
          {
            id_token: idToken,
          },
          true
        );
        if (response?.jwt) {
          localStorage.setItem("token", response.jwt);
          setAuthState({ isAuthenticated: true, user: response.user });
          navigate("/projects");
        }
      };
      registerUser();
    }
  }, [idToken, navigate]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token && !idToken) {
      fetchUserInfo();
    }
  }, [navigate, fetchUserInfo, idToken]);

  return (
    <AuthContext.Provider
      value={{ ...authState, login, fetchUserInfo, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return authContext;
};

const useCheckSubscription = () => {
  const apiToken = process.env.REACT_APP_STRAPI_ADMIN_API_TOKEN;

  const checkSubscription = useCallback(async (user) => {
    try {
      const response = await axios.get(
        `https://backend.ailms.co/strapi-stripe/getSubscriptionStatus/${user?.email}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiToken}`,
          },
        }
      );
      const isActive = response.data?.data?.some(
        (subscription) => subscription?.plan?.active
      );
      return isActive;
    } catch (error) {
      console.error(error);
      return false;
    }
  }, [apiToken]);

  return checkSubscription;
};

export { AuthProvider, AuthContext, useAuth, useCheckSubscription };
