import {createSlice} from '@reduxjs/toolkit'

const customLoader = createSlice({
    name: 'customLoader',
    initialState: {
        visibility: false,
        message: '',
    },
    reducers: {
        setLoaderVisibility: (state, action) => {
            state.visibility = action.payload;
        },
        setLoaderMessage: (state, action) => {
            state.message = action.payload;
        }
    }
});

export const {
    setLoaderVisibility,
    setLoaderMessage
} = customLoader.actions;
export default customLoader.reducer;