import React from 'react';
import TextPanel from "./TextPanel/TextPanel";
import ImagePanel from "./ImagePanel/ImagePanel";
import ShapesPanel from "./ShapePanel/ShapesPanel";
import VideoPanel from "./VideoPanel/VideoPanel";
import AudioPanel from "./AudioPanel/AudioPanel";
import DocsPanel from "./DocsPanel/DocsPanel";
import SettingsPanel from "./SettingsPanel/SettingsPanel"
import ComponentsPanel from "./ComponentsPanel/ComponentsPanel"
import QuizPanel from './QuizPanel/QuizPanel';
import AvatarPanel from './AvatarPanel/AvatarPanel';

function RightPanel({activeComponent}) {
    switch (activeComponent) {
        case "text":
            return (
                <TextPanel/>
            );
        case "shape":
            return (
                <ShapesPanel/>
            );
        case "image":
            return (
                <ImagePanel/>
            );
        case 'video':
            return (
                <VideoPanel/>
            );
        case 'audio':
            return (
                <AudioPanel/>
            );
        case 'components':
            return (
                <ComponentsPanel/>
            );
        case 'quiz':
            return (
                <QuizPanel/>
            );
        case 'docs':
            return (
                <DocsPanel/>
            );
        case 'settings':
            return (
                <SettingsPanel/>
            )
        case "avatar":
          return <AvatarPanel />;
        default:
            return (
                <div className="flex flex-col w-[280px] overflow-y-auto bg-gray-50 p-4">
                    Default Component here 
                </div>
            );
    }
}

export default RightPanel;