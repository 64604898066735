import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { fabric } from 'fabric';
import { v4 as uuidv4 } from 'uuid';
import { shallowEqual } from 'react-redux';
import { setAllMediaToSlide } from '../../../store/reducers/slideListSlice';
import { useDispatch } from 'react-redux';
import LayerAndLinkControl from '../TextPanel/LayerAndLinkControl';
import { faPlay, faStop, faPause, faLink, faForward, faBackward } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const tailwindColorHex = {
  'bg-blue-500': '#3b82f6',
  'bg-red-500': '#ef4444',
  'bg-green-500': '#10b981',
  'bg-yellow-500': '#f59e0b',
  'bg-purple-500': '#8b5cf6',
  'bg-pink-500': '#ec4899',
};

const tailwindHoverColorHex = {
  'bg-blue-500': '#2563eb',
  'bg-red-500': '#dc2626',
  'bg-green-500': '#059669',
  'bg-yellow-500': '#d97706',
  'bg-purple-500': '#7c3aed',
  'bg-pink-500': '#f562a6',
  // Add more Tailwind color classes as needed
};

const Button = () => {
  const { canvas, activeObject } = useSelector((state) => state.fabricCanvas);
  const { activeSlide } = useSelector(state => state.slideList, shallowEqual);
  const [selectedStyle, setSelectedStyle] = useState('style1');
  const [buttonText, setButtonText] = useState('Button');
  const [buttonBgColor, setButtonBgColor] = useState('bg-blue-500');
  const [selectedAction, setSelectedAction] = useState('');
  const dispatch = useDispatch()

  // Function to calculate hover color
  const getHoverColor = (colorClass) => {
    if (!colorClass) return '';
    const colorParts = colorClass.split('-');
    const baseColor = colorParts.slice(0, -1).join('-');
    const colorIntensity = parseInt(colorParts[colorParts.length - 1], 10);
    const hoverIntensity = colorIntensity + 200; // Calculate hover intensity
    return `${baseColor}-${hoverIntensity}`;
  };

  const buttonStyles = {
    style1: `${buttonBgColor} hover:${getHoverColor(buttonBgColor)} text-white font-bold py-2 px-4 rounded relative z-50`,
    style2: `${buttonBgColor} hover:${getHoverColor(buttonBgColor)} text-white font-bold py-2 px-4 rounded-full relative z-50`,
    style3: `bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded relative z-50`,
    style4: `${buttonBgColor} hover:${getHoverColor(buttonBgColor)} text-white font-bold py-2 px-4 border-b-4 border-blue-700 hover:border-blue-500 rounded relative z-50`,
  };

  const canvasButtonStyles = {
    style1: {
      fill: tailwindColorHex[buttonBgColor],
      color: 'white',
      fontWeight: 'bold',
      buttonType: 'standard',
      hoverFill: tailwindHoverColorHex[buttonBgColor],
    },
    style2: {
      fill: tailwindColorHex[buttonBgColor],
      color: 'white',
      fontWeight: 'bold',
      buttonType: 'pill',
      hoverFill: tailwindHoverColorHex[buttonBgColor],
    },
    style3: {
      fill: 'transparent',
      color: tailwindColorHex[buttonBgColor],
      fontWeight: 'bold',
      hoverFill: tailwindHoverColorHex[buttonBgColor],
      hoverColor: 'white',
      buttonType: 'outlined',
      borderColor: tailwindColorHex[buttonBgColor]
    },
    style4: {
      fill: tailwindColorHex[buttonBgColor],
      color: 'white',
      fontWeight: 'bold',
      borderBottom: '4px solid #007bff',
      hoverFill: tailwindHoverColorHex[buttonBgColor],
      buttonType: '3D',
    },
  };

  const tailwindColors = [
    "bg-blue-500",
    "bg-red-500",
    "bg-green-500",
    "bg-yellow-500",
    "bg-purple-500",
    "bg-pink-500",
    // Add more Tailwind color classes as needed
  ];

  // Add element to slide
  const handleAddElement = (iconType = '') => {
    // Assuming canvas.lowerCanvasEl.id dynamically identifies the target div
    const targetSlides = document.getElementsByClassName('canvas-container');

    if (targetSlides.length === 0 || !canvas) {
      console.error('Target slide not found');
      return;
    }

    // buttonEl.className = selectedStyle ? buttonStyles[selectedStyle] : '';
    // Ensure there is always a valid text for the button
    const buttonTextValue = buttonText || 'Button'; // Default to 'Button' if buttonText is falsy
    const id = uuidv4();
    const iconData = iconType ? icons[iconType] : ''; // Get the appropriate icon for the input type
    const svgPath = iconData ? iconData.icon[4] : ''; // The SVG path data
    // Create a rectangle to simulate the button background
    console.log('selectedStyle', selectedStyle, canvasButtonStyles[selectedStyle]);
    const {
      fill,
      hoverFill,
      borderRadius,
      color,
      fontWeight,
      hoverColor = '',
      buttonType = '',
      borderColor = ''
    } = selectedStyle
        ? canvasButtonStyles[selectedStyle]
        : canvasButtonStyles.style1;
    dispatch(setAllMediaToSlide({ type: 'button', data: { label: buttonTextValue, id } }));
    const button = new fabric.StaticButton({
      id,
      label: buttonTextValue,
      fill, // Default fill color
      hoverFill, // Color when hovered
      borderRadius,
      color,
      fontWeight,
      hoverColor,
      buttonType,
      borderColor,
      clickHandler: () => {
        console.log('Button clicked!');
      },
      svgPath,
      type: 'button',
      slideId: activeSlide?.id
    });
    // Add the button to canvas
    canvas.add(button);
    canvas.renderAll();
  };
  const icons = {
    play: faPlay,
    pause: faPause,
    stop: faStop,
    url: faLink,
    next: faForward,
    back: faBackward,
  };
  const buttonActions = [
    { value: 'play', label: 'Play' },
    { value: 'pause', label: 'Pause' },
    { value: 'stop', label: 'Stop' },
    { value: 'url', label: 'Open Url' },
    { value: 'next', label: 'Next' },
    { value: 'back', label: 'Previous' },
  ];

  const renderButton = (type = '', buttonText) => {
    const Icon = type ? icons[type] : ''; // Get the appropriate icon for the input type
    return (
      <div className="relative mt-2">
        <button className={`${selectedStyle ? buttonStyles[selectedStyle] : ''} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 inline-flex items-center`}>
          {Icon && <FontAwesomeIcon icon={Icon} className="w-4 h-4 mr-1" />}
          <p>
            {buttonText}
          </p>
        </button>
      </div>
    );
  };

  return (
    <div>
      <div className="mb-5"> {/* Changed marginBottom: '20px' to Tailwind's mb-5 */}
        <label className="block text-lg font-semibold mb-2">Button Preview:</label> {/* Example of adding classes for the label */}
        {renderButton(selectedAction, buttonText)}
      </div>
      <div className="mb-4"> {/* Changed marginBottom: '10px' to Tailwind's mb-4 */}
        <label className="block text-lg font-semibold mb-2">Button Text:</label>
        <input type="text" value={buttonText} onChange={(e) => setButtonText(e.target.value)} className="input bg-white border border-gray-300 rounded py-2 px-4 block w-full focus:border-blue-500 focus:ring-blue-500" />
      </div>
      <div className="mb-4"> {/* Tailwind's mb-4 */}
        <label className="block text-lg font-semibold mb-2">Select Button Style:</label>
        <select onChange={(e) => setSelectedStyle(e.target.value)} value={selectedStyle} className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500">
          <option value="style1">Sdandard</option>
          <option value="style2">Pill Button</option>
          <option value="style3">Outline</option>
          <option value="style4">3D Button</option>
        </select>
      </div>
      <div className="mb-4">
        <label className="block text-lg font-semibold mb-2">Select Action:</label>
        <select onChange={(e) => {
          setSelectedAction(e.target.value);
        }}
          value={selectedAction} className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500">
          <option key="default" value="">{selectedAction ? 'Remove Action' : 'Select Action'}</option>
          {buttonActions.map((type) => (
            <option key={type.value} value={type.value}>{type.label}</option>
          ))}
        </select>
      </div>
      <div className="mb-4"> {/* Tailwind's mb-4 */}
        <label className="block text-lg font-semibold mb-2">Select Button Background Color:</label>
        <select onChange={(e) => setButtonBgColor(e.target.value)} value={buttonBgColor} className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500">
          {tailwindColors.map((color, index) => (
            <option key={index} value={color}>{color.replace('bg-', '').replace('-500', '')}</option>
          ))}
        </select>
        <div className="mb-4 p-4">
          {/* Insert the Add to Canvas button with the onClick handler */}
          <button onClick={() => handleAddElement(selectedAction)}>Add to Canvas</button>
        </div>
      </div>
      {activeObject && activeObject?.type === 'button' && (
        <LayerAndLinkControl />
      )}
    </div>

  );
};

export default Button;