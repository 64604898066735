import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {changeJumpToSlidePopupStatus, changeLinkPopupStatus} from "../../../utils/helpers";

const TextFontControl = () => {

    const {activeSlide} = useSelector(state => state.slideList);
    const {activeObject, fontList, globalFontSize} = useSelector(state => state.fabricCanvas);
    const [fontFamily, setFontFamily] = useState("Open Sans");
    const [fontSize, setFontSize] = useState(12);
    const dispatch = useDispatch();


    useEffect(() => {
        if (activeObject) {
            const {fontSize, fontFamily} = activeObject;
            setFontSize(fontSize);
            setFontFamily(fontFamily);
        }
    }, [activeObject,globalFontSize]);

    // Handler for text font family selection
    const handleTextFontFamilyChange = (event) => {
        const {canvas} = activeSlide;
        const newValue = event.target.value;
        setFontFamily(newValue);
        activeObject.set({
            fontFamily: newValue,
        });
        changeLinkPopupStatus(canvas, true, dispatch);
        changeJumpToSlidePopupStatus(canvas, true, dispatch);
        canvas.renderAll();
    }

    // Handler for text size selection
    const handleTextSizeChange = (event) => {
        const {canvas} = activeSlide;
        const newValue = parseFloat(event.target.value);
        setFontSize(newValue);
        activeObject.set({
            fontSize: newValue,
        });
        changeLinkPopupStatus(canvas, true, dispatch);
        changeJumpToSlidePopupStatus(canvas, true, dispatch);
        canvas.renderAll();
    }

    return (
        <>
            <label htmlFor="fontSelector" className="mb-2 block text-xs font-medium text-gray-600">Text Settings</label>
            <div className="mb-4 flex items-center justify-between space-x-4">
                {
                    fontList.length > 0 && (
                        <select
                            id="fontSelect"
                            className="h-10 flex-grow rounded-md border p-2 text-xs text-gray-600"
                            style={{fontFamily: fontFamily}}
                            onChange={handleTextFontFamilyChange}
                            value={fontFamily}
                        >
                            {fontList && fontList.map((font, index) => (
                                <option key={index} value={font.family}
                                        style={{fontFamily: font.family}} className="h-10 flex-grow rounded-md border p-2 text-xs text-gray-600">
                                    {font.family}
                                </option>
                            ))}
                        </select>
                    )
                }
                <input
                    id="fontSize"
                    className="h-10 w-[70px] rounded-md border p-2 text-xs"
                    onChange={handleTextSizeChange}
                    value={fontSize}
                    type="number"
                    min={1}
                    max={50}
                />
            </div>
        </>
    )
}
export default TextFontControl;