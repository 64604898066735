import { configureStore} from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import slideList from "./reducers/slideListSlice";
import fabricCanvas from "./reducers/canvasSlice";
import imageBucket from "./reducers/imageBucketSlice";
import customLoader from "./reducers/loaderSlice";
import cropper from "./reducers/cropperSlice";
import avatars from "./reducers/avatarSlice";
import projects from "./reducers/projectListSlice";


const reducer = combineReducers({
  slideList,
  fabricCanvas,
  imageBucket,
  customLoader,
  cropper,
  avatars,
  projects
});

export default configureStore({
    reducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }),
    devTools: {
        shouldCatchErrors: true,
        actionsBlacklist: ['largeAction'],
        maxAge: 50, // Adjust the number of actions retained in the DevTools
      }
})

