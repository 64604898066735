import {v4 as uuidv4} from "uuid";
export default class imageFile{
    constructor(fileUrl) {
        this.id = uuidv4();
        this.url = fileUrl;
    }

    getId = () => {
        return this.id;
    }

    getUrl = () => {
        return this.url;
    }

}