import React, { useState } from 'react'; // Import useState
import { useNavigate } from 'react-router-dom'; // Updated to useNavigate instead of useHistory
import Topbar from '../../Topbar/Topbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCreditCard, faRocket, faArrowCircleUp, faPencil, faFileContract, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import useArticles from './useArticles'; // Import the custom hook

const Docs = () => {
  const navigate = useNavigate(); // Updated to useNavigate
  const { articles, loading, error } = useArticles(); // Use the custom hook
  const [searchQuery, setSearchQuery] = useState(''); // State for search query
  const [searchResults, setSearchResults] = useState([]); // State for search results

  // Function to handle search
  const handleSearch = (query) => {
    setSearchQuery(query);
    if (query.length > 0 && Array.isArray(articles)) {
      const results = articles.filter(article =>
        article.title.toLowerCase().includes(query.toLowerCase()) ||
        article.content.toLowerCase().includes(query.toLowerCase())
      );
      setSearchResults(results);
    } else {
      setSearchResults([]);
    }
  };

  // Function to handle category clicks and navigate to the respective category's article page
  const handleCategoryClick = (slug) => {
    navigate(`/docs/category/${slug}`); // Updated to use navigate
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading articles</div>;

  return (
    <>
      <Topbar />
      <div className="p-8 flex flex-col items-center">
        
        {/* Jumbotron with Search Bar */}
        <div className="bg-teal-500 text-white text-center py-12 w-full">
          <h1 className="text-4xl font-bold mb-4">How can we help?</h1>
          <p className="mb-6">Our Docs are here to help</p>
          <div className="max-w-md mx-auto relative"> {/* Changed max-w-lg to max-w-md */}
            <input
              type="text"
              placeholder="Search the knowledge base..."
              className="w-full p-4 pl-12 rounded-full text-gray-800"
              value={searchQuery}
              onChange={(e) => handleSearch(e.target.value)}
            />
            <FontAwesomeIcon icon={faMagnifyingGlass} className="w-6 h-6 absolute left-3 top-4 text-gray-400" />
            {searchResults.length > 0 && (
              <div className="absolute bg-white shadow-lg rounded-lg mt-2 w-full z-10">
                {searchResults.map((result, index) => (
                  <div
                    key={index}
                    className="p-4 cursor-pointer hover:bg-gray-100"
                    onClick={() => handleCategoryClick(result.slug)}
                  >
                    {result.title}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        
        {/* Section below Jumbotron */}
        <div className="flex w-full flex-col md:flex-row"> {/* Added flex-col for mobile */}
          {/* 2/3 Section */}
          <div className="w-full md:w-2/3 border-r">
            {/* Documentation Section */}
            <div className="py-8 px-4">
              <div className="max-w-6xl mx-auto">
                {/* Help Topics */}
                <div>
                  <h2 className="text-2xl font-semibold mb-4">Help Topics</h2>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {/* Category Block for 'My Account' */}
                    <div
                      className="bg-white p-4 rounded-lg shadow flex items-center cursor-pointer"
                      onClick={() => handleCategoryClick('my-account')} // Navigating to My Account category
                    >
                      <FontAwesomeIcon icon={faUser} className="w-6 h-6 text-teal-500 mr-4" />
                      <div>
                        <h3 className="text-lg font-semibold">My Account</h3>
                        <p>How to manage your account and its features.</p>
                      </div>
                    </div>

                    {/* Category Block for 'Billing & Payments' */}
                    <div
                      className="bg-white p-4 rounded-lg shadow flex items-center cursor-pointer"
                      onClick={() => handleCategoryClick('billing-payments')} // Navigating to Billing & Payments category
                    >
                      <FontAwesomeIcon icon={faCreditCard} className="w-6 h-6 text-teal-500 mr-4" />
                      <div>
                        <h3 className="text-lg font-semibold">Billing & Payments</h3>
                        <p>Information about how we charge you for our services.</p>
                      </div>
                    </div>

                    {/* Category Block for 'Getting Started' */}
                    <div
                      className="bg-white p-4 rounded-lg shadow flex items-center cursor-pointer"
                      onClick={() => handleCategoryClick('getting-started')} // Navigating to Getting Started category
                    >
                      <FontAwesomeIcon icon={faRocket} className="w-6 h-6 text-teal-500 mr-4" />
                      <div>
                        <h3 className="text-lg font-semibold">Getting Started</h3>
                        <p>Articles to get you up running quick and easy.</p>
                      </div>
                    </div>
                    {/* Category Block for 'SCROM and LMS' */}
                    <div
                      className="bg-white p-4 rounded-lg shadow flex items-center cursor-pointer"
                      onClick={() => handleCategoryClick('scrom-export-and-lms')} 
                    >
                      <FontAwesomeIcon icon={faArrowCircleUp} className="w-6 h-6 text-teal-500 mr-4" />
                      <div>
                        <h3 className="text-lg font-semibold">SCROM Export and LMS</h3>
                        <p>Articles to get you up running quick and easy.</p>
                      </div>
                    </div>
                    {/* Category Block for 'Editor' */}
                    <div
                      className="bg-white p-4 rounded-lg shadow flex items-center cursor-pointer"
                      onClick={() => handleCategoryClick('project-editor')} 
                    >
                      <FontAwesomeIcon icon={faPencil} className="w-6 h-6 text-teal-500 mr-4" />
                      <div>
                        <h3 className="text-lg font-semibold">Project Editor</h3>
                        <p>Articles to get you up running quick and easy.</p>
                      </div>
                    </div>
                    {/* Category Block for 'Legal & Copyrights' */}
                    <div
                      className="bg-white p-4 rounded-lg shadow flex items-center cursor-pointer"
                      onClick={() => handleCategoryClick('legal-and-copyrights')} 
                    >
                      <FontAwesomeIcon icon={faFileContract} className="w-6 h-6 text-teal-500 mr-4" />
                      <div>
                        <h3 className="text-lg font-semibold">Legal & Copyrights</h3>
                        <p>Articles to get you up running quick and easy.</p>
                      </div>
                    </div>
                    {/* Add more categories similarly... */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          {/* 1/3 Section */}
          <div className="w-full md:w-1/3 border-l mt-4 md:mt-0"> {/* Adjusted width and margin for mobile */}
            {/* Support Section */}
            <div className="p-4">
              <h2 className="text-2xl font-semibold mb-4">Need Support?</h2>
              <p>Can't find the answer you're looking for? We're here to help!</p>
              <button className="mt-4 bg-teal-500 text-white p-4 rounded-lg w-full">Contact Support</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Docs;
