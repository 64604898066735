import React, { useState } from "react";
import Sidebar from "./Sidebar";
import Headers from "./Header";
import { EditIcon } from "../../../utils/iconHelper";
import { useSelector } from "react-redux";
import { shallowEqual } from "react-redux";
import { useDispatch } from "react-redux";
import { api } from "../../../api/api";
import {
  deleteProject,
  setProjects,
} from "../../../store/reducers/projectListSlice";
import baseUrl from "../../../api/config";
import SaveProject from "../SaveProject/SaveProject";
import Dialog from "../../../components/Dialog/Dialog";
import moment from "moment/moment";
import { useAlert } from "../../../context/AlertContext";

const ListOfProject = () => {

  const { projects } = useSelector((state) => state.projects, shallowEqual);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [editData, setEditData] = useState(undefined);
  const [isDeleteDialog, setIsDeleteDialog] = useState("");

  const dispatch = useDispatch();
  const { showAlert } = useAlert();

  const openDialog = () => setIsDialogOpen(true);
  const closeDialog = () => {
    setIsDialogOpen(false);
    setEditData(undefined);
  };

  const fetchProjects = React.useCallback(async () => {
    const response = await api.get("/api/projects");
    if (response.data) {
      dispatch(setProjects(response.data));
    }
  }, [dispatch]);

  React.useEffect(() => {
    fetchProjects();
  }, [fetchProjects]);

  const userData = {
    name: "John Doe",
    email: "john.doe@example.com",
    joinedDate: "2023-01-15",
    status: "Active",
    nextPaymentDate: "2024-08-15",
  };

  const navigateToEditor = (projectId) => {
    window.open(`/editor/${projectId}`, '_self');
  };

  const deleteProjects = async (projectId) => {
    try {
      const deleteResponse = await api.delete(`/api/projects/${projectId}`);
      if (deleteResponse) {
        showAlert("success", "Project deleted successfully!");
        setIsDeleteDialog("");
        dispatch(deleteProject(projectId));
      }
    } catch (error) {
      showAlert(
        "error",
        error?.response?.data?.error?.message || "Error in deleting a project"
      );
    }
  };

  return (
    <div className="flex h-screen">
      <Sidebar />
      <div className="flex-1 flex flex-col ">
        <Headers />
        <main className="flex-1 bg-black text-white p-4 max-h-[calc(100vh - 75px)] overflow-auto">
          <div className="flex flex-col md:flex-row md:justify-between md:items-start">
            <div className="flex flex-col flex-grow mb-4 md:mb-0">
              <div className="flex items-center justify-between mb-4">
                <h1 className="text-2xl md:text-3xl font-bold">My Projects</h1>
                <button
                  className="flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600"
                  onClick={openDialog}
                >
                  Add Project
                </button>
                {/* <SaveProject /> */}
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 lg:grid-cols-4  gap-4">
                {projects.map((project) => (
                  <div
                    key={project?.project_id}
                    className="bg-black border-gray-700 border-2 rounded-xl flex flex-col cursor-pointer"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      navigateToEditor(project?.project_id);
                    }}
                  >
                    {project?.project_image?.url ? (
                      <img
                        src={`${baseUrl}${project?.project_image?.url}`}
                        alt={project?.name}
                        className="w-full h-64 object-cover rounded-t-xl"
                      />
                    ) : (
                      <div className="w-full h-64 object-cover rounded-t-xl bg-slate-200" />
                    )}
                    <hr className="border-gray-700" />
                    <div className="p-4 flex flex-col justify-between flex-grow">
                      <h3 className="text-lg font-semibold">{project?.name}</h3>
                      <p className="text-sm mt-2">
                        {project?.start_date ||
                          moment(project?.createdAt).format("l") ||
                          "-"}
                      </p>
                      <div className="flex justify-end mt-2">
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setIsDeleteDialog(project?.project_id);
                          }}
                          className="bg-red-500 text-white p-2 rounded-md flex items-center max-w-28 mr-2"
                        >
                          Delete
                        </button>
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setIsDialogOpen(true);
                            setEditData({
                              name: project?.name,
                              project_image: project?.project_image,
                              id: project?.project_id
                            });
                          }}
                          className="bg-[#0062FF] text-white p-2 rounded-md flex items-center max-w-28"
                        >
                          <div className="w-4 h-4 bg-[#4f8cf180] rounded-full flex items-center justify-center mr-2">
                            <EditIcon className="text-white w-4 h-4" />
                          </div>
                          Edit
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex flex-col space-y-4 md:mx-8">
              <h2 className="text-lg font-semibold mb-2">Filter by</h2>
              <div className="py-2">
                <select className="bg-black border border-white rounded-xl py-2 px-4 w-full md:w-[200px]">
                  <option>This week</option>
                </select>
              </div>
              <div className="py-2">
                <select className="bg-black border border-white rounded-xl py-2 px-4 w-full md:w-[200px]">
                  <option>Global</option>
                </select>
              </div>
              <div className="py-2">
                <select className="bg-black border border-white rounded-xl py-2 px-4 w-full md:w-[200px]">
                  <option>Any format</option>
                </select>
              </div>
              <div className="py-10">
                <h2 className="text-lg font-semibold mb-2">User Profile</h2>
                <div className="space-y-4">
                  <div>
                    <strong>Name</strong>
                    <div className="mt-2">{userData.name}</div>
                  </div>
                  <div>
                    <strong>Email</strong>
                    <div className="mt-2">{userData.email}</div>
                  </div>
                  <div>
                    <strong>Joined Date</strong>
                    <div className="mt-2">{userData.joinedDate}</div>
                  </div>
                  <div>
                    <strong>Status</strong>
                    <div className="mt-2">{userData.status}</div>
                  </div>
                  <div>
                    <strong>Next Payment Date</strong>
                    <div className="mt-2">{userData.nextPaymentDate}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Dialog
          isOpen={isDialogOpen}
          title={editData ? "Edit Project" : "Add Project"}
          onClose={closeDialog}
        >
          <SaveProject onclose={closeDialog} initialValues={editData} isEdit={!!editData} />
        </Dialog>
        {isDeleteDialog?.length ? (
          <Dialog
            isOpen={isDeleteDialog?.length}
            title="Delete Project"
            onClose={() => setIsDeleteDialog("")}
          >
            <p className="mt-6">Are you sure you want to delete project?</p>
            <div className="flex justify-end">
              <button
                type="button"
                onClick={() => setIsDeleteDialog("")}
                className="mt-6 py-2 px-4 bg-gray-500 text-white rounded-md mr-2"
              >
                Cancel
              </button>
              <button
                className="mt-6 bg-red-500 text-white px-4 py-2 rounded-md"
                onClick={() => deleteProjects(isDeleteDialog)}
              >
                Delete
              </button>
            </div>
          </Dialog>
        ) : null}
      </div>
    </div>
  );
};

export default ListOfProject;
