import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Sidebar({ onActiveComponentChange }) {
    const renderIconWithText = (icon, text, onClick) => (
        <div className="flex flex-col items-center justify-center p-2 cursor-pointer size-[40px] transition-colors duration-200 text-gray-700 mt-2 hover:text-gray-500" onClick={onClick}>
            <FontAwesomeIcon icon={icon} className="size-4"/>
            <span className="mt-1" style={{ fontSize: '6px' }}>{text}</span>
        </div>
    );

    return (
        <div className="flex flex-col h-full bg-white items-center shadow-md">
            {renderIconWithText('text-height', 'Text', () => onActiveComponentChange('text'))}
            {renderIconWithText('vector-square', 'Shape', () => onActiveComponentChange('shape'))}
            {renderIconWithText('image', 'Image', () => onActiveComponentChange('image'))}
            {renderIconWithText('video', 'Video', () => onActiveComponentChange('video'))}
            {renderIconWithText('music', 'Audio', () => onActiveComponentChange('audio'))}
            {renderIconWithText('puzzle-piece', 'Components', () => onActiveComponentChange('components'))}
            {renderIconWithText('gauge-high', 'Quiz', () => onActiveComponentChange('quiz'))}
            {renderIconWithText("user-tie", "Avatar", () => onActiveComponentChange("avatar"))}
            <div className="border-t border-gray-400 w-2/3 mt-auto"></div>

            {renderIconWithText('gear', 'Settings', () => onActiveComponentChange('settings'))}
            {renderIconWithText('book', 'Docs', () => onActiveComponentChange('docs'))}
        </div>
    );
}

export default Sidebar;
