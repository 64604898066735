import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import PaymentSuccessModal from './PaymentSuccessModal';

const PaymentSuccessContainer = () => {
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();

  const handleClose = useCallback(() => {
    setOpen(false);
    navigate('/projects');
  }, [navigate]);

  useEffect(() => {
    const timer = setTimeout(() => {
      handleClose();
    }, 5000);
    return () => clearTimeout(timer);
  }, [handleClose]);

  return (
    <div>
      <PaymentSuccessModal open={open} onClose={handleClose} />
    </div>
  );
};

export default PaymentSuccessContainer;
