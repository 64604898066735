import { fabric } from 'fabric';
import { getFabricImageFromURL } from '../helpers';

export class StaticCard extends fabric.Group {
  static type = 'card';

  async initialize(options, canvas) {
    const defaultOpts = {
      width: 200,
      height: 300,
      fill: '#FFFFFF', // Default fill color
      cardImg: '',
    };

    const {
      label,
      labelLong,
      fill, // Default fill color
      width,
      height,
      cardImg,
      handleCardLoading = () => {},
    } = options || {};

    const cardBg = new fabric.Rect({
      width: width || defaultOpts.width,
      height: height || defaultOpts.height,
      fill: fill || defaultOpts.fill,
      rx: 10,
      ry: 10,
      id: 'cardBg',
      shadow: new fabric.Shadow({
        color: 'rgba(0, 0, 0, 0.4)', // Shadow color as semi-transparent black
        blur: 15, // Blur size
        offsetX: 5, // Horizontal shadow offset
        offsetY: 5, // Vertical shadow offset
      })
    });
    const pathCommands = [
      'M', 10, 0,                      // Move to the start of the top left curve
      'H', cardBg.width - 10,          // Horizontal line to the start of the top right curve
      'A', 10, 10, 0, 0, 1, cardBg.width, 10,  // Arc to make top right corner rounded
      'V', cardBg.height / 2,          // Vertical line down to the bottom right corner
      'H', 0,                          // Horizontal line to the bottom left corner
      'V', 10,                         // Vertical line up to the start of the top left curve
      'A', 10, 10, 0, 0, 1, 10, 0      // Arc to make top left corner rounded
    ].join(' ');
    
    const imagePlaceholder = new fabric.Path(pathCommands, {
      fill: '#cbd5e1',
      top: 0,
      originX: 'center',
      originY: 'top',
      selectable: false,
      id: 'imagePlaceholder',
    });

    const cardText = new fabric.StaticText({
      text: label || 'Button',
      width: width || defaultOpts.width,
      fontSize: 14,
      fill: '#000000',
      fontWeight: 'bold',
      textAlign: 'left',
      fontFamily: 'Verdana',
      borderWidth: 0,
      borderStyle: 'none',
      borderFill: 'rgb(0,0,0)',
      listStyle: 'none',
      originX: 'center',
      originY: 'top',
      top: imagePlaceholder.height +20 ,
      left: cardBg.width /2,
      id: 'cardText',
    });

    const cardTextLong = new fabric.StaticText({
      text: labelLong || 'Text Long',
      width: width || defaultOpts.width,
      fontSize: 12,
      fill: '#4a5568',
      fontWeight: 'normal',
      textAlign: 'left',
      fontFamily: 'Open Sans',
      borderWidth: 0,
      borderStyle: 'none',
      borderFill: 'rgb(0,0,0)',
      listStyle: 'none',
      originX: 'center',
      originY: 'top',
      top: imagePlaceholder.height + cardText.height + 30 ,
      left: cardBg.width /2,
      id: 'cardTextLong',
    });
    cardBg.set('height', imagePlaceholder.height + cardText.height + cardTextLong.height + 50);
    this.setControlsVisibility({
      mt: false, // Middle top control
      mtr: false, // Middle bottom control
    });
    
     // Disable scaling flip
     this.lockScalingFlip = true;
     // Add scaling event listener to lock aspect ratio
     this.on('scaling', function () {
      const w = this.width * this.scaleX;
      const h = this.height * this.scaleY;
      const aspectRatio = w / h;

      // Determine the aspect ratio based on initial scale
      if (aspectRatio > 1) {
          // Width is greater than height, lock horizontal scaling to vertical
          this.scaleX = this.scaleY;
      } else {
          // Height is greater than width, lock vertical scaling to horizontal
          this.scaleY = this.scaleX;
      }
  });
  if (cardImg) {
    getFabricImageFromURL(
      cardImg,
      (fabricImage) => {
     
          // Apply the calculated scale to both width and height to maintain aspect ratio
          fabricImage.scaleToHeight(imagePlaceholder.height);
          fabricImage.scaleToWidth(imagePlaceholder.width);
    
          // Update the image properties
          fabricImage.set({
            left: 0,
            top: 0,
          });
    
          // Set controls visibility to hide unnecessary controls
          fabricImage.setControlsVisibility({
            mt: false, // Middle top control
            mb: false, // Middle bottom control
          });
           const clipPath = new fabric.Rect({
                width: fabricImage.width,
                height: fabricImage.height,
                originX: 'center',
                originY: 'center',
                rx: 30, // Adjust the radius as needed
                ry: 30, // Adjust the radius as needed
            });
            // Apply clip path to the image
            fabricImage.set('clipPath', clipPath);

          // Get the scaled height and width
          const scaledHeight = fabricImage.height * fabricImage.scaleY;
          cardText.set('top', scaledHeight + 20);
          cardTextLong.set('top', scaledHeight + cardText.height + 30);
          cardBg.set('height', scaledHeight + cardText.height + cardTextLong.height + 50);
          handleCardLoading(false);
          super.initialize([cardBg, fabricImage, cardText, cardTextLong], options);
          // Trigger canvas render
          canvas.requestRenderAll();
        },
        () => {
          super.initialize([cardBg, imagePlaceholder, cardText, cardTextLong], options);
          handleCardLoading(false);
        }
      );
    } else {
      super.initialize([cardBg, imagePlaceholder, cardText, cardTextLong], options);
      handleCardLoading(false);
    }
  }

  toObject(propertiesToInclude = []) {
    return fabric.util.object.extend(this.callSuper('toObject', propertiesToInclude), {
        id: this.id,
        label: this.label,
        labelLong: this.labelLong,
        cardImg: this.cardImg,
        slideId: this.slideId,
        cardType: this.cardType,
        // Include other custom properties as necessary
    });
}

  toJSON(propertiesToInclude = []) {
    return fabric.util.object.extend(
      super.toObject.call(this, propertiesToInclude),
      {}
    );
  }
}

fabric.StaticCard = fabric.util.createClass(StaticCard, {
  type: StaticCard.type,
});

if (!fabric.StaticCard) {
  fabric.StaticCard = StaticCard;
}
