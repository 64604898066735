import React, { useEffect, useState } from "react";
import axios from "axios";
import "./index.css";
import Introduction from "./Introduction";
import Selection from "./Selection";
import Confirmation from "./Confirmation";
import { addNewAvatar } from "../../store/reducers/avatarSlice";
import { useDispatch } from "react-redux";
import s3B from "../../utils/s3Config";
import { S3_BUCKET_NAME } from "../../utils/helpers";
import VideoFile from "../../utils/videoFile";
import ProgressBar from "../Common/ProgressBar";
import { truncateAudio } from "./util";

const AvatarForm = ({ onClose }) => {
  const dispatch = useDispatch();

  const [sampleData, setSampleData] = useState({
    name: "",
    audio: "",
    character: "",
  });

  const [data, setData] = useState({
    name: "",
    audio: "",
    character: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isSample, setIsSample] = useState(false);
  const [progress, setProgress] = useState(5);

  const [currentStep, setCurrentStep] = useState(1);

  const headers = {
    Authorization: `Bearer ${process.env["REACT_APP_API_TOKEN"]}`,
    "Content-Type": "application/json",
  };
  const url = "https://peterkros-sadtalker.hf.space/run/generate_full_video";

  const [sampleVideo, setSampleVideo] = useState(null);
  useEffect(() => {
    let interval;

    if (loading) {
      interval = setInterval(() => {
        setProgress((oldProgress) => {
          const newProgress = oldProgress + 5;
          if (newProgress >= 90) {
            clearInterval(interval);
            return 90;
          }
          return newProgress;
        });
      }, 3000);
    } else {
      clearInterval(interval);
    }
  }, [loading]);

  const encodeFileToBase64 = (file) => {
    if (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result.split(",")[1]);
        reader.onerror = (error) => reject(error);
      });
    }

    return "";
  };

  const getEncodedImage = async () => {
    const imageUrl = "/test_character.png";
    let imageBlob = await fetch(imageUrl).then((response) => response.blob());
    return await encodeFileToBase64(imageBlob);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProgress(0)

    try {
      if (!data.name) {
        setError("Please select character type!");
        return;
      }
      if (!data.audio) {
        setError("Please select audio!");
        return;
      }

      setLoading(true);
      setError(null);

      let audioBlob = await fetch(data.audio.url).then((response) =>
        response.blob()
      );

      const encodedAudio = await encodeFileToBase64(audioBlob);
      const encodedImage = await getEncodedImage();

      const payload = {
        data: [
          `data:image/png;base64,${encodedImage}`,
          {
            name: "audio.wav",
            data: `data:audio/wav;base64,${encodedAudio}`,
          },
          "Transparent",
        ],
      };
      // dispatch(setLoading(true))
      const generatedFile = await generateVideo(payload);
      // dispatch(setLoading(false))
      dispatch(addNewAvatar({ data: generatedFile }));
      setProgress(100)
      onClose();
    } catch (error) {
      console.error(
        "Error uploading file:",
        error.response ? error.response.data : error
      );
      setError("Error uploading file.");
    } finally {
      setLoading(false);
    }
  };

  const handleCreateSample = async (e) => {
    setProgress(0)
    e.preventDefault();
    try {
      setSampleVideo(null);
      if (!sampleData.name) {
        setError("Please select character type!");
        return;
      }
      if (!sampleData.audio) {
        setError("Please select audio!");
        return;
      }

      setLoading(true);
      setError(null);

      const audioUrl = sampleData.audio.url;
      let audioBlob = await fetch(audioUrl).then((response) => response.blob());

      const truncatedBlob = await truncateAudio(audioBlob)
      const encodedAudio = await encodeFileToBase64(truncatedBlob);
      const encodedImage = await getEncodedImage();

      const payload = {
        data: [
          `data:image/png;base64,${encodedImage}`,
          {
            name: "audio.wav",
            data: `data:audio/wav;base64,${encodedAudio}`,
          },
          "Standard",
        ],
      };
      const generatedFile = await generateVideo(payload);
      setSampleVideo(generatedFile.getUrl());
      setIsSample(true);
      setProgress(100)
    } catch (error) {
      console.error(
        "Error uploading file:",
        error.response ? error.response.data : error
      );
      setError("Error uploading file.");
    } finally {
      setLoading(false);
    }
  };

  const generateVideo = async (payload) => {
    const { data: responseData } = await axios.post(url, payload, {
      headers,
    });

    const parsedAvatarKey = responseData.data[1].split("/").splice(3).join("/");
    const imageURL = s3B.getSignedUrl("getObject", {
      Bucket: S3_BUCKET_NAME,
      Key: parsedAvatarKey,
      Expires: 100000,
    });
    const newVideoFile = new VideoFile(imageURL);
    await newVideoFile.generateThumbnail();

    return newVideoFile;
  };

  const handleUpdateSampleData = (updatedData) => {
    setSampleData((oldData) => {
      return {
        ...oldData,
        ...updatedData,
      };
    });
  };

  const handleUpdateData = (updatedData) => {
    setData((oldData) => {
      return {
        ...oldData,
        ...updatedData,
      };
    });
  };

  const nextStep = () => {
    setProgress(5)
    if (currentStep < 3) {
      setCurrentStep(currentStep + 1);
    }
  };

  const prevStep = () => {
    setProgress(5)
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div
        className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="relative p-4 w-full max-w-xl h-full max-h-full">
          {/* Adjusted max-w for overall modal size */}

          <div className="relative bg-white rounded-md shadow-xl dark:bg-gray-700">
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                {currentStep === 1 && "Step 1 - AI Avatar Creator"}
                {currentStep === 2 &&
                  "Step 2 - Create AI Avatar Creator Sample"}
                {currentStep === 3 && "Step 3 - Create Final AI Avatar"}
              </h3>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                onClick={onClose}
                disabled={loading}
              >
                <span className="sr-only">Close modal</span>
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  fill="none"
                  viewBox="0 0 14 14"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    stroke="currentColor"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
              </button>
            </div>

            {/* Step 1: Introduction */}
            {currentStep === 1 && <Introduction />}

            {/* Step 2: File and Character Selection */}
            {currentStep === 2 && (
              <Selection
                data={sampleData}
                sampleVideo={sampleVideo}
                setData={handleUpdateSampleData}
              />
            )}

            {/* Step 3: Confirmation */}
            {currentStep === 3 && (
              <Confirmation data={data} setData={handleUpdateData} />
            )}

            {loading && (
              <>
                <div className="px-2">
                  <ProgressBar width={progress} />
                </div>
                <br></br>
              </>
            )}
            {error && <p className="text-red-500 pl-4 pb-2"> {error} </p>}
            <div className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
              {(currentStep === 2 || currentStep === 3) && (
                <button
                  type="button"
                  className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-800 mr-2 disabled:bg-opacity-50"
                  disabled={loading}
                  onClick={prevStep}
                >
                  Back
                </button>
              )}
              {currentStep === 2 && (
                <button
                  onClick={handleCreateSample}
                  type="submit"
                  disabled={loading}
                  className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-700 mr-2 disabled:bg-opacity-50"
                >
                  Create Sample
                </button>
              )}

              {(currentStep === 1 || currentStep === 2) && (
                <button
                  onClick={nextStep}
                  type="button"
                  disabled={loading || (currentStep === 2 && !isSample)}
                  className=" text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 disabled:bg-opacity-50"
                >
                  Next
                </button>
              )}

              {currentStep === 3 && (
                <button
                  type="submit"
                  disabled={loading}
                  className="px-4 py-2 bg-orange-500 text-white rounded hover:bg-orange-700 disabled:bg-opacity-50"
                >
                  Create Final Video
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AvatarForm;
