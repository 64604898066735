import AWS from 'aws-sdk';

AWS.config.update({
    accessKeyId:  process.env["REACT_APP_S3_ACCESS_KEY"],
    secretAccessKey:  process.env["REACT_APP_SECRET_KEY"],
});


const s3B = new AWS.S3();
export default s3B;

