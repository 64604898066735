// src/contexts/AlertContext.js

import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { createContext, useState, useContext } from "react";

const AlertContext = createContext();

export const AlertProvider = ({ children }) => {
  const [alert, setAlert] = useState({ type: "", message: "", isOpen: false });

  const showAlert = (type, message) => {
    setAlert({ type, message, isOpen: true });
    setTimeout(() => {
      setAlert({ ...alert, isOpen: false });
    }, 3000); // Hide after 3 seconds
  };

  const hideAlert = () => setAlert({ ...alert, isOpen: false });

  const getAlertClass = (alert) => {
    let alertClass = "";
    switch (alert.type) {
      case "success":
        alertClass = "text-green-800 rounded-lg bg-green-50 dark:bg-green-800 dark:text-green-400";
        break;
      case "error":
        alertClass = "text-red-800 rounded-lg bg-red-50 dark:bg-red-800 dark:text-red-400";
        break;
      case "loading":
        alertClass = "text-blue-800 rounded-lg bg-blue-50 dark:bg-blue-800 dark:text-blue-400";
        break;
      default:
        break;
    }
    return alertClass;
  };
  
  return (
    <AlertContext.Provider value={{ alert, showAlert, hideAlert }}>
      {alert?.isOpen && (
        <div
          className={`fixed top-3 left-1/2 transform -translate-x-1/2 flex items-center justify-between p-2 ${getAlertClass(alert)}`}
          role="alert"
        >
          <div className="text-sm font-medium">{alert.message}</div>
          <button onClick={hideAlert}>
            <FontAwesomeIcon icon={faXmark} className={`size-4 ${getAlertClass(alert)} ms-2`} />
          </button>
        </div>
      )}
      {children}
    </AlertContext.Provider>
  );
};

export const useAlert = () => useContext(AlertContext);
