import React from "react";
import Content from "../Content/Content";
import Topbar from "../Topbar/Topbar";

const DashboardPage = () => {

  return (
    <>
      <Topbar />
      <Content />
    </>
  );
};

export default DashboardPage;
