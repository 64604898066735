import { createSlice } from "@reduxjs/toolkit";

const slideList = createSlice({
  name: "slideList",
  initialState: {
    slideList: [],
    activeSlide: null,
    activeSlideIndex: 0,
    refreshAudioWaveform: false,
    newSlideAdded: false,
    slideDeleted: "initial",
    audioAdded: false,
    svgs: [],
    addedObjects: 0,
  },
  reducers: {
    setActiveSlideIndex: (state, action) => {
      state.activeSlideIndex = action.payload;
    },
    setActiveSlide: (state, action) => {
      state.activeSlide = action.payload;
    },
    setSlideList: (state, action) => {
      state.slideList = action.payload;
      window.slideList = [...state.slideList];
    },
    addSlide: (state, action) => {
      state.slideList.push(action.payload);
      window.slideList = [...state.slideList];
    },
    setAllMediaToSlide: (state, action) => {
      const newActiveSlide = { ...state.activeSlide };
      const { data, type } = action.payload;
      if (type) {
        newActiveSlide.pushNewItem(data, type);
      }
      state.addedObjects += 1;
      state.activeSlide = newActiveSlide;
    },
    updateAllMediaToSlide: (state, action) => {
      const newActiveSlide = { ...state.activeSlide };
      const { data, type } = action.payload;
      if (type) {
        newActiveSlide.updateItem(data, type);
      }
      state.addedObjects += 1;
      state.activeSlide = newActiveSlide;
    },
    setAllMediaToSpecificSlide: (state, action) => {
      const { file, slideId, type } = action.payload;
      const pushMedia = (slide, file, mediaType) => {
        if (mediaType) {
          slide.pushNewItem(file, mediaType);
        }
      };
      state.slideList.forEach((slide) => {
        if (slide.id === slideId) {
          pushMedia(slide, file, type);
        }
      });
      state.addedObjects += 1;
    },
    deleteAllMediaFromSpecificSlide: (state, action) => {
      const { id, slideId, type } = action.payload;
      const deleteMedia = (slide, id, mediaType) => {
        if (mediaType) {
          slide.deleteItem(id, mediaType);
        }
      };
      state.slideList.forEach((slide) => {
        if (slide.id === slideId) {
          deleteMedia(slide, id, type);
        }
      });
      state.addedObjects -= 1;
    },
    setSvgs: (state, action) => {
      state.svgs = action.payload;
      state.filteredSvgs = action.payload;
    },
    addSvgToStore: (state, action) => {
      console.log("Handling addSvgToStore:", action.payload);
      state.svgs.push(action.payload);
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    resetSvgList: (state, action) => {
      state.filteredSvgs = state.svgs;
    },
    setFilteredSvgs: (state, action) => {
      state.filteredSvgs = action.payload;
    },
    triggerRefreshAudioWaveform: (state) => {
      state.refreshAudioWaveform = !state.refreshAudioWaveform;
    },
    setSlideThumbnail: (state, action) => {
      if (state?.activeSlide?.id === action?.payload?.slideId) {
        state.activeSlide.setThumbnail(action.payload.newThumbnail);
      }
    },
    toggleNewSlideAdded: (state) => {
      state.newSlideAdded = !state.newSlideAdded;
    },
    deleteSlide: (state, action) => {
      const tempSlideList = [...state.slideList];
      state.slideList = tempSlideList.filter(
        (slide) => slide.id !== action.payload
      );
      window.slideList = [...state.slideList];
    },
    toggleSlideDeleted: (state, action) => {
      state.slideDeleted = action.payload;
    },
    setSlideName: (state, action) => {
      const { name, slideId } = action.payload;
      const tempSlideList = [...state.slideList];
      tempSlideList.forEach((slide) => {
        if (slide.id === slideId) {
          slide.name = name;
        }
      });
      state.slideList = tempSlideList;
      window.slideList = [...state.slideList];
    },
    setAudioAddedStatus: (state, action) => {
      state.audioAdded = action.payload;
    },
    setSlideTransition: (state, action) => {
      const { transition, slideId } = action.payload;
      const tempSlideList = [...state.slideList];
      tempSlideList.forEach((slide) => {
        if (slide.id === slideId) {
          slide.transition = transition;
        }
      });
      state.slideList = tempSlideList;
      window.slideList = [...state.slideList];
    },
  },
});

export const {
  setSlideList,
  addSlide,
  setActiveSlide,
  setActiveSlideIndex,
  triggerRefreshAudioWaveform,
  setSlideThumbnail,
  toggleNewSlideAdded,
  deleteSlide,
  toggleSlideDeleted,
  setSlideName,
  setAudioAddedStatus,
  setSvgs,
  addSvgToStore,
  setLoading,
  resetSvgList,
  setFilteredSvgs,
  setSlideTransition,
  setAllMediaToSlide,
  setAllMediaToSpecificSlide,
  deleteAllMediaFromSpecificSlide,
  updateAllMediaToSlide,
} = slideList.actions;
export default slideList.reducer;
