import {useDispatch} from "react-redux";
import React, {useRef, useState} from "react";
import {handleAddVideo, S3_BUCKET_NAME, uploadVideoToS3} from "../../../utils/helpers";
import '../../../utils/Objects/StaticVideo'
import {v4 as uuidv4} from "uuid";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import VideoGallery from "./VideoGallery";
import PexelsVideoGallery from "./PexelsVideoGallery";
import { useAuth } from "../../../context/AuthContext";

const VideoPanel = () => {
    const inputRef = useRef(null);
    const dispatch = useDispatch();
    const {user} = useAuth();
    const [uploadStatus, setUploadStatus] = useState(false);
    const [selectedTab, setSelectedTab] = useState('uploads');

    const handleTabClick = (tab) => {
        setSelectedTab(tab);
    };

    const handleUploadImageClick = () => {
        inputRef.current.click();
    }

    const handleVideoUpload = (event) => {
        setUploadStatus(true);
        const file = event.target.files[0];

        const fileName = `${user?.user_id}/video_list/user_upload_${uuidv4()}_${file.name}`;
        const params = {
            Bucket: S3_BUCKET_NAME,
            Key: fileName,
            Body: file,
            ContentType: file.type,
        };
        uploadVideoToS3(file, fileName, params, dispatch).then(() => setUploadStatus(false));
    }



    return (
        <div className="flex flex-col w-[280px] svg-box overflow-y-auto bg-gray-50 p-4">
            <div className="items-center justify-center">
                <input
                    className="hidden"
                    type="file"
                    ref={inputRef}
                    accept="video/mp4,video/webm,video/quicktime"
                    onChange={handleVideoUpload}
                />
                <label htmlFor="fontSelector" className="mb-2 block text-xs font-medium text-gray-600">Select Video or
                    Upload</label>
                <div className="mb-2 flex items-center">
                    <div className="flex w-full items-center justify-center">
                        <button
                            className="flex h-10 flex-grow items-center text-gray-600 justify-center rounded-md border bg-white text-xs transition-colors duration-200 hover:bg-gray-100 active:bg-gray-200"
                            onClick={handleUploadImageClick}
                        >
                            <FontAwesomeIcon icon={"cloud-arrow-up"} className="size-3 mr-2 text-gray-600"/>
                            Upload Video
                        </button>
                    </div>
                </div>
                <div
                    className="flex items-center justify-center p-2 bg-white rounded-md rounded-b-none border border-b-0">
                    <div
                        className={`flex w-full justify-center rounded-l-md p-2 border transition-all duration-200 active:bg-gray-200 border-r-0 ${selectedTab === 'uploads' ? 'bg-gray-100' : ''}`}
                        onClick={() => handleTabClick('uploads')}>
                        <label
                            className={`text-xs rounded-md text-gray-600 cursor-pointer`}>Uploads</label>
                    </div>
                    <div
                        className={`flex w-full justify-center rounded-r-md p-2 border transition-all duration-200 active:bg-gray-200 border-l-0 ${selectedTab === 'pexels' ? 'bg-gray-100' : ''}`}
                        onClick={() => handleTabClick('pexels')}>
                        <label
                            className={`text-xs rounded-md text-gray-600 cursor-pointer`}>Videos</label>
                    </div>
                </div>
                {
                    selectedTab === 'uploads' && (
                        <VideoGallery
                            uploadStatus={uploadStatus}
                            handleAddVideo={handleAddVideo}
                        />
                    )
                }
                {
                    selectedTab === 'pexels' && (
                        <PexelsVideoGallery
                            handleAddVideo={handleAddVideo}
                        />
                    )
                }
            </div>
        </div>
    )
}
export default VideoPanel;