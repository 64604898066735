import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { setSlideName, setSlideTransition } from '../../../store/reducers/slideListSlice';

const SlideSettings = () => {
    const dispatch = useDispatch();
    const {activeSlide} = useSelector(state => state.slideList);
    const [slideTitle, setSlideTitle] = useState(activeSlide.name || '');
    const [transitionType, setTransitionType] = useState(activeSlide.transition || '');

    const handleSaveSettings = () => {
        // Implement save logic here
        // For example, update state in a parent component or make an API call
        activeSlide.setSlideName(slideTitle);
        activeSlide.setSlideTransition(transitionType);
        dispatch(setSlideName({
            name: slideTitle,
            slideId: activeSlide.id,
        }));
        dispatch(setSlideTransition({
            transition: transitionType,
            slideId: activeSlide.id,
        }));
    };

    return (
        <div className="flex flex-col w-[280px] svg-box overflow-y-auto bg-gray-50 p-4">
              <label htmlFor="fontSelector" className="mb-2 block text-xs font-medium text-gray-600">Slides Settings </label>
            <div>
                <label htmlFor="slideTitle" className="block text-xs font-medium text-gray-700">Slide Title</label>
                <input
                    type="text"
                    id="slideTitle"
                    value={slideTitle}
                    onChange={(e) => setSlideTitle(e.target.value)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                    placeholder="Enter slide title"
                />
            </div>
            <div>
                <label htmlFor="transitionType" className="block text-xs font-medium text-gray-700">Transition Type</label>
                <select
                    id="transitionType"
                    value={transitionType}
                    onChange={(e) => setTransitionType(e.target.value)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                >
                    <option value="">Select a transition</option>
                    <option value="fade">Fade</option>
                    <option value="slide">Slide</option>
                    <option value="zoom">Zoom</option>
                    <option value="flip">Flip</option>
                </select>
            </div>
            <button
                onClick={handleSaveSettings}
                className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
            >
                Save Settings
            </button>
        </div>
    );
};

export default SlideSettings;
