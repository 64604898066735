import React from "react";
import { faBan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function AccessDenied() {
  return (
    <div className="bg-gray-100 h-screen flex items-center justify-center">
      <div className="text-center bg-white p-8 rounded-lg shadow-lg min-w-[400px]">
        <div className="text-6xl text-red-500 mb-4">
          <FontAwesomeIcon icon={faBan} className="w-10 h-10" />
        </div>
        <h1 className="text-lg font-bold mb-2">Access Denied</h1>
        <p className="text-gray-700 mb-4">
          You do not have access to this page.
        </p>
        <a
          href="/"
          className="inline-block bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700"
        >
          Go to Homepage
        </a>
      </div>
    </div>
  );
}

export default AccessDenied;
