import { v4 as uuidv4 } from "uuid";
import {captureFrame, loadVideoResource} from "./helpers";

export default class VideoFile {
    constructor(fileUrl) {
        this.id = uuidv4();
        this.url = fileUrl;
        this.thumbnail = '';
    }

    generateThumbnail = async () => {
        return new Promise(resolve => {
            loadVideoResource(this.url).then(video => {
                captureFrame(video).then(thumbnail => {
                    this.thumbnail = thumbnail;
                    resolve();
                })
            })
        });
    }

    getId = () => {
        return this.id;
    }

    getUrl = () => {
        return this.url;
    }

    getThumbnail = () => {
        return this.thumbnail;
    }

    getVideoJSON = () => {
        return {
            id: this.id,
            url: this.url,
            thumbnail: this.thumbnail,
        }
    }
}
