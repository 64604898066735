import {useDispatch, useSelector} from "react-redux";
import {changeJumpToSlidePopupStatus} from "../utils/helpers";
import Reveal from 'reveal.js';
import {DeleteIcon} from "../utils/iconHelper";

const JumpToSlide = () => {

    const {jumpToSlidePopupStatus, jumpToSlidePopupCoords, activeObject, canvas} = useSelector(state => state.fabricCanvas);
    const dispatch = useDispatch();

    const handleDeleteLink = () => {
        activeObject.set({
            jumpToSlide: 0,
        });
        canvas.renderAll();
        changeJumpToSlidePopupStatus(canvas, false, dispatch);
    }

    const handleJumpToSlide = () => {
        Reveal.slide(activeObject.jumpToSlide - 1);
    }

    return (
        <>
            {
                jumpToSlidePopupStatus && activeObject && activeObject.jumpToSlide !== 0 && (
                    <div
                        style={{top: activeObject?.link.length > 0 ? jumpToSlidePopupCoords.y + 50 : jumpToSlidePopupCoords.y, left: jumpToSlidePopupCoords.x}}
                        className="absolute w-auto max-w-[250px] overflow-hidden rounded-md bg-gray-50 shadow-lg z-50"
                    >
                        <div className="p-3">
                            <div className="flex items-center space-x-2">
                                <span
                                    className="truncate flex-grow text-xs underline font-semibold transition-colors duration-200 text-gray-400 hover:cursor-pointer hover:text-gray-600"
                                    onClick={handleJumpToSlide}
                                >
                                    Jump to Slide {activeObject.jumpToSlide}
                                </span>
                                <div className="border-r border-gray-300 h-4"></div>
                                <button className="text-gray-400 hover:text-red-500 focus:outline-none transition-colors duration-200" onClick={handleDeleteLink}>
                                    <DeleteIcon/>
                                </button>
                            </div>
                        </div>
                    </div>

                )
            }
        </>
    )
}
export default JumpToSlide;