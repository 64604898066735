import React, { useState, useEffect } from 'react';

const Note = ({ note, onDelete }) => {
    const colorClassMap = {
      yellow: 'bg-yellow-200',
      green: 'bg-green-200',
      blue: 'bg-blue-200',
    };
    const backgroundColorClass = colorClassMap[note.color] || 'bg-gray-200'; // Default to gray if color not found
  
    return (
      <div className={`${backgroundColorClass} p-2 m-2 rounded flex justify-between items-start`}>
        <p>{note.text}</p>
        <button onClick={() => onDelete(note.id)} className="bg-red-500 hover:bg-red-700 text-white font-bold">×</button>
      </div>
    );
  };

  const NoteEditor = ({ onAdd }) => {
    const [text, setText] = useState('');
    const [color, setColor] = useState('yellow'); // No '-200' here to match colorClassMap keys
  
    const handleSubmit = () => {
      if (!text) return;
      onAdd({ text, color, id: Date.now() });
      setText('');
    };
  
    return (
      <div className="w-4/5 relative">
        <textarea
          className="w-full p-2 mb-4 text-xs font-light"
          placeholder="Enter your note here and click on color to save..."
          rows={5}
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
        <div className="absolute top-0 right-5 mt-2 ml-2 flex">
          <button className="bg-yellow-200 h-4 w-4 rounded-full mr-2 focus:outline-none" onClick={() => { setColor('yellow'); handleSubmit(); }}></button>
          <button className="bg-green-200 h-4 w-4 rounded-full mr-2 focus:outline-none" onClick={() => { setColor('green'); handleSubmit(); }}></button>
          <button className="bg-blue-200 h-4 w-4 rounded-full focus:outline-none" onClick={() => { setColor('blue'); handleSubmit(); }}></button>
        </div>
      </div>
    );
  };

  const NotesApp = () => {
    const [notes, setNotes] = useState(() => {
      const savedNotes = localStorage.getItem('notes');
      return savedNotes ? JSON.parse(savedNotes) : [];
    });
  
    useEffect(() => {
      localStorage.setItem('notes', JSON.stringify(notes));
    }, [notes]);
  
    const addNote = (note) => setNotes([note, ...notes]);
    const deleteNote = (id) => setNotes(notes.filter(note => note.id !== id));
  
    const filteredNotes = notes.filter(note => note.text.toLowerCase().includes(''));
  
    return (
      <div className="app flex mx-auto max-w-6xl mt-2">
        <NoteEditor onAdd={addNote} />
        <div className="w-1/5 overflow-y-auto text-xxs rounded-lg">
          {filteredNotes.map(note => (
            <Note key={note.id} note={note} onDelete={deleteNote} />
          ))}
        </div>
      </div>
    );
  };

  export default NotesApp;
