const Introduction = () => {
  return (
    <div className="flex flex-col md:flex-row md:space-x-4 p-4 md:p-5">
      <div className="md:w-1/2 space-y-4">
        {/* Text container taking half space on medium screens */}
        <p className="pt-10 text-sm leading-relaxed text-gray-500 dark:text-gray-400">
          Talking Avatar will create a AI avatar based on your Audio File. You
          can first create Audio in the Audio tab.
          <br />
          <br />
          To create a talking avatar, in the next step select your audio and
          character type to create a 5s sample. After approving the sample, we
          will create an avatar for your full audio.
        </p>
      </div>
      <div className="md:w-1/2 flex justify-center items-center">
        {/* Video container */}
        <video
          className="rounded-md md:max-w-xs lg:max-w-xs p-5"
          autoplay="autoplay"
          controls
        >
          {/* Adjusted sizes for responsiveness */}
          <source src="/video_modal.webm" type="video/webm; codec=vp9" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};

export default Introduction;
