import {useDispatch, useSelector} from "react-redux";
import {changeLinkPopupStatus} from "../utils/helpers";
import {DeleteIcon} from "../utils/iconHelper";

const LinkViewPopup = () => {

    const {linkPopupStatus, linkPopupCoords, activeObject, canvas} = useSelector(state => state.fabricCanvas);
    const dispatch = useDispatch();

    const handleDeleteLink = () => {
        activeObject.set({
            link: '',
        });
        canvas.renderAll();
        changeLinkPopupStatus(canvas, false, dispatch);
    }

    return (
        <>
            {
                linkPopupStatus && activeObject &&  activeObject?.link && activeObject?.link?.length !== 0 && (
                    <div
                        style={{top: linkPopupCoords.y, left: linkPopupCoords.x}}
                        className="absolute w-auto max-w-[250px] overflow-hidden rounded-md bg-gray-50 shadow-lg z-50"
                    >
                        <div className="p-3">
                            <div className="flex items-center space-x-2">
                                <a
                                    href={activeObject.link}
                                    rel='noreferrer'
                                    target="_blank"
                                    className="truncate flex-grow text-xs underline transition-colors duration-200 font-semibold text-gray-400 hover:cursor-pointer hover:text-gray-600"
                                >
                                    {activeObject.link}
                                </a>
                                <div className="border-r border-gray-300 h-4"></div>
                                <button className="text-gray-400 hover:text-red-500 focus:outline-none transition-colors duration-200" onClick={handleDeleteLink}>
                                    <DeleteIcon/>
                                </button>
                            </div>
                        </div>
                    </div>

                )
            }
        </>
    )
}
export default LinkViewPopup;