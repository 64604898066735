import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CanvasActions } from "../../utils/canvasActions";
import { CopyIcon, CutIcon, DeleteIcon, DuplicateIcon, PasteIcon } from "../../utils/iconHelper";

const ContextMenu = () => {
    const { ctxCoords, activeObject, clipBoard, isContext } = useSelector(state => state.fabricCanvas);
    const { activeSlide } = useSelector(state => state.slideList);
    const dispatch = useDispatch();
    const actions = new CanvasActions({ activeSlide, dispatch, clipBoard });

    useEffect(() => {
        console.log(ctxCoords);
    }, [isContext, ctxCoords]);

    return (
        <div className="z-[70] absolute rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5" id="ctx-menu"
            style={{ top: ctxCoords.top, left: ctxCoords.left }}
        >
            <div className="w-[280px]">
                <div className="space-y-1 p-1 font-[400]" role="menu" aria-orientation="vertical"
                    aria-labelledby="options-menu">
                    {/*Copy*/}
                    <div
                        className={`flex cursor-pointer rounded-md px-4 py-2 text-xs transition-colors duration-200 text-gray-600 hover:bg-gray-100 active:bg-gray-200 ${!activeObject && 'opacity-50 pointer-events-none'}`}
                        role="menuitem" onClick={() => actions.handleActions('Copy')}>
                        <div className="flex basis-[5%] items-center">
                            <CopyIcon />
                        </div>
                        <div className="flex-grow basis-[60%] items-center justify-start">
                            <p className="ml-5">Copy</p>
                        </div>
                        <div className="flex basis-[30%] items-center justify-end">Ctrl/⌘ + C</div>
                    </div>
                    {/*Cut*/}
                    <div
                        className={`flex cursor-pointer rounded-md px-4 py-2 text-xs transition-colors duration-200 text-gray-600 hover:bg-gray-100 active:bg-gray-200 ${!activeObject && 'opacity-50 pointer-events-none'}`}
                        role="menuitem" onClick={() => actions.handleActions('Cut')}>
                        <div className="flex basis-[5%] items-center">
                            <CutIcon />
                        </div>
                        <div className="flex-grow basis-[60%] items-center justify-start">
                            <p className="ml-5">Cut</p>
                        </div>
                        <div className="flex basis-[30%] items-center justify-end">Ctrl/⌘ + X</div>
                    </div>
                    {/*Duplicate*/}
                    <div
                        className={`flex cursor-pointer rounded-md px-4 py-2 text-xs transition-colors duration-200 text-gray-600 hover:bg-gray-100 active:bg-gray-200 ${!activeObject && 'opacity-50 pointer-events-none'}`}
                        role="menuitem" onClick={() => actions.handleActions('Duplicate')}>
                        <div className="flex basis-[5%] items-center">
                            <DuplicateIcon />
                        </div>
                        <div className="flex-grow basis-[60%] items-center justify-start">
                            <p className="ml-5">Duplicate</p>
                        </div>
                        <div className="flex basis-[30%] items-center justify-end">Ctrl/⌘ + D</div>
                    </div>
                    {/*Paste*/}
                    <div
                        className={`flex cursor-pointer rounded-md px-4 py-2 text-xs transition-colors duration-200 text-gray-600 hover:bg-gray-100 active:bg-gray-200 ${!clipBoard && 'opacity-50 pointer-events-none'}`}
                        role="menuitem" onClick={() => actions.handleActions('Paste')}>
                        <div className="flex basis-[5%] items-center">
                            <PasteIcon />
                        </div>
                        <div className="flex-grow basis-[60%] items-center justify-start">
                            <p className="ml-5">Paste</p>
                        </div>
                        <div className="flex basis-[30%] items-center justify-end">Ctrl/⌘ + V</div>
                    </div>
                    <hr className="border-t border-gray-300 my-4" />
                    {/*Delete*/}
                    <div
                        className={`flex cursor-pointer rounded-md px-4 py-2 text-xs transition-colors duration-200 text-gray-600 hover:bg-red-100 hover:text-red-600 active:bg-red-200 ${!activeObject && 'opacity-50 pointer-events-none'}`}
                        role="menuitem" onClick={() => actions.handleActions('Delete')}>
                        <div className="flex basis-[5%] items-center">
                            <DeleteIcon />
                        </div>
                        <div className="flex-grow basis-[60%] items-center justify-start">
                            <p className="ml-5">Delete</p>
                        </div>
                        <div className="flex basis-[30%] items-center justify-end">Del</div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ContextMenu;
