import React from "react";
import DashboardPage from "../../modules/LayoutModule/Dashboard/DashboardPage";

function DashboardContainer() {
  return (
      <DashboardPage />
  );
}

export default DashboardContainer;
