import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useSelector } from "react-redux";
import Modal from "../../Common/Modal";
import AvatarForm from "../../Avatar/AvatarForm";
import { handleAddVideo } from "../../../utils/helpers";
import { useDispatch } from "react-redux";
import { shallowEqual } from "react-redux";

const AVATAR = { UPLOADED: "Uploads", EXISTING: "Avatars" };

const AvatarPanel = () => {
  const [isAvatarModal, setIsAvatarModal] = useState(false);
  const { canvas } = useSelector((state) => state.fabricCanvas);
  const { activeSlide } = useSelector((state) => state.slideList, shallowEqual);
  const [selectedTab, setSelectedTab] = useState(AVATAR.UPLOADED);

  const dispatch = useDispatch();

  const handleCloseModal = () => {
    setIsAvatarModal(false);
  };

  const handleOpenModal = () => {
    setIsAvatarModal(true);
  };

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  const { avatars, avatarLoading } = useSelector((state) => state.avatars);

  return (
    <>
      <div className="flex flex-col w-[280px] svg-box overflow-y-auto bg-gray-50 p-4">
        <label
          htmlFor="fontSelector"
          className="mb-2 block text-xs font-medium text-gray-600"
        >
          Avatar Panel
        </label>

        <div className="mb-2 flex items-center">
          <div className="flex w-full items-center justify-center">
            <button
              className="flex h-10 flex-grow items-center text-gray-600 justify-center rounded-md border bg-white text-xs transition-colors duration-200 hover:bg-gray-100 active:bg-gray-200"
              onClick={handleOpenModal}
            >
              <FontAwesomeIcon
                icon={"wand-magic-sparkles"}
                className="size-3 mr-2 text-gray-600"
              />
              Generate Avatar
            </button>
          </div>
        </div>

        <div className="flex items-center justify-center p-2 bg-white rounded-md rounded-b-none border border-b-0">
          <div
            className={`flex w-full justify-center rounded-l-md p-2 border transition-all duration-200 active:bg-gray-200 border-r-0 ${
              selectedTab === AVATAR.UPLOADED ? "bg-gray-100" : ""
            }`}
            onClick={() => handleTabClick(AVATAR.UPLOADED)}
          >
            <label
              className={`text-xs rounded-md text-gray-600 cursor-pointer`}
            >
              Uploads
            </label>
          </div>
          <div
            className={`flex w-full justify-center rounded-r-md p-2 border transition-all duration-200 active:bg-gray-200 border-l-0 ${
              selectedTab === AVATAR.EXISTING ? "bg-gray-100" : ""
            }`}
            onClick={() => handleTabClick(AVATAR.EXISTING)}
          >
            <label
              className={`text-xs rounded-md text-gray-600 cursor-pointer`}
            >
              Videos
            </label>
          </div>
        </div>

        <div className="svg-box flex mb-6 items-start justify-center overflow-x-hidden bg-white rounded-md border-t-0 rounded-t-none h-[165px] overflow-y-auto p-2 border">
        {selectedTab === AVATAR.UPLOADED && (
            <div className="grid grid-cols-3 gap-2">
              {avatarLoading && <img src="/loader.gif" alt="Avatar loader" />}
              {avatars.map((avatar, index) => {
                return (
                  <div
                    onClick={() => {
                      handleAddVideo(
                        avatar,
                        canvas,
                        null,
                        dispatch,
                        activeSlide?.id
                      );
                    }}
                    key={index}
                    className="group relative size-[45px] transition-all duration-200 flex-none cursor-pointer overflow-hidden rounded-md bg-white hover:opacity-85 hover:ring ring-gray-200 active:ring-gray-300"
                  >
                    <video
                      className="VideoInput_video"
                      style={{ display: "none" }}
                      src={avatar.url}
                    />
                    <img
                      src={avatar.thumbnail}
                      alt={index}
                      className="h-full w-full object-cover rounded-md"
                    />
                    {/* <button
                  className="absolute top-0 right-0 p-1 bg-black text-white rounded-full text-[6px] flex items-center justify-center"
                  onClick={(e) => {
                    e.stopPropagation();
                    // handleDeleteImage(video);
                  }}
                >
                  X
                </button> */}
                  </div>
                );
              })}
            </div>
        )}

        {selectedTab === AVATAR.EXISTING && <>
          <p className="text-xs rounded-md text-gray-600"> Nothing to show right now! </p>
        </>}

        </div>
      </div>

      <Modal isOpen={isAvatarModal} onClose={handleCloseModal}>
        <AvatarForm onClose={handleCloseModal} />
      </Modal>
    </>
  );
};

export default AvatarPanel;
