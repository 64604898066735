import { optionsToAdd } from "./helpers";

class ScormHelper {
  constructor(slideList) {
    this.slideList = slideList;
    this.slideMediaDataList = [];
    this.slideListJSONFile = null;
    this.constructMediaFilesForSlideList();
    this.constructSlideListJSONFile();
  }

  getStringifyJSON = () => {
    return this.slideMediaDataList;
  };

  getSlideListFile = () => {
    return this.slideListJSONFile;
  };

  constructSlideListJSONFile = () => {
    const jsonData = this.getStringifyJSON();
    const blob = new Blob([JSON.stringify(jsonData)], {
      type: "application/json",
    });
    this.slideListJSONFile = new File([blob], "slide_list_output.json", {
      type: "application/json",
    });
  };

  constructMediaFilesForSlideList = () => {
    this.slideList.forEach((slide) => {
      let { id, name, canvas, thumbnail } = slide.getSlideJSON();
      let media = this.constructMediaFileForSlide(slide);
      let canvasJSON = this.constructCanvasJSON(canvas);
      this.slideMediaDataList.push({
        id,
        name,
        media,
        canvasJSON,
        thumbnail,
      });
    });
  };

  constructCanvasJSON = (canvas) => {
    const canvasJSON = canvas.toJSON(optionsToAdd);

    canvasJSON.objects.forEach((object) => {
      if (object.type === "image") {
        const canvasObjectInstance = canvas
          .getObjects()
          .find((obj) => obj.id === object.id);
        object.base64src = canvasObjectInstance.toDataURL();
        object.type = "image"; // Ensure the type is set for the image
      }
    });

    return JSON.stringify(canvasJSON);
  };

  constructMediaFileForSlide = (slide) => {
    let media = [];
    const canvasObjectList = [...slide.canvas._objects].filter(
      (obj) => obj.id !== "boundingRect"
    );
    const audioList = [...slide.audio];

    canvasObjectList.forEach((object) => {
      if (object.hasOwnProperty("videoSrc")) {
        const videoJSON = object?.videoSrc;
        media.push({
          type: "video",
          src: videoJSON.url,
          top: object.top,
          left: object.left,
          id: object.id,
          height: object.height,
          width: object.width,
          scaleX: object.scaleX,
          scaleY: object.scaleY,
          thumbnail: videoJSON.thumbnail,
          start: object.start,
          end: object.end,
        });
      } else if (object.type === "audio" && object.src) {
        // Directly use SVG src for media inclusion
        media.push({
          type: "audio",
          src: object.src,
          name: object.name,
          id: object.id,
        });
      } else if (object.type === "svg" && object.src) {
        // Directly use SVG src for media inclusion
        media.push({
          type: "svg",
          src: object.src,
          top: object.top,
          left: object.left,
          id: object.id,
          height: object.height,
          width: object.width,
          scaleX: object.scaleX,
          scaleY: object.scaleY,
          link: object.link || "",
          jumpToSlide: object.jumpToSlide || 0,
          customAnimation: object?.customAnimation
        });
      } else {
        const objectJSON = JSON.parse(
          JSON.stringify(object.toJSON(optionsToAdd))
        );
        objectJSON.type = object.type; // Use the object's type directly
        media.push(objectJSON);
      }
    });

    // Process separate audioList and include audio files
    audioList.forEach((audioData) => {
      const audioJSON = audioData.getAudioJSON();
      media.push({
        type: "audio",
        src: audioJSON.url, // Assuming getAudioJSON() returns an object with a url property
        start: audioData.start,
        end: audioData.end,
        sequence: audioData.sequence,
        id: audioData.id,
        name: audioData.name,
      });
    });

    return media;
  };
}
export default ScormHelper;
