import React, {useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {S3_BUCKET_NAME, uploadAudioToS3, uploadRecordedAudioToS3} from "../../../utils/helpers";
import {v4 as uuidv4} from "uuid";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import AudioGallery from "./AudioGallery";
import RecordedAudioGallery from "./RecordedAudioGallery";
import AudioPlayer from "./AudioPlayer";
import AIAudioControl from "./AIAudioControl";
import { useAuth } from "../../../context/AuthContext";

const AudioPanel = () => {

    const inputRef = useRef(null);
    const dispatch = useDispatch();
    const { user } = useAuth();
    const [uploadStatus, setUploadStatus] = useState(false);
    const [selectedTab, setSelectedTab] = useState('uploads');
    const recorder = useAudioRecorder();
    const [showRecorder, setShowRecorder] = useState(false);
    const [selectedAudio, setSelectedAudio] = useState(null);

    const handleTabClick = (tab) => {
        setSelectedTab(tab);
    };

    const handleUploadImageClick = () => {
        inputRef.current.click();
    }

    const handleAudioUpload = (event, type='user') => {
        setUploadStatus(true);
        const file = event.target.files[0];
        const fileName = `${user?.user_id}/audio_list/user_upload_${uuidv4()}_${file.name}`;
        const params = {
            Bucket: S3_BUCKET_NAME,
            Key: fileName,
            Body: file,
            ContentType: file.type,
        };

        uploadAudioToS3(file, fileName, params, dispatch).then(() => setUploadStatus(false));
    }

    // eslint-disable-next-line
    const handleRecordedAudioUpload = (file) => {
        setUploadStatus(true);

        const params = {
            Bucket: S3_BUCKET_NAME,
            Key: file.name,
            Body: file,
            ContentType: file.type,
        };

        // Assuming uploadRecordedAudioToS3 is a function that uploads to S3 asynchronously
        uploadRecordedAudioToS3(file, file.name, params, dispatch).then(() => {
            setUploadStatus(false);
        }).catch(error => {
            console.error("Error uploading audio to S3:", error);
            setUploadStatus(false);
        });
    }

    const generateFileName = () => {
        return `${user?.user_id}/audio_list/user_upload_${uuidv4()}_recording_${Date.now()}.webm`
    }

    const handleShowRecorder = () => {
        setShowRecorder(!showRecorder);
        if(!showRecorder) {
            recorder.startRecording();
        } else {
            recorder.stopRecording();
        }
    }

    const handleRecordingComplete = async (blob) => {
        setShowRecorder(false);
        const url = URL.createObjectURL(blob);
        setSelectedAudio(url);

        const file = new File([blob], generateFileName(), { type: 'audio/webm' });

        // Pass the webm file to handleRecordedAudioUpload
        await handleRecordedAudioUpload(file);
    }

    const handleSetSelectedAudio = (audio) => {
        setSelectedAudio(audio.url);
    }

    return (
        <div className="flex flex-col w-[280px] svg-box overflow-y-auto bg-gray-50 p-4">
            <div className="items-center justify-center">
                <input
                    className="hidden"
                    type="file"
                    ref={inputRef}
                    accept="audio/*"
                    onChange={handleAudioUpload}
                />
                <label htmlFor="fontSelector" className="mb-2 block text-xs font-medium text-gray-600">Record or
                    Upload</label>
                <div className="mb-2 flex items-center">
                    <div className="flex w-full items-center justify-center space-x-2">
                        <button
                            className="flex h-10 flex-grow items-center text-gray-600 justify-center rounded-md border bg-white text-xs transition-colors duration-200 hover:bg-gray-100 active:bg-gray-200"
                            onClick={handleUploadImageClick}
                        >
                            <FontAwesomeIcon icon={"cloud-arrow-up"} className="size-3 ml-2 text-gray-600"/>
                            Upload Audio
                        </button>
                        <button
                            className={`flex h-10 flex-grow items-center text-gray-600 justify-center rounded-md border ${showRecorder ? 'bg-gray-100' : 'bg-white'} text-xs transition-colors duration-200 hover:bg-gray-100 active:bg-gray-200`}
                            onClick={handleShowRecorder}
                        >
                            <FontAwesomeIcon icon={"record-vinyl"} className="size-3 ml-2 text-gray-600"/>
                            Record Audio
                        </button>
                    </div>
                </div>
                <label htmlFor="fontSelector" className="mb-2 block text-xs font-medium text-gray-600">Select Audio</label>
                <div className="mb-2 flex items-center" style={{display: showRecorder ? '' : 'none'}}>
                    {
                        <AudioRecorder
                            onRecordingComplete={handleRecordingComplete}
                            recorderControls={recorder}
                        />
                    }
                </div>
                <div
                    className="flex items-center justify-center p-2 bg-white rounded-md rounded-b-none border border-b-0">
                    <div
                        className={`flex w-full justify-center rounded-l-md p-2 border transition-all duration-200 active:bg-gray-200 border-r-0 ${selectedTab === 'uploads' ? 'bg-gray-100' : ''}`}
                        onClick={() => handleTabClick('uploads')}>
                        <label
                            className={`text-xs rounded-md text-gray-600 cursor-pointer`}>Uploads</label>
                    </div>
                    <div
                        className={`flex w-full justify-center rounded-r-md p-2 border transition-all duration-200 active:bg-gray-200 border-l-0 ${selectedTab === 'recorded' ? 'bg-gray-100' : ''}`}
                        onClick={() => handleTabClick('recorded')}>
                        <label
                            className={`text-xs rounded-md text-gray-600 cursor-pointer`}>Recorded</label>
                    </div>
                </div>
                {
                    selectedTab === 'uploads' && (
                        <AudioGallery
                            uploadStatus={uploadStatus}
                            handleSetSelectedAudio={handleSetSelectedAudio}
                        />
                    )
                }
                {
                    selectedTab === 'recorded' && (
                        <RecordedAudioGallery
                            uploadStatus={uploadStatus}
                            handleSetSelectedAudio={handleSetSelectedAudio}
                        />
                    )
                }
                <AudioPlayer
                    selectedAudio={selectedAudio}
                />
                <AIAudioControl
                    handleAudioUpload={handleAudioUpload}
                />
            </div>
        </div>
    )
}
export default AudioPanel;