import { fabric } from 'fabric';

export class StaticButton extends fabric.Group {
  static type = 'button';

  initialize(options) {
    const defaultOpts = {
      width: 100,
      height: 50,
      fill: 'blue', // Default fill color
      hoverFill: 'red', // Color when hovered
      clickHandler: () => {}, // Default click handler
      color: 'white',
      iconScale: 0.05,
    };

    const {
      label,
      fill, // Default fill color
      hoverFill, // Color when hovered
      width,
      height,
      buttonType,
      color,
      fontWeight,
      hoverColor,
      borderColor,
      svgPath,
      iconScale,
    } = { ...defaultOpts, ...options };

    const buttonRect = new fabric.Rect({
      width: width,
      height: height,
      fill: fill,
      hoverFill: hoverFill,
      rx: buttonType === 'pill' ? 30 : 3,
      ry: buttonType === 'pill' ? 30 : 3,
      strokeWidth: buttonType === 'outlined' ? 1 : 0,
      stroke: buttonType === 'outlined' ? borderColor : 'transparent',
    });

    const icon = svgPath
      ? new fabric.Path(svgPath, {
          fill: color,
          scaleX: iconScale,
          scaleY: iconScale,
          left: 20,
          top: height / 2,
          originX: 'center',
          originY: 'center',
        })
      : null;

    const iconWidth = icon ? icon.width * icon.scaleX : 0;

    const buttonText = new fabric.StaticText({
      text: label || 'Button',
      fontSize: 18,
      fill: color,
      fontWeight,
      originX: svgPath ? 'left' : 'center',
      originY: 'center',
      top: buttonRect.height / 2,
      left: svgPath ? iconWidth + 10 : buttonRect.width / 2,
    });

    // // Calculate the width of the text element
    const textWidth = buttonText.width * buttonText.scaleX;

    if (svgPath) {
      buttonRect.set('width', textWidth + iconWidth + 20);
    } else {
      // Set the width of the button rectangle based on the text width
    const buttonWidth = textWidth + 20; // Add padding
    buttonRect.set('width', buttonWidth);
    buttonText.set('left', buttonWidth / 2);
    }

    // // Set the width of the button rectangle based on the text width
    // const buttonWidth = textWidth + 20; // Add padding
    // buttonRect.set('width', buttonWidth);
    // buttonText.set('left', buttonWidth / 2);

    super.initialize(
      icon ? [buttonRect, icon, buttonText] : [buttonRect, buttonText],
      options
    );

    this.setControlsVisibility({
      mt: false,
      mb: false,
      ml: false,
      mr: false,
      mtr: false,
    });
    // Disable scaling flip
    this.lockScalingFlip = true;
    // Add scaling event listener to lock aspect ratio
    this.on('scaling', function () {
      const w = this.width * this.scaleX;
      const h = this.height * this.scaleY;
      const aspectRatio = w / h;

      // Determine the aspect ratio based on initial scale
      if (aspectRatio > 1) {
        // Width is greater than height, lock horizontal scaling to vertical
        this.scaleX = this.scaleY;
      } else {
        // Height is greater than width, lock vertical scaling to horizontal
        this.scaleY = this.scaleX;
      }
    });

    this.on('mousedown', () => {
      this.item(0).set('fill', hoverFill || defaultOpts.hoverFill); // Change fill color on click
      if (hoverColor) {
        this.item(1).set('fill', hoverColor); // Change fill color on hover
        if(svgPath){
          this.item(2).set('fill', hoverColor);
        }
      }
      this.canvas.renderAll();
      this.clickHandler();
    });

    this.on('mouseup', () => {
      this.item(0).set('fill', fill || defaultOpts.fill); // Restore fill color on release
      this.item(1).set('fill', color);
      if(svgPath){
        this.item(2).set('fill', color);
      }
      this.canvas.renderAll();
    });

    this.on('mouseover', () => {
      this.item(0).set('fill', hoverFill || defaultOpts.hoverFill); // Change fill color on hover
      if (hoverColor) {
        this.item(1).set('fill', hoverColor); // Change fill color on hover
        if(svgPath){
          this.item(2).set('fill', hoverColor);
        }
      }
      this.canvas.renderAll();
    });

    this.on('mouseout', () => {
      this.item(0).set('fill', fill || defaultOpts.fill); // Restore fill color when not hovered
      this.item(1).set('fill', color);
      if(svgPath){
        this.item(2).set('fill', color);
      }
      this.canvas.renderAll();
    });

    return this;
  }

  clickHandler() {
    // Custom click handler logic
    console.log('Button clicked!');
  }

  toObject(propertiesToInclude = []) {
    return fabric.util.object.extend(
      super.toObject.call(this, propertiesToInclude),
      {}
    );
  }

  toJSON(propertiesToInclude = []) {
    return fabric.util.object.extend(
      super.toObject.call(this, propertiesToInclude),
      {}
    );
  }
}

fabric.StaticButton = fabric.util.createClass(StaticButton, {
  type: StaticButton.type,
});

if (!fabric.StaticButton) {
  fabric.StaticButton = StaticButton;
}
