import s3B from "./s3Config";
import {setLoading, setSvgs} from "../store/reducers/slideListSlice";
const bucketName = 'slidesdeck';
export const loadSvgs = async (dispatch)=>{
    dispatch(setLoading(true));
    const svgs = await fetchSvgs();
    await preloadImages(svgs.slice(0,50));
    svgs.forEach(svg => svg.name = getConvertedSVGName(svg.name))
    dispatch(setSvgs(svgs));
    dispatch(setLoading(false));
}

const getConvertedSVGName = (inputString) => {
    let convertedString = inputString.replace(/-|\.svg/g, ' ');
    convertedString = convertedString.replace(/\b\w/g, match => match.toUpperCase());
    return convertedString;
}

/**
 * Preloading few images on component loading
 * @param images
 * @returns {Promise<unknown[]>}
 */
export const preloadImages = async (images) => {
    const imageLoadPromises = images.map((image) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = image.url;
            img.onload = resolve;
            img.onerror = reject;
        });
    });

    return Promise.all(imageLoadPromises);
};
/**
 * After getting the list of keys, getting the signed urls for each key
 * @returns {Promise<any>}
 */
export const fetchSvgs = async () => {
    try {
        const objects = await listAllKeys({ Bucket: bucketName });
        let objList = await Promise.all(
            objects.map(async object => {
                if (!object.Key.endsWith('.svg')) return null; // Skip non-svg files

                const getObjectParams = {
                    Bucket: bucketName,
                    Key: object.Key,
                    Expires: 100000,
                };
                const signedUrl = await s3B.getSignedUrlPromise('getObject', getObjectParams);

                return {
                    url: signedUrl, // Preserved URL of the SVG
                    name: getObjectName(object.Key), // Converted name
                    type: 'svg', // Explicitly setting type as svg
                };
            }));

        // Filter out null values (non-svg files) and return the list
        return objList.filter(obj => obj !== null);
    } catch (error) {
        console.error('Error fetching objects:', error);
    }
};

const getObjectName = (key) => key.split('/').pop(); // Extracts the name from the key

/**
 * Fetching all the keys from s3 bucket
 * @param params
 * @param out
 * @returns {Promise<*|*[]|undefined>}
 */
const listAllKeys = async (params, out = []) => {
    try {
        const { Contents, IsTruncated, NextContinuationToken } = await s3B.listObjectsV2(params).promise();

        out.push(...Contents);

        if (IsTruncated) {
            return listAllKeys(Object.assign(params, { ContinuationToken: NextContinuationToken }), out);
        }

        return out;
    } catch (error) {
        throw error;
    }
};