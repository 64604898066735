import React from 'react';
import Layout from '../../modules/LayoutModule/';
import LoginForm from '../../components/LoginForm/LoginForm';
import SignupForm from '../../components/SignupForm/SignupForm';

function AuthContainer({ signup, login }) {
  return (
    <Layout>
      {login &&<LoginForm />}
      {signup && <SignupForm />}
    </Layout>
  );
}

export default AuthContainer;
