import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { setVideoPlay } from "../../store/reducers/canvasSlice";
import { PauseIcon, PlayIcon } from "../../utils/iconHelper";

const VideoControl = () => {
    const { videoCoords, canvas, isPlaying } = useSelector(state => state.fabricCanvas);
    const [buttonPosition, setButtonPosition] = useState({ left: 0, top: 0 });
    const dispatch = useDispatch();

    const handlePlay = () => {
        const activeObject = canvas.getActiveObject();
        if (isPlaying) {
            dispatch(setVideoPlay(false));
            if (activeObject && activeObject.name === 'video') {
                activeObject.pause();
            }
        } else {
            dispatch(setVideoPlay(true));
            if (activeObject && activeObject.name === 'video') {
                activeObject.play();
            }
        }
    };

    useEffect(() => {
        // Assuming 'canvas' here is your fabric.Canvas instance
        if (canvas && videoCoords) {
            console.log('videoCoords', videoCoords);
            
            const updateButtonPosition = () => {
                // Retrieve the DOM element for the Fabric.js canvas
                const buttonLeft = videoCoords.x - 5; // Adjust for the button's size
                const buttonTop = videoCoords.y - 5;
                setButtonPosition({ left: buttonLeft, top: buttonTop });
            };
    
            updateButtonPosition();
        }
    
        function handleEnded() {
            dispatch(setVideoPlay(false));
        }
    
        const activeObject = canvas?.getActiveObject();
        let videoEl = activeObject?.getElement();
    
        if (activeObject?.name === 'video' && videoEl) {
            videoEl.addEventListener('ended', handleEnded);
            return () => videoEl && videoEl.removeEventListener('ended', handleEnded); // Ensure cleanup
        }
    }, [videoCoords, canvas, dispatch, isPlaying]);

    return (
        <div style={{ position: "absolute", left: `${buttonPosition.left}px`, top: `${buttonPosition.top}px`, zIndex: 5 }}>
            <button className="bg-gray-500 text-white rounded-full w-8 h-8 flex items-center justify-center" onClick={handlePlay}>
                {!isPlaying ? <div className='translate-x-[1px]'><PlayIcon /></div> : <PauseIcon />}
            </button>
        </div>
    );
};

export default VideoControl;
