import React, { useState } from 'react';
import { LogoutIcon, NotificationIcon, SearchIcon, UserIcon } from '../../../utils/iconHelper';
import { useAuth } from '../../../context/AuthContext';
import { useNavigate } from 'react-router';

const Header = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const navigate = useNavigate();
  const { logout } = useAuth();

  const handleUserIconClick = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const handleLogout = () => {
    logout();
    navigate("/login");
  };
  return (
    <header className="bg-black text-white flex flex-col md:flex-row items-center justify-between p-4 border-b border-gray-700">
      <div className="flex-1 max-w-xs relative mb-4 md:mb-0">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <SearchIcon className="w-5 h-5" />
        </div>
        <input
          type="text"
          placeholder="Search"
          className="w-full bg-black text-white border border-gray-600 rounded-3xl py-2 pl-10 pr-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>
      <div className="flex items-center space-x-4 md:space-x-6">
        <div className="w-8 h-8 md:w-10 md:h-10 flex items-center justify-center border-2 border-gray-600 rounded-full">
          <NotificationIcon className="w-5 h-5 md:w-6 md:h-6 text-white" />
        </div>

        <div className="relative">
          <div className="cursor-pointer" onClick={handleUserIconClick}>
            <UserIcon className="w-8 h-8 md:w-9 md:h-9"/>
          </div>
          {isPopupOpen && (
            <div className="absolute right-0 mt-2 w-32 bg-white rounded-md shadow-lg py-1 hover:bg-gray-200">
              <button
                className="flex items-center px-4 py-2 w-full text-left text-black"
                onClick={handleLogout}
              >
                <LogoutIcon fontSize="small" style={{ marginRight: "8px" }} />
                Log out
              </button>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
