import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AddSheetIcon,
  DeleteSheetIcon,
  DuplicateSheetIcon,
} from "../../utils/iconHelper";
import Reveal from "reveal.js";
import {
  applyAnimationFromData,
  createNewSlide,
  deleteSlideById,
  duplicateSlideData,
  getFabricImageFromURL,
  getVideoElement,
  handleLoader,
} from "../../utils/helpers";
import {
  deleteSlide,
  toggleSlideDeleted,
  setSlideList,
  setActiveSlideIndex,
  setSlideName,
  triggerRefreshAudioWaveform,
  setAudioAddedStatus,
  setAllMediaToSlide,
} from "../../store/reducers/slideListSlice";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import AudioWaveform from "../AudioWaveform";
import NotesApp from "./NotesApp";
import TimelineComponent from "../Timeline/TimelineComponent";
import { shallowEqual } from "react-redux";
import { fabric } from "fabric";
import { shapes } from "../../utils/shapesHelper";
import {
  setLoaderMessage,
  setLoaderVisibility,
} from "../../store/reducers/loaderSlice";
import audioFile from "../../utils/audioFile";
import { useLocation } from "react-router";
import { useAlert } from "../../context/AlertContext";

const SlidesViewer = () => {
  const { slideList, activeSlideIndex, audioAdded, addedObjects } = useSelector(
    (state) => state.slideList,
    shallowEqual
  );
  const { canvas } = useSelector((state) => state.fabricCanvas, shallowEqual);
  const { show: showCropper } = useSelector((state) => state.cropper);
  const [activeTab, setActiveTab] = useState("slides"); // 'slides', 'timeline', 'notes'
  const dispatch = useDispatch();
  const location = useLocation();
  const projectId = useMemo(
    () => location.pathname.split("/").pop() || "",
    [location]
  );
  const { showAlert } = useAlert();

  const handleSlideClick = (index) => {
    Reveal.slide(index);
  };

  const setAnimation = (object, canvas) => {
    if (object?.customAnimation) {
      applyAnimationFromData(object, canvas, object?.customAnimation);
    }
  };

  const handleCardLoading = React.useCallback(
    (visibility) => {
      handleLoader(visibility, "load_card_on_canvas", dispatch);
    },
    [dispatch]
  );

  const handleAddImageToCanvas = React.useCallback(
    (obj, activeSlide, optionalData = {}) => {
      handleLoader(true, "load_image_on_canvas", dispatch);

      getFabricImageFromURL(
        obj.src,
        (fabricImage) => {
          fabricImage.set({
            id: obj.id,
            borderWidth: obj?.borderWidth || 0,
            borderStyle: obj?.borderStyle || "none",
            borderFill: obj?.borderFill || "rgb(0,0,0)",
            listStyle: obj?.listStyle || "none",
            link: obj?.link || "",
            jumpToSlide: obj?.jumpToSlide || 0,
            slideId: activeSlide?.id,
            top: obj.top,
            left: obj.left,
            height: obj.height,
            width: obj.width,
            scaleX: obj.scaleX,
            scaleY: obj.scaleY,
            customAnimation: obj?.customAnimation,
            ...optionalData,
          });
          fabricImage.scale(obj.scaleX, obj.scaleY);
          setAnimation(fabricImage, canvas);
          canvas.add(fabricImage);
          handleLoader(false, "", dispatch);
        },
        () => {
          handleLoader(false, "", dispatch);
        }
      );
    },
    [canvas, dispatch]
  );

  const handleLinkAudioToSlide = React.useCallback(
    async (audio) => {
      handleLoader(true, "link_audio_to_slide", dispatch);
      const newAudio = new audioFile("");
      newAudio.loadAudioJSON(audio);

      dispatch(
        setAllMediaToSlide({
          type: "audio",
          data: {
            ...newAudio,
            start: audio.start,
            end: audio.end,
            sequence: audio.sequence,
          },
        })
      );
      dispatch(triggerRefreshAudioWaveform());
    },
    [dispatch]
  );
  const handleLinkVideoToSlide = React.useCallback(
    (obj, activeSlide) => {
      dispatch?.(
        setAllMediaToSlide({
          type: "video",
          data: {
            url: obj.src,
            id: obj.id,
            thumbnail: obj.thumbnail,
            start: obj.start || 0,
            end: obj.end || 10,
          },
        })
      );
      let videoE = getVideoElement(obj.src);
      videoE.addEventListener("loadeddata", async () => {
        if (dispatch) dispatch(setLoaderVisibility(false));
        if (dispatch) dispatch(setLoaderMessage(""));
        let fabVideo = new fabric.StaticVideo(videoE, {
          left: obj.left || 0,
          top: obj.top || 0,
          videoSrc: { ...obj, url: obj.src },
          name: "video",
          type: "video",
          id: obj.id,
          slideId: activeSlide.id,
          width: obj.width,
          height: obj.height,
          scaleX: obj.scaleX,
          scaleY: obj.scaleY,
        });
        canvas.add(fabVideo);
        fabVideo?.play(canvas);
        fabVideo?.stop(canvas);
      });
    },
    [dispatch, canvas]
  );
  React.useEffect(() => {
    const activeSlide = slideList?.[activeSlideIndex];
    if (canvas?.getObjects()?.length < 2 && activeSlide?.objects?.length) {
      const canvasObjects = activeSlide?.objects.filter(
        (i) => i?.type !== "audio"
      );

      if (canvasObjects?.length) {
        canvasObjects?.forEach((obj) => {
          switch (obj?.type) {
            case "textbox":
              dispatch(
                setAllMediaToSlide({
                  type: "textbox",
                  data: {
                    id: obj.id,
                    type: "text",
                    text: obj.text, // Send updated text to Redux
                  },
                })
              );
              const newText = new fabric.StaticText({
                ...obj,
                selectable: true,
              });
              setAnimation(newText, canvas);
              canvas.add(newText);
              break;

            case "svg":
              const svgAttributes = {
                id: obj.id,
                type: "svg",
                src: obj?.src,
                slideId: activeSlide.id,
                ...obj,
              };
              dispatch(
                setAllMediaToSlide({
                  type: "shape",
                  data: { ...svgAttributes },
                })
              );
              fabric.loadSVGFromURL(obj?.src, (objects, options) => {
                const svgObj = fabric.util.groupSVGElements(objects, options);
                svgObj.scale(2);
                svgObj.set(svgAttributes);
                setAnimation(svgObj, canvas);
                canvas.add(svgObj);
              });
              break;
            case "polygon":
              const polygon = new fabric.Polygon(obj.points, { ...obj });
              setAnimation(polygon, canvas);
              canvas.add(polygon);
              break;
            case "circle":
              const circle = new fabric.Circle({ ...obj });
              setAnimation(circle, canvas);
              canvas.add(circle);
              break;
            case "line":
              const lineShape = shapes.find((i) => i.type === "line");
              const line = new fabric.Line(lineShape.path, { ...obj });
              setAnimation(line, canvas);
              canvas.add(line);
              break;
            case "image":
              dispatch(
                setAllMediaToSlide({
                  type: "image",
                  data: { url: obj.src, id: obj.id },
                })
              );
              handleAddImageToCanvas(obj, activeSlide);
              break;
            case "textInput":
              dispatch(
                setAllMediaToSlide({
                  type: "textInput",
                  data: { label: obj?.placeholder, id: obj.id },
                })
              );
              const inputField = new fabric.StaticInput({
                id: obj.id,
                width: obj?.width || 200,
                height: obj?.height || 40,
                fill: "white", // Default fill color
                borderColor: "black", // Default border color
                borderWidth: 1, // Default border width
                placeholder: obj?.placeholder, // Default placeholder text
                onChange: () => {}, // Default onChange handler
                svgPath: obj.svgPath || "",
                type: "textInput",
                slideId: activeSlide?.id,
                inputType: obj?.inputType || "text",
                top: obj.top,
                left: obj.left,
                customAnimation: obj?.customAnimation,
              });
              setAnimation(inputField, canvas);
              canvas.add(inputField); // Add input field to canvas
              break;
            case "button":
              dispatch(
                setAllMediaToSlide({
                  type: "button",
                  data: { label: obj?.label, id: obj.id },
                })
              );
              const button = new fabric.StaticButton({
                id: obj.id,
                label: obj?.label,
                fill: obj.fill, // Default fill color
                hoverFill: obj.hoverFill, // Color when hovered
                borderRadius: obj.borderRadius,
                fontWeight: obj.fontWeight,
                hoverColor: obj.hoverColor,
                borderColor: obj.borderColor,
                top: obj.top,
                left: obj.left,
                buttonType: obj.buttonType,
                svgPath: obj.svgPath || "",
                color: obj.color || "#fff",
                clickHandler: () => {
                  console.log("Button clicked!");
                },
                type: "button",
                slideId: activeSlide?.id,
                customAnimation: obj?.customAnimation,
              });
              setAnimation(button, canvas);
              canvas.add(button);
              break;
            case "card":
              dispatch(
                setAllMediaToSlide({
                  type: "card",
                  data: { label: obj.label, id: obj.id },
                })
              );
              handleCardLoading(true);
              const card = new fabric.StaticCard(
                {
                  id: obj.id,
                  label: obj.label,
                  labelLong: obj.labelLong,
                  fill: obj.fill, // Default fill color
                  fontWeight: obj.fontWeight,
                  cardType: obj.cardType,
                  type: "card",
                  top: obj.top,
                  left: obj.left,
                  cardImg: obj.cardImg,
                  slideId: activeSlide?.id,
                  handleCardLoading,
                  customAnimation: obj?.customAnimation,
                },
                canvas
              );setAnimation(card, canvas);
              canvas.add(card);
              break;
            case "quiz":
              dispatch(
                setAllMediaToSlide({
                  type: "quiz",
                  data: {
                    url: obj.src,
                    id: obj.id,
                    question: obj.formData.question || obj.formData.title,
                  },
                })
              );
              handleAddImageToCanvas(obj, activeSlide, {
                type: "quiz",
                formData: obj.formData,
                questionType: obj.questionType,
              });
              break;
            case "video":
              handleLinkVideoToSlide(obj, activeSlide);
              break;
            default:
              break;
          }
        });
      }
      const audioObjects = slideList?.[activeSlideIndex]?.objects.filter(
        (i) => i?.type === "audio"
      );
      if (audioObjects?.length) {
        dispatch(setAudioAddedStatus(true));
        audioObjects.forEach((audio) => {
          handleLinkAudioToSlide(audio);
        });
      }
    }
  }, [
    slideList,
    activeSlideIndex,
    canvas,
    dispatch,
    handleCardLoading,
    handleLinkAudioToSlide,
    handleAddImageToCanvas,
    handleLinkVideoToSlide,
  ]);

  const renderAnimationOnSlideChange = (slideId) => {
    const currentSlide = slideList.find((item) => item.id === slideId);
    const slideObjects = currentSlide.canvas
      .getObjects()
      ?.filter((object) => object.id !== "boundingRect");
    slideObjects.forEach((object) => {
      setAnimation(object, currentSlide.canvas);
    });
  };

  const handleDeleteSheet = (slide) => {
    const deletedSlideIndex = slideList.findIndex(
      (item) => item.id === slide.id
    );
    if (deletedSlideIndex === 0 && activeSlideIndex === deletedSlideIndex) {
      handleSlideClick(1);
      dispatch(setActiveSlideIndex(0));
    } else if (
      deletedSlideIndex === slideList.length - 1 &&
      activeSlideIndex === deletedSlideIndex
    ) {
      handleSlideClick(deletedSlideIndex - 1);
      dispatch(setActiveSlideIndex(deletedSlideIndex - 1));
    } else if (activeSlideIndex === deletedSlideIndex) {
      handleSlideClick(activeSlideIndex - 1);
      dispatch(setActiveSlideIndex(activeSlideIndex - 1));
    } else if (deletedSlideIndex < activeSlideIndex) {
      dispatch(setActiveSlideIndex(activeSlideIndex - 1));
    }
    dispatch(deleteSlide(slide.id));
    dispatch(toggleSlideDeleted(deletedSlideIndex));
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(slideList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    dispatch(setSlideList(items));

    // If the dragged slide is the active slide, update the activeSlideIndex
    if (result.source.index === activeSlideIndex) {
      const newIndex = result.destination.index;
      dispatch(setActiveSlideIndex(newIndex));
    }
  };

  const handleSlideNameChange = (event, slide) => {
    const newValue = event.target.value;
    dispatch(
      setSlideName({
        name: newValue,
        slideId: slide.id,
      })
    );
  };

  return (
    <>
      <div className="flex justify-center items-start p-2 bg-gray-100">
        {/* Tab buttons container */}
        <div className="flex flex-col space-y-2">
          <button
            className={`px-2 py-1 text-xs font-semibold ${
              activeTab === "slides"
                ? "text-blue-600 border-l-4 border-blue-600 bg-gray-200"
                : "text-gray-600"
            }`}
            onClick={() => setActiveTab("slides")}
          >
            Slides
          </button>
          <button
            className={`px-2 py-1 text-xs font-semibold ${
              activeTab === "timeline"
                ? "text-blue-600 border-l-4 border-blue-600 bg-gray-200"
                : "text-gray-600"
            }`}
            onClick={() => setActiveTab("timeline")}
          >
            Timeline
          </button>
          <button
            className={`px-2 py-1 text-xs font-semibold ${
              activeTab === "notes"
                ? "text-blue-600 border-l-4 border-blue-600 bg-gray-200"
                : "text-gray-600"
            }`}
            onClick={() => setActiveTab("notes")}
          >
            Notes
          </button>
        </div>
        {/* Content area for the active tab, you might want to adjust its placement depending on your layout */}
      </div>
      {activeTab === "slides" && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable
            droppableId="slides"
            direction="horizontal"
            className="flex w-full h-[160px]"
          >
            {(provided) => (
              <div
                className="flex items-end justify-start w-full h-[160px] space-x-2 svg-box overflow-x-auto overflow-y-hidden bg-gray-50 p-4 shadow-md"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {slideList.map((slide, index) => (
                  <Draggable
                    key={slide.id}
                    draggableId={slide.id}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="flex h-full items-end"
                      >
                        <div>
                          {audioAdded && (
                            <div className="flex svg-box flex-col space-y-1 max-h-[100px] overflow-y-auto overflow-x-hidden">
                              {slide &&
                                slide.audio.length > 0 &&
                                slide.audio.map((audioFile) => (
                                  <div className="relative">
                                    <AudioWaveform
                                      audio={audioFile}
                                      slide={slide}
                                    />
                                  </div>
                                ))}
                            </div>
                          )}
                          <div className="bg-transparent mb-1 w-[80px]">
                            <input
                              className="outline-gray-500 outline-1 text-xs font-semibold w-full text-gray-600 px-1 bg-transparent"
                              value={slide.name}
                              type="text"
                              onChange={(e) => handleSlideNameChange(e, slide)}
                            />
                          </div>
                          <div
                            className={`group ${
                              !showCropper && "relative"
                            } h-[60px] w-[80px] flex-none cursor-pointer overflow-hidden rounded-md border-2  bg-white hover:border-gray-500 ${
                              activeSlideIndex === index
                                ? "border-gray-600"
                                : "border-gray-400"
                            }`}
                            onClick={() => {
                              handleSlideClick(index);
                              renderAnimationOnSlideChange(slide.id);
                            }}
                          >
                            {slide.thumbnail.length > 0 && (
                              <img
                                src={slide.thumbnail}
                                alt={slide.id}
                                className="h-full w-full object-cover z-100"
                              />
                            )}
                            <div className="absolute bottom-2 right-2 flex space-x-2 opacity-0 transition-opacity duration-200 group-hover:opacity-100">
                              <button
                                onClick={() =>
                                  duplicateSlideData(
                                    slide,
                                    dispatch,
                                    slideList.length
                                  )
                                }
                              >
                                <DuplicateSheetIcon />
                              </button>
                              {slideList.length > 1 && (
                                <button
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    deleteSlideById(
                                      slide?.id,
                                      () => handleDeleteSheet(slide),
                                      showAlert
                                    );
                                  }}
                                >
                                  <DeleteSheetIcon />
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
                <div>
                  <div
                    className={`group ${
                      !showCropper && "relative"
                    } h-[60px] w-[80px] flex-none cursor-pointer items-center mt-6 justify-center rounded-md border-1 border-gray-400 bg-gray-200 transition-colors duration-200 hover:border-gray-500 hover:bg-gray-300`}
                    onClick={() =>
                      createNewSlide(
                        dispatch,
                        slideList?.length,
                        projectId,
                        showAlert,
                        false
                      )
                    }
                  >
                    <div className="flex h-full w-full items-center justify-center">
                      <AddSheetIcon />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
      {activeTab === "timeline" && (
        <div className="flex items-end justify-start w-full space-x-2 svg-box overflow-x-auto overflow-y-hidden bg-gray-50 px-4 pt-[30px] pb-0 shadow-md">
          <div className="flex svg-box flex-col space-y-1 max-h-[130px] overflow-y-auto w-full">
            <TimelineComponent addedObjects={addedObjects} />
          </div>
        </div>
      )}

      {activeTab === "notes" && (
        <div className="flex items-end h-[160px] justify-start w-full space-x-2 svg-box overflow-x-auto overflow-y-hidden bg-gray-50 shadow-md">
          <div className="h-[160px] w-full">
            <NotesApp />
          </div>
        </div>
      )}
    </>
  );
};
export default SlidesViewer;
