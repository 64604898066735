export const getScormXMLString = (title = 'Reveal') => {

    return (`
        <manifest xmlns="http://www.imsproject.org/xsd/imscp_rootv1p1p2" xmlns:imsmd="http://www.imsglobal.org/xsd/imsmd_rootv1p2p1" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:adlcp="http://www.adlnet.org/xsd/adlcp_rootv1p2" identifier="MANIFEST-A21219BD-60C8-4433-8CE5-5D40844DE433" xsi:schemaLocation="http://www.imsproject.org/xsd/imscp_rootv1p1p2 imscp_rootv1p1p2.xsd http://www.imsglobal.org/xsd/imsmd_rootv1p2p1 imsmd_rootv1p2p1.xsd http://www.adlnet.org/xsd/adlcp_rootv1p2 adlcp_rootv1p2.xsd">
        <metadata>
        <imsmd:lom>
        <imsmd:general>
        <imsmd:identifier/>
        <imsmd:title>
        <imsmd:langstring xml:lang="en">${title}</imsmd:langstring>
        </imsmd:title>
        <imsmd:description>
        <imsmd:langstring xml:lang="en">${title}</imsmd:langstring>
        </imsmd:description>
        <imsmd:metametadata>
        <color>#FFFFFF</color>
        </imsmd:metametadata>
        </imsmd:general>
        </imsmd:lom>
        </metadata>
        <organizations default="ORG-0F47BE8F-568D-4C62-AD8A-CDCC422BEFE5">
        <organization identifier="ORG-0F47BE8F-568D-4C62-AD8A-CDCC422BEFE5" structure="hierarchical">
        <title>${title}</title>
        <item identifier="ITEM-DDF8FF17-5CE5-4412-BF43-1077400DB525" identifierref="RES-F2504A73-BC7E-4106-A006-DE19DFA32749" isvisible="true">
        <title>${title}</title>
        <adlcp:masteryscore>75</adlcp:masteryscore>
        </item>
        </organization>
        </organizations>
        <resources>
        <resource identifier="RES-F2504A73-BC7E-4106-A006-DE19DFA32749" type="webcontent" adlcp:scormtype="sco" href="index.html">
        <file href="index.html"/>
        </resource>
        </resources>
        </manifest>
 `)
}