import React, {useEffect, useRef, useState} from 'react';
import {useDispatch} from "react-redux";
import {setFilteredSvgs} from "../../store/reducers/slideListSlice";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const SearchBar = ({ svgs }) => {
    const dispatch = useDispatch();
    // State for managing the search input and filtered results
    const [searchInput, setSearchInput] = useState('');
    const [filteredResults, setFilteredResults] = useState([]);
    const searchBarRef = useRef(null);
    const [isSearchBarOpen, setIsSearchBarOpen] = useState(false);

    // Function to handle changes in the search input
    const handleSearchChange = (event) => {
        const input = event.target.value;
        setIsSearchBarOpen(true);
        setSearchInput(input);
        // Filter the icons based on the search input
        const filteredResults = svgs.filter((icon) =>
            icon.name.toLowerCase().includes(input.toLowerCase())
        );
        setFilteredResults(filteredResults);
        dispatch(setFilteredSvgs(filteredResults));
    };

    const clearSearch = () => {
        setSearchInput('');
        setFilteredResults([]);
        // Filter the icons based on the search input
        const filteredResults = svgs.filter((icon) =>
            icon.name.toLowerCase().includes('')
        );
        dispatch(setFilteredSvgs(filteredResults));
    }

    const getConvertedSVGName = (inputString) => {
        let convertedString = inputString.replace(/-|\.svg/g, ' ');
        convertedString = convertedString.replace(/\b\w/g, match => match.toUpperCase());
        return convertedString;
    }

    const handleClickOutside = (event) => {
        if (searchBarRef.current && !searchBarRef.current.contains(event.target)) {
            setIsSearchBarOpen(false);
        }
    };

    useEffect(() => {
        // Add event listener on mount
        document.addEventListener('mousedown', handleClickOutside);

        // Remove event listener on component unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []); // Empty dependency array means this effect runs only once, on mount

    return (
        <div className="flex items-center justify-center border rounded-md p-2 relative" ref={searchBarRef}>
            <input
                type="text"
                className="w-full text-gray-600 focus:outline-none text-xs"
                placeholder="Search for icons"
                value={searchInput}
                onChange={handleSearchChange}
            />
            <button className="focus:outline-none">
                {
                    searchInput.length === 0 && <FontAwesomeIcon className="size-3 text-gray-600" icon={"search"}/>
                }
                {
                    searchInput.length > 0 &&
                    <FontAwesomeIcon className="size-3 text-gray-600" icon={"close"} onClick={clearSearch}/>
                }
            </button>
            {isSearchBarOpen && searchInput.length > 0 && filteredResults.length > 0 && (
                <div className="absolute z-50 flex flex-col w-full rounded-md top-10 p-2 bg-white text-xs text-gray-600 border">
                    {/* Display up to 3 suggestions */}
                    {filteredResults.slice(0, 3).map((suggestion, index) => (
                        <div
                            key={index}
                            className="p-2 items-center cursor-pointer transition-colors duration-200 hover:bg-gray-50 rounded-md"
                            onClick={() => {
                                handleSearchChange({target: {value: getConvertedSVGName(suggestion.name)}})
                                setFilteredResults([]);
                            }}
                        >
                            {getConvertedSVGName(suggestion.name)}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default SearchBar;
