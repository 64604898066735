import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  DownloadIcon,
  LogoutIcon,
  PreviewIcon,
  UserIcon,
} from "../../utils/iconHelper";
import {
  createSCORMFiles,
  createPreview,
  updateSlide,
} from "../../utils/helpers";
import { useAuth } from "../../context/AuthContext";
import ScormHelper from "../../utils/scormHelper";
import { useAlert } from "../../context/AlertContext";

const Header = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(false);
  const { slideList, activeSlide } = useSelector((state) => state.slideList);
  const navigate = useNavigate();
  const { logout } = useAuth();
  const { showAlert } = useAlert();

  const handleUserIconClick = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  const handleJsonUpload = () => {
    const scormHelper = new ScormHelper(slideList);
    const jsonData = scormHelper.getStringifyJSON();
    const currentSlideJson = jsonData.find((i) => i.id === activeSlide.id);
    if (!currentSlideJson) {
      console.error("No JSON data provided.");
      return;
    }
    
    setUploadStatus(true);
    const body = {
      data: {
        objects: JSON.stringify(currentSlideJson.media),
        name: currentSlideJson.name,
        thumbnail: currentSlideJson.thumbnail || '',
      },
    };  
    
    updateSlide(currentSlideJson?.id, body, () => {
      setUploadStatus(false);
    }, showAlert);
  };

  return (
    <header className="flex items-center justify-between bg-white px-5 py-3 shadow-md">
      <div className="flex items-center text-xs">
        <nav className="space-x-1">
          <span className="text-gray-700 transition-colors duration-200 hover:text-gray-500">
            Dashboard
          </span>
          <span>/</span>
          <span className="font-bold text-gray-700 transition-colors duration-200 hover:text-gray-500">
            My Learning Project
          </span>
        </nav>
      </div>
      <div className="flex items-center space-x-3">
        <button
          className="flex items-center rounded-md bg-pink-400 px-[15px] py-[6px] text-xs text-white transition-colors duration-200 hover:bg-pink-500 active:bg-pink-600"
          onClick={() => handleJsonUpload()}
          disabled={uploadStatus}
        >
          {uploadStatus ? "Uploading..." : "Save"}
        </button>
        <button
          className="flex items-center rounded-md bg-purple-500 px-[15px] py-[6px] text-xs text-white transition-colors duration-200 hover:bg-purple-600 active:bg-purple-700"
          onClick={() => createSCORMFiles(slideList)}
        >
          <DownloadIcon />
          Download
        </button>
        <button
          className="flex items-center rounded-md bg-pink-400 px-[15px] py-[6px] text-xs text-white transition-colors duration-200 hover:bg-pink-500 active:bg-pink-600"
          onClick={() => createPreview(slideList)}
        >
          <PreviewIcon />
          Preview
        </button>
        <div className="relative">
          <div className="cursor-pointer" onClick={handleUserIconClick}>
            <UserIcon />
          </div>
          {isPopupOpen && (
            <div className="absolute right-0 mt-2 w-28 bg-white rounded-md shadow-lg py-1 hover:bg-gray-200">
              <button
                className="flex items-center px-4 py-2 text-gray-s00 w-full text-left"
                onClick={handleLogout}
              >
                <LogoutIcon fontSize="small" style={{ marginRight: "8px" }} />
                Log out
              </button>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
