import React, { useState, useEffect, useRef } from 'react';
import {useSelector} from "react-redux";
import {ShapesHelper} from "../../../utils/shapesHelper";
import { useDispatch } from 'react-redux';

const SvgPagination = ({ svgs:svgUrls }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const {activeSlide} = useSelector(state => state.slideList);
    // ShapesHelper class initialized
    const dispatch = useDispatch();
    const shapesHelper = new ShapesHelper({activeSlide}, dispatch)
    const itemsPerPage = 100;
    const containerRef = useRef(null);
    
   


    useEffect(() => {
        const handleScroll = () => {
            const container = containerRef.current;
            if (container) {
                const { scrollTop, clientHeight, scrollHeight } = container;

                // Calculate the total number of images loaded
                const totalLoadedImages = currentPage * itemsPerPage;

                // Check if the user has scrolled to the bottom
                if (scrollTop + clientHeight >= scrollHeight - 10 && totalLoadedImages < svgUrls.length) {
                    // Increase the page number and load more images
                    setCurrentPage((prevPage) => prevPage + 1);
                }
            }
        };
        // Add scroll event listener
        const container = containerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
        }

        // Remove the event listener when the component is unmounted
        return () => {
            if (container) {
                container.removeEventListener('scroll', handleScroll);
            }
        };
    }, [currentPage, svgUrls]);

    const getAllLoadedItems = () => {
        const loadedItems = [];
        for (let page = 1; page <= currentPage; page++) {
            const startIndex = (page - 1) * itemsPerPage;
            const endIndex = startIndex + itemsPerPage;
            loadedItems.push(...svgUrls?.slice(startIndex, endIndex));
        }
        return loadedItems;
    };
    const addSvg = (url)=>{
        console.log('SVG URL:', url);
        shapesHelper.add('svg',[],url, dispatch)
        
    }

    return (
        <div
            ref={containerRef}
            className="svg-box max-h-[210px] overflow-y-auto overflow-x-hidden mt-2 scrollbar-thin scrollbar-thumb-grey-500 scrollbar-track-transparent scrollbar-thumb-hover:visible"
        >
            <div
                id="svg-container"
                className="flex items-center justify-center"
            >
                <div className="grid grid-cols-6 gap-1">
                    {getAllLoadedItems().map((item, index) => (
                        <button
                            key={index}
                            onClick={() => addSvg(item.url)}
                            className="size-8 p-2" // Adjusted class for 6 columns in each row
                        >
                            <img
                                src={item.url}
                                alt="SVG"
                                className="w-full h-full object-cover"
                            />
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default SvgPagination;
