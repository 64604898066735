import React from "react";
import ListOfProject from "./ListOfProject";

function DashboardContainer() {
  return (
      <ListOfProject />
  );
}

export default DashboardContainer;
