import React, { useEffect, useState } from "react";
import {
  AIEnableIcon,
  AdvancedIcon,
  CloudIcon,
  CollaborationIcon,
  CommerceIcon,
  ConversionIcon,
  FacebookIcon,
  FeaturesIcon,
  InstagramIcon,
  InteractiveIcon,
  QuizIcon,
  ResponsiveIcon,
  ReviewIcon,
  ScromIcon,
  SourceIcon,
  SupportIcon,
  TickMarkIcon,
  TwitterIcon,
  VideoIcon,
  YoutubeIcon,
} from "../../../utils/iconHelper";
import { useNavigate } from "react-router";
import { useAuth, useCheckSubscription } from "../../../context/AuthContext";

const Content = () => {
  const navigate = useNavigate();
  const { isAuthenticated, user } = useAuth();
  const checkSubscription = useCheckSubscription();
  const [isPlanActive, setIsPlanActive] = useState(false);

  useEffect(() => {
    const checkUserSubscription = async () => {
      if (isAuthenticated) {
        const isActive = await checkSubscription(user);
        setIsPlanActive(isActive);
        if(isActive){

          navigate('/projects')
        }
      }
    };
    checkUserSubscription();
  }, [isAuthenticated, user, checkSubscription, navigate]);

  const handleNavigation = async () => {
    if (isAuthenticated) {
      const isActive = await checkSubscription(user);
      if (isActive) {
        navigate("/projects");
      } else {
        navigate("/subscription-details");
      }
    } else {
      navigate("/login", { state: { redirectTo: "/subscription-details" } });
    }
  };
  
  return (
    <>
      <div>
        <div className="p-10 pb-28">
          <div className="max-w-7xl mx-auto grid grid-cols-2 gap-10">
            <div className="col-span-2 sm:col-span-1">
              <h1 className="text-6xl mt-8 mb-2 font-semibold text-gray-800">
                AI powered e-learning platform for your business
              </h1>
              <p className="mb-4 max-w-[600px] text-gray-500">
              Empowering Corporate Learning Everywhere with AI and 
              Innovation: A Complete SCORM-Compatible, Browser-Based, 
              Mobile-Friendly eLearning Solution for Every Company.
              <br></br>
              <br></br>
              Full scope of e-learning from content creation to LMS hosting, all you need in one place.
              </p>
              
              <div className="flex items-center mb-4 text-gray-700">
              <a href="/signup">
                <button class="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
                  Sign Up
                </button>
              </a>
               
              </div>
              {/* <div className="flex items-center mt-6 text-gray-600">
                <p className="text-xl font-semibold mr-2">★★★★★</p>
                <p className="text-xl">10,000+ users</p>
              </div> */}
            </div>
            <div className="col-span-2 sm:col-span-1 mt-16">
              <div className="rounded-lg overflow-hidden transform scale-100 p-2 shadow-lg ">
                <div className="flex flex-wrap justify-start ml-6 my-2">
                  <div className="rounded-full bg-green-500 h-3 w-3"></div>
                  <div className="rounded-full bg-yellow-500 h-3 w-3 mx-2"></div>
                  <div className="rounded-full bg-red-500 h-3 w-3"></div>
                </div>
                <div className="relative">
                  <div className="absolute inset-0 rounded-lg blur-md h-[400px]"></div>
                  <div className="relative rounded-lg p-4">
                    <video
                      src="https://spacetry.s3.amazonaws.com/Product+Documentation+Video+(2).mp4"
                      className="w-full h-auto"
                      controls></video>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="about" className="px-6 md:px-0 max-w-[500px] mx-auto">
          <p className="text-center font-bold text-xl text-blue-600 ">
            Everything you need
          </p>
          <h2 className="text-[40px] mt-8 mb-2 font-semibold text-gray-700 text-center">
            The most powerful web-based e-learning creation tools for your business
          </h2>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mx-auto md:max-w-screen-md lg:max-w-screen-lg my-16">
          <div className="bg-white rounded-xl shadow-md p-4">
            <div className="flex justify-center items-center bg-gradient-to-br from-primary-100 to-primary-200 rounded-full p-4 h-16"> {/* Increased padding and size */}
              <ScromIcon className="w-12 h-12" /> {/* Increased icon size */}
            </div>
            <h3 className="text-center text-xl font-semibold mt-4 text-gray-700">
              SCORM
            </h3>
            <p className="text-center mt-2 text-gray-500">
              Full compatibility with modern LMS Platform for SCORM
              compatibility.
            </p>
          </div>

          <div className="bg-white rounded-xl shadow-md p-4">
            <div className="flex justify-center items-center bg-gradient-to-br from-primary-100 to-primary-200 rounded-full p-2 h-12">
              <AIEnableIcon />
            </div>
            <h3 className="text-center text-xl font-semibold mt-4 text-gray-700">
              AI enabled
            </h3>
            <p className="text-center mt-2 text-gray-500">
              Generate text, images and videos with AI integrations.
            </p>
          </div>

          <div className="bg-white rounded-xl shadow-md p-4">
            <div className="flex justify-center items-center bg-gradient-to-br from-primary-100 to-primary-200 rounded-full p-2 h-12">
              <CollaborationIcon />
            </div>
            <h3 className="text-center text-xl font-semibold mt-4 text-gray-700">
              Collaboration
            </h3>
            <p className="text-center mt-2 text-gray-500">
              Collaborate with your team and share with your clients and
              coworkers.
            </p>
          </div>
        </div>
        <div className="p-12 md:px-0">
  <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-16 p-4">
    <div>
      <h2 className="text-3xl md:text-[40px] font-bold text-gray-700">
        Helping you focus on what really matters
      </h2>
      <p className="mt-4 text-gray-500">
        Forget about hard-to-use desktop software that takes ages to learn and deliver your e-learning in hours. Work smart, not hard!
      </p>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 text-gray-500">
        <div>
          <ul className="mt-4 space-y-2">
            <li className="flex items-center">
              <TickMarkIcon />
              <span className="ml-2">Easy to use</span>
            </li>
            <li className="flex items-center">
              <TickMarkIcon />
              <span className="ml-2">Multiple templates</span>
            </li>
          </ul>
        </div>
        <div>
          <ul className="mt-4 space-y-2">
            <li className="flex items-center">
              <TickMarkIcon />
              <span className="ml-2">AI features</span>
            </li>
            <li className="flex items-center">
              <TickMarkIcon />
              <span className="ml-2">Secure platform</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
        <div className="relative mt-8 md:mt-0">
          <div className="skeleton-container">
            <div className="p-1">
              <div className="bg-white rounded-xl shadow-md p-4 w-full sm:w-[250px] grid grid-cols-2 gap-4 z-1 relative ml-auto mb-[-100px]">
                <div>
                  <h3 className="text-start text-xl font-semibold mt-4 text-gray-700 w-[200px]">
                    Smart features
                  </h3>
                  <p className="text-start mt-2 text-gray-500 w-[200px]">
                    All the tools you need
                  </p>
                </div>
                <div className="flex justify-end items-center bg-gradient-to-br from-primary-100 to-primary-200 rounded-full p-2">
                  <FeaturesIcon />
                </div>
              </div>
              <div className="relative">
                <img
                  src="screenshot.png"
                  alt="Descriptive Alt Text"
                  className="w-full h-auto object-cover rounded-xl shadow-md"
                />
              </div>
              <div className="*:max-sm:hidden bg-white rounded-xl shadow-md p-4 w-full sm:w-[250px] grid grid-cols-2 gap-4 ml-[-100px] mt-[-50px] z-1 relative">
                <div>
                  <h3 className="text-start text-xl font-semibold mt-4 text-gray-700 w-[200px]">
                    Easy to use
                  </h3>
                  <p className="text-start mt-2 text-gray-500 w-[200px]">
                    Made for everyone
                  </p>
                </div>
                <div className="flex justify-center items-center bg-gradient-to-br from-primary-100 to-primary-200 rounded-full p-2">
                  <TickMarkIcon />
                </div>
              </div>
            </div>
          </div>
          <div className="absolute bottom-0 left-0 p-8">
            <div className="bg-gradient-to-tr from-primary-100 to-transparent rounded-full p-2"></div>
          </div>
        </div>
      </div>
    </div>
    <div className="p-8 md:px-0">
  <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-16 p-4">
    <div className="relative">
      <div className="flex flex-col md:flex-row items-center bg-white relative">
        <div className="absolute inset-0 bg-gradient-blur filter blur-60 transform scale-100"></div>
        <div className="flex flex-col w-full md:w-1/2 pt-20 gap-8 p-4">
          <div className="py-4 shadow-lg rounded-lg">
            <div className="flex">
              <div className="bg-gradient-to-br from-primary-light to-white rounded-full ml-8">
                <span className="bg-gradient-to-br from-primary-light to-white rounded-full p-1">
                  <CloudIcon />
                </span>
              </div>
            </div>
            <p className="counter text-3xl md:text-[40px] font-bold text-gray-700 ml-4">
              100+
            </p>
            <p className="text-gray-500 ml-4">Templates</p>
          </div>
          <div className="py-2 shadow-lg rounded-lg">
            <div className="flex">
              <div className="bg-gradient-to-br from-primary-light to-white rounded-full ml-8">
                <div className="flex space-x-2">
                  <span className="bg-gradient-to-br from-primary-light to-white rounded-full p-1">
                    <ReviewIcon />
                  </span>
                  <span className="bg-gradient-to-br from-primary-light to-white rounded-full p-1">
                    <ReviewIcon />
                  </span>
                  <span className="bg-gradient-to-br from-primary-light to-white rounded-full p-1">
                    <ReviewIcon />
                  </span>
                  <span className="bg-gradient-to-br from-primary-light to-white rounded-full p-1">
                    <ReviewIcon />
                  </span>
                  <span className="bg-gradient-to-br from-primary-light to-white rounded-full p-1">
                    <ReviewIcon />
                  </span>
                </div>
              </div>
            </div>
            <p className="counter text-3xl md:text-[40px] font-bold text-gray-700 ml-4">
              5 Star
            </p>
            <p className="text-gray-500 ml-4">User reviews</p>
          </div>
        </div>
        <div className="flex flex-col w-full md:w-1/2 gap-8 p-4">
          <div className="py-4 shadow-lg rounded-lg">
            <div className="flex">
              <div className="bg-gradient-to-br from-primary-light to-white rounded-full ml-8">
                <span className="bg-gradient-to-br from-primary-light to-white rounded-full p-1">
                  <SourceIcon />
                </span>
              </div>
            </div>
            <p className="counter text-3xl md:text-[40px] font-bold text-gray-700 ml-4">
              100%
            </p>
            <p className="text-gray-500 ml-4">Satisfaction</p>
          </div>
          <div className="py-2 shadow-lg rounded-lg">
            <div className="flex">
              <div className="bg-gradient-to-br from-primary-light to-white rounded-full ml-8">
                <span className="bg-gradient-to-br from-primary-light to-white rounded-full p-1">
                  <SupportIcon />
                </span>
              </div>
            </div>
            <p className="counter text-3xl md:text-[40px] font-bold text-gray-700 ml-4">
              24/7
            </p>
            <p className="text-gray-500 ml-4">Support</p>
          </div>
        </div>
      </div>
    </div>
    <div className="mt-8 md:mt-0">
      <h2 className="text-3xl md:text-[40px] font-bold text-gray-700">
        Designed to support full scope of your e-learning needs.
      </h2>
      <p className="mt-4 text-gray-500">
        Our platform is designed to support all e-learning needs from content creation to LMS hosting, all in one platform. 
      </p>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 text-gray-500">
        <div>
          <ul className="mt-4 space-y-2">
            <li className="flex items-center">
              <TickMarkIcon />
              <span className="ml-2">Designed to scale</span>
            </li>
            <li className="flex items-center">
              <TickMarkIcon />
              <span className="ml-2">Online support</span>
            </li>
          </ul>
        </div>
        <div>
          <ul className="mt-4 space-y-2">
            <li className="flex items-center">
              <TickMarkIcon />
              <span className="ml-2">AI Integration</span>
            </li>
            <li className="flex items-center">
              <TickMarkIcon />
              <span className="ml-2">100% satisfaction</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<div className="p-8 md:px-0">
  <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-16 p-4">
    <div>
      <h2 className="text-3xl md:text-[40px] font-bold text-gray-700 tracking-tight">
        Compatible with all your favorite LMS – SCROM
      </h2>
      <p className="mt-4 text-gray-500">
        Full integration and support for most popular platforms if you already have LMS. If
        there's an integration missing that you need, let us know and
        we'll happily add support.
      </p>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 text-gray-500">
        <div>
          <ul className="mt-4 space-y-2">
            <li className="flex items-center">
              <TickMarkIcon />
              <span className="ml-2">Moodle</span>
            </li>
            <li className="flex items-center">
              <TickMarkIcon />
              <span className="ml-2">Canvas</span>
            </li>
          </ul>
        </div>
        <div>
          <ul className="mt-4 space-y-2">
            <li className="flex items-center">
              <TickMarkIcon />
              <span className="ml-2">Blackboard Learn</span>
            </li>
            <li className="flex items-center">
              <TickMarkIcon />
              <span className="ml-2">TalentLMS</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div className="relative mt-8 md:mt-0">
  <div className="flex flex-col md:flex-row md:flex-wrap items-center relative">
    <div className="flex flex-row items-center justify-center w-full md:w-[400px] h-28 animate fade-in-up shadow-lg rounded-lg mb-8 md:mb-0">
      <ResponsiveIcon />
      <div className="flex flex-col ml-4">
        <p className="text-lg font-bold text-gray-700">Responsive settings</p>
        <p className="text-sm text-gray-500">Work on desktop and tablet</p>
      </div>
    </div>
    <div className="flex flex-row items-center justify-center w-full md:w-[400px] h-28 animate fade-in-up shadow-lg rounded-lg mb-8 md:mb-0 md:ml-8">
      <CommerceIcon />
      <div className="flex flex-col ml-4">
        <p className="text-lg font-bold text-gray-700">Marketplace</p>
        <p className="text-sm text-gray-500">Marketplace for templates etc.</p>
      </div>
    </div>
    <div className="flex flex-row items-center justify-center w-full md:w-[400px] h-28 animate fade-in-up shadow-lg rounded-lg md:ml-8">
      <ConversionIcon />
      <div className="flex flex-col ml-4">
        <p className="text-lg font-bold text-gray-700">Team oriented</p>
        <p className="text-sm text-gray-500">Work with your team</p>
      </div>
    </div>
  </div>
</div>

  </div>
</div>


       
        {!isPlanActive && (
        <div id="pricing" className="max-w-screen-xl px-4 py-8 mx-auto lg:py-24 lg:px-6">
          <div className="max-w-screen-md mx-auto mb-8 text-center lg:mb-12">
            <p className="text-md text-blue-600 mb-4">PRICING</p>
            <h2 className="mb-4 text-4xl font-extrabold tracking-tight text-gray-700 ">
              Designed for business teams like yours
            </h2>
            <p className="mb-5  text-gray-500 sm:text-xl ">
              Clear transparent pricing model to support all your needs. 
            </p>
          </div>
          <div className="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0">
            <div className="flex flex-col max-w-lg p-6 mx-auto text-center text-gray-700 bg-white border border-gray-100 rounded-lg shadow  xl:p-8">
              <h3 className="mb-4 text-2xl font-semibold">Starter</h3>
              <p className="font-light text-gray-500 sm:text-lg ">
                Best option for single use &amp; for your next project.
              </p>
              <div className="flex items-baseline justify-center my-8">
                <span className="mr-2 text-5xl font-extrabold">$29</span>
                <span className="text-gray-500 ">/month</span>
              </div>

              <ul className="mb-8 space-y-4 text-left">
                <li className="flex items-center space-x-3">
                  <TickMarkIcon />
                  <span>Desktop & Mobile</span>
                </li>
                <li className="flex items-center space-x-3">
                  <TickMarkIcon />
                  <span>50 AI credits included</span>
                </li>
                <li className="flex items-center space-x-3">
                  <TickMarkIcon />
                  <span>
                    Team size: <span className="font-semibold">1 developer</span>
                  </span>
                </li>
                <li className="flex items-center space-x-3">
                  <TickMarkIcon />
                  <span>
                    Premium support: <span className="font-semibold">6 months</span>
                  </span>
                </li>
                <li className="flex items-center space-x-3">
                  <TickMarkIcon />
                  <span>
                    Marketplace: <span className="font-semibold">6 months</span>
                  </span>
                </li>
              </ul>
              <button
                onClick={handleNavigation}
                className="text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:ring-blue-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              >
                Get started
              </button>
            </div>

            <div className="flex flex-col max-w-lg p-6 mx-auto text-center text-gray-700 bg-white border border-gray-100 rounded-lg shadow ">
              <h3 className="mb-4 text-2xl font-semibold">Pro</h3>
              <p className="font-light text-gray-500 sm:text-lg ">
                Relevant for professional users, AI Features &amp; premium support.
              </p>
              <div className="flex items-baseline justify-center my-8">
                <span className="mr-2 text-5xl font-extrabold">$79</span>
                <span className="text-gray-500 ">/month</span>
              </div>

              <ul className="mb-8 space-y-4 text-left">
                <li className="flex items-center space-x-3">
                  <TickMarkIcon />
                  <span>LMS Integration and hosting</span>
                </li>
                <li className="flex items-center space-x-3">
                  <TickMarkIcon />
                  <span>Unlimited AI credits</span>
                </li>
                <li className="flex items-center space-x-3">
                  <TickMarkIcon />
                  <span>
                    Team size:
                    <span className="font-semibold"> 1 developer</span>
                  </span>
                </li>
                <li className="flex items-center space-x-3">
                  <TickMarkIcon />
                  <span>
                    Premium support:
                    <span className="font-semibold"> 24 months</span>
                  </span>
                </li>
                <li className="flex items-center space-x-3">
                  <TickMarkIcon />
                  <span>
                    Marketplace: <span className="font-semibold">24 months</span>
                  </span>
                </li>
              </ul>
              <button
                onClick={handleNavigation}
                className="text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:ring-blue-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              >
                Get started
              </button>
            </div>

            <div className="flex flex-col max-w-lg p-6 mx-auto text-center text-gray-700 bg-white border border-gray-100 rounded-lg shadow  xl:p-8">
              <h3 className="mb-4 text-2xl font-semibold">Team</h3>
              <p className="font-light text-gray-500 sm:text-lg ">
                Best for large teams and full integration.
              </p>
              <div className="flex items-baseline justify-center my-8">
                <span className="mr-2 text-5xl font-extrabold">$129</span>
                <span className="text-gray-500 ">/month</span>
              </div>

              <ul className="mb-8 space-y-4 text-left">
                <li className="flex items-center space-x-3">
                  <TickMarkIcon />
                  <span>All Features</span>
                </li>
                <li className="flex items-center space-x-3">
                  <TickMarkIcon />
                  <span>Unlimited AI credits</span>
                </li>
                <li className="flex items-center space-x-3">
                  <TickMarkIcon />
                  <span>
                    Team size:
                    <span className="font-semibold"> 3 developers</span>
                  </span>
                </li>
                <li className="flex items-center space-x-3">
                  <TickMarkIcon />
                  <span>
                    Premium support:
                    <span className="font-semibold"> Unlimited</span>
                  </span>
                </li>
                <li className="flex items-center space-x-3">
                  <TickMarkIcon />
                  <span>
                    Marketplace: <span className="font-semibold">Unlimited</span>
                  </span>
                </li>
              </ul>
              <button
                onClick={handleNavigation}
                className="text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:ring-blue-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              >
                Get started
              </button>
            </div>
          </div>
        </div>
        )}
        <div className="container max-w-xl p-6 mx-auto space-y-12 lg:px-8 lg:max-w-7xl">
          <div>
            <h2 className="text-3xl font-bold text-center sm:text-5xl text-gray-700">
              New Features
            </h2>
            <p className="max-w-2xl mx-auto mt-4 text-xl text-center text-gray-500">
              Explore the latest features that enhance your learning experience
              and make it even more exciting.
            </p>
          </div>
          <div className="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
            <div>
              <div className="mt-4 space-y-12">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center w-12 h-12 rounded-md">
                      <AdvancedIcon />
                    </div>
                  </div>
                  <div className="ml-4">
                    <h4 className="text-lg font-medium text-gray-700">
                      Advanced Learning Algorithms
                    </h4>
                    <p className="mt-2 text-gray-500">
                      Discover our improved learning algorithms that adapt to
                      your preferences and provide even more personalized
                      learning suggestions.
                    </p>
                  </div>
                </div>
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center w-12 h-12 rounded-md">
                      <InteractiveIcon />
                    </div>
                  </div>
                  <div className="ml-4">
                    <h4 className="text-lg font-medium  text-gray-700">
                      Interactive Learning Resources
                    </h4>
                    <p className="mt-2 text-gray-500">
                      Access an extensive library of interactive resources that
                      make your learning journey engaging and interactive.
                    </p>
                  </div>
                </div>
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center w-12 h-12 rounded-md">
                     <VideoIcon />
                    </div>
                  </div>
                  <div className="ml-4">
                    <h4 className="text-lg font-medium text-gray-700">
                      Enhanced Video Streaming
                    </h4>
                    <p className="mt-2 text-gray-500">
                      Experience seamless video integration with enhanced
                      streaming capabilities, providing a better and more
                      uninterrupted learning experience.
                    </p>
                  </div>
                </div>
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center w-12 h-12 rounded-md">
                      <QuizIcon />
                    </div>
                  </div>
                  <div className="ml-4">
                    <h4 className="text-lg font-medium text-gray-700">
                      Advanced Quiz Generation
                    </h4>
                    <p className="mt-2 text-gray-500">
                      Take your knowledge testing to the next level with
                      advanced quiz generation, providing more customization
                      options for your quizzes.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div aria-hidden="true" className="mt-10 lg:mt-0">
              <img
                width="600"
                height="600"
                src="HomeScreen.jpeg"
                alt="Home Page"
                className="mx-auto rounded-lg shadow-lg"
              />
            </div>
          </div>
        </div>

        

        <div className="bg-gray-100">
          <div className="max-w-screen-lg py-10 px-4 sm:px-6 text-gray-800 sm:flex justify-between mx-auto">
            <div className="p-5 sm:w-2/12 border-r">
              <div className="text-sm uppercase text-blue-600 font-bold">Menu</div>
              <ul>
                <li className="my-2">
                  <a className="hover:text-blue-600" href="/">
                    Home
                  </a>
                </li>
                <li className="my-2">
                  <a className="hover:text-blue-600" href="/">
                    Services
                  </a>
                </li>
                <li className="my-2">
                  <a className="hover:text-blue-600" href="/">
                    Products
                  </a>
                </li>
                <li className="my-2">
                  <a className="hover:text-blue-600" href="/">
                    Pricing
                  </a>
                </li>
              </ul>
            </div>
            <div className="p-5 sm:w-7/12 border-r text-center">
              <h3 className="font-bold text-xl text-blue-600 mb-4">ailms.co</h3>
              <p className="text-gray-500 text-sm mb-10">
              Empowering Corporate Learning Everywhere with AI and Innovation: A Complete SCORM-Compatible, Browser-Based, Mobile-Friendly eLearning Solution for Every Company.
              </p>
            </div>
            <div className="p-5 sm:w-3/12">
              <div className="text-sm uppercase text-blue-600 font-bold">
                Contact Us
              </div>
              <ul>
                <li className="my-2">
                  <a className="hover:text-blue-600" href="/">
                    Valencia, Spain
                  </a>
                </li>
                <li className="my-2">
                  <a className="hover:text-blue-600" href="/">
                    contact@ailms.co
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="flex py-5 m-auto text-gray-800 text-sm flex-col items-center border-t max-w-screen-xl">
            <div className="md:flex-auto md:flex-row-reverse mt-2 flex-row flex">
              <a href="/" className="w-6 mx-1">
                <TwitterIcon />
              </a>
              <a href="/" className="w-6 mx-1">
                <YoutubeIcon />
              </a>
              <a href="/" className="w-6 mx-1">
                <InstagramIcon />
              </a>
              <a href="/" className="w-6 mx-1">
                <FacebookIcon />
              </a>
            </div>
            <div className="my-5">© Copyright 2024. All Rights Reserved.</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Content;
