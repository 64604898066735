import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth, useCheckSubscription } from '../../context/AuthContext';

function ProtectedRoute({ element }) {
  const { isAuthenticated, user } = useAuth();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate()
  const checkSubscription = useCheckSubscription();

  useEffect(() => {
    const path = location.pathname;

    const verifyAccess = async () => {
      if (!isAuthenticated && !localStorage.getItem('token')) {
        navigate('/access-denied');
        setLoading(false);
        return;
      }

      if (path.includes('/editor') || path === '/projects') {
        try {
          if (user) {
            const isSubscribed = await checkSubscription(user);
            if (isSubscribed) {
              setLoading(false);
            } else {
              navigate('/dashboard');
              setLoading(false);
            }
          }
        } catch (error) {
          setLoading(false);
          navigate('/access-denied');
        }
      } else {
        setLoading(false);
      }
    };

    verifyAccess();
  }, [isAuthenticated, user, location.pathname, checkSubscription, navigate]);

  if (loading) {
    return <div>Loading...</div>;
  }
  return element;
}

export default ProtectedRoute;
