import {createSlice} from '@reduxjs/toolkit'

const cropper = createSlice({
    name: 'cropper',
    initialState: {
        show: false,
        canvasImageSrc: '',
        crop: null,
        percentCrop: null,
    },
    reducers: {
        setCropperVisibility: (state, action) => {
            state.show = action.payload;
        },
        setCanvasImageSrc: (state, action) => {
            state.canvasImageSrc = action.payload;
        },
        setCrop: (state, action) => {
            state.crop = action.payload;
        },
        setPercentCrop: (state, action) => {
            state.percentCrop = action.payload;
        },
    }
});

export const {
    setCropperVisibility,
    setCanvasImageSrc,
    setCrop,
    setPercentCrop
} = cropper.actions;
export default cropper.reducer;