import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteVideoFromS3 } from "../../../utils/helpers";
import { shallowEqual } from "react-redux";
import { useAuth } from "../../../context/AuthContext";

const VideoGallery = ({ uploadStatus, handleAddVideo }) => {

    const { activeSlide } = useSelector(state => state.slideList, shallowEqual);
    const { videoList } = useSelector(state => state.imageBucket);
    const { canvas } = useSelector(state => state.fabricCanvas);
    const dispatch = useDispatch();
    const { user } = useAuth()
    const handleDeleteImage = async (video) => {
        // Call the delete function
        await deleteVideoFromS3(video, dispatch, user?.user_id);
    };


    return (
        <div
            className="svg-box flex mb-6 items-start justify-center overflow-x-hidden bg-white rounded-md border-t-0 rounded-t-none h-[165px] overflow-y-auto p-2 border">
            {
                videoList.length === 0 && uploadStatus && (
                    <div className="grid grid-cols-3 gap-2">
                        <div
                            className="group relative size-[45px] transition-all duration-200 flex items-center justify-center cursor-pointer overflow-hidden rounded-md bg-gray-100"
                        >
                            <FontAwesomeIcon icon={faSpinner} className="animate-spin text-gray-600 size-5" />
                        </div>
                    </div>
                )
            }
            {videoList && videoList.length !== 0 && (
                <div className="grid grid-cols-3 gap-2">
                    {
                        uploadStatus && (
                            <div
                                className="group relative size-[45px] transition-all duration-200 flex items-center justify-center cursor-pointer overflow-hidden rounded-md bg-gray-100"
                            >
                                <FontAwesomeIcon icon={faSpinner} className="animate-spin text-gray-600 size-5" />
                            </div>
                        )
                    }
                    {videoList.map((video, index) => (
                        <div onClick={() => handleAddVideo(video, canvas, null, dispatch, activeSlide?.id)} key={index}
                            className="group relative size-[45px] transition-all duration-200 flex-none cursor-pointer overflow-hidden rounded-md bg-white hover:opacity-85 hover:ring ring-gray-200 active:ring-gray-300">
                            <video
                                className="VideoInput_video"
                                style={{ display: 'none' }}
                                src={video.url}
                            />
                            <img src={video.thumbnail} alt={index}
                                className="h-full w-full object-cover rounded-md" />
                            <button
                                className="absolute top-0 right-0 p-1 bg-black text-white rounded-full text-[6px] flex items-center justify-center"
                                onClick={(e) => {
                                    e.stopPropagation(); // Prevent triggering the click event of the image/container
                                    handleDeleteImage(video); // Use your actual delete function here
                                }}
                            >
                                X {/* Using simple text "X" here */}
                            </button>
                        </div>
                    ))}
                </div>
            )}
            {
                videoList && videoList.length === 0 && !uploadStatus && (
                    <div
                        className="flex flex-col flex-grow items-center justify-center w-full h-full text-center text-gray-600 text-xs">
                        <div className="flex flex-row items-center justify-center w-full mb-2">
                            <FontAwesomeIcon icon={"file-image"} className="flex size-10" />
                        </div>
                        <div className="flex flex-row items-center justify-center w-full">
                            No video files found.
                        </div>
                    </div>
                )
            }
        </div>
    )
}
export default VideoGallery;