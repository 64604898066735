import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { applyAnimationFromData } from "../../utils/helpers";

const AnimationControl = () => {
  const { activeObject, canvas } = useSelector((state) => state.fabricCanvas);

  const [animationType, setAnimationType] = useState("translate");
  const [easingType, setEasingType] = useState("linear");


  useEffect(() => {
    if(activeObject && activeObject.customAnimation) {
      const { type, easing } = activeObject.customAnimation
      setAnimationType(type);
      setEasingType(easing)
    }
}, [activeObject, activeObject.jumpToSlide])

  const applyAnimation = () => {
    if (!activeObject) return;

    const animationData = {
      type: animationType,
      easing: easingType,
      duration: 1000, // You can make this dynamic or customizable
    };

    // Store animation data on the object itself
    activeObject.customAnimation = animationData;
    // Apply the animation
    applyAnimationFromData(activeObject, canvas, animationData);
    canvas.renderAll();
  };

  return (
    <div>
      <div>
        <label
          htmlFor="animation-type"
          className="mb-2 block text-xs font-medium text-gray-600"
        >
          Select Animation:{" "}
        </label>
        <select
          id="animation-type"
          value={animationType}
          onChange={(e) => setAnimationType(e.target.value)}
          className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
        >
          <option value="translate">Translate</option>
          <option value="rotate">Rotate</option>
          <option value="scale">Scale</option>
          <option value="opacity">Opacity</option>
          <option value="color">Color</option>
        </select>
      </div>

      <div className="mt-3">
        <label
          htmlFor="easing-type"
          className="mb-2 block text-xs font-medium text-gray-600"
        >
          Select Easing:{" "}
        </label>
        <select
          id="easing-type"
          value={easingType}
          onChange={(e) => setEasingType(e.target.value)}
          className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
        >
          <option value="linear">Linear</option>
          <option value="easeInQuad">Ease In</option>
          <option value="easeOutQuad">Ease Out</option>
          <option value="easeInOutQuad">Ease In Out</option>
        </select>
      </div>

      <button
        onClick={applyAnimation}
        className="my-3 bg-blue-500 hover:bg-blue-700 text-white py-2 px-4  w-full rounded-md text-[14px] focus:outline-none"
      >
        Apply Animation
      </button>
    </div>
  );
};
export default AnimationControl;
