import React, { useState } from "react";
import { useSelector } from "react-redux";
import QuestionBankModal from "./QuestionBank/QuestionBankModal";
import QuestionController from "./QuestionBank/QuestionController";
import ResultModal from "./Result/ResultModal";
import AnimationControl from "../AnimationControl";

const QuizPanel = () => {
  const { activeObject } = useSelector((state) => state.fabricCanvas);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isResultModalOpen, setResultModalOpen] = useState(false);

  const handleOpenModal = (type) => {
    if (type === "question") {
      setModalOpen(true);
    } else {
      setResultModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setResultModalOpen(false);
  };

  return (
    <div className="flex flex-col w-[280px] svg-box overflow-y-auto bg-gray-50 p-4">
      <label
        htmlFor="fontSelector"
        className="mb-2 block text-[16px] font-medium text-gray-600"
      >
        Quiz
      </label>
      <button
        onClick={() => handleOpenModal("question")}
        className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
      >
        Question Bank
      </button>
      <button
        onClick={() => handleOpenModal("result")}
        className="px-4 py-2 my-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
      >
        Results
      </button>
      {activeObject && activeObject.type === "quiz" ? (
        <div className="my-2">
          <p className="text-[16px] text-slate-500 my-2">Update Question</p>
          <QuestionController />
        </div>
      ) : null}
      {isModalOpen && (
        <QuestionBankModal isOpen={isModalOpen} closeModal={handleCloseModal} />
      )}
      {isResultModalOpen && (
        <ResultModal isOpen={isResultModalOpen} closeModal={handleCloseModal} />
      )}
      {activeObject && <AnimationControl/>}
    </div>
  );
};

export default QuizPanel;
