import React, {useEffect, useRef, useState} from "react";
import {useWavesurfer} from '@wavesurfer/react'
//import Timeline from 'wavesurfer.js/dist/plugins/timeline.esm.js'
import {useDispatch, useSelector} from "react-redux";
import {handleLoader} from "../utils/helpers";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    triggerRefreshAudioWaveform,
    setAllMediaToSpecificSlide,
    deleteAllMediaFromSpecificSlide
} from "../store/reducers/slideListSlice";
import audioFile from "../utils/audioFile";

const AudioWaveform = ({audio, slide}) => {
    // eslint-disable-next-line 
    const {activeSlide, refreshAudioWaveform} = useSelector(state => state.slideList);
    const waveformRef = useRef(null);
    const [showWaveform, setShowWaveform] = useState(false);
    const [showOptions, setShowOptions] = useState(false);
    const dispatch = useDispatch();
    const optionRef = useRef(null);

    const {wavesurfer} = useWavesurfer({
        container: waveformRef,
        height: 12,
        width: 60,
        waveColor: 'rgb(107 114 128)',
        barWidth: 1,
        barGap: 1,
        barRadius: 2,
        barHeight: 2,
        progressColor: 'rgb(107 114 128)',
        url: audio.url,
    })

    //const formatTime = (seconds) => [seconds / 60, seconds % 60].map((v) => `0${Math.floor(v)}`.slice(-2)).join(':')

    useEffect(() => {
        if (wavesurfer) {
            const onReady = (duration) => {
                handleLoader(false, '', dispatch);
                setShowWaveform(true);
            };
    
            const onDestroy = () => {
                setShowWaveform(false);
            };
    
            // Subscribe to events
            wavesurfer.on('ready', onReady);
            wavesurfer.on('destroy', onDestroy);
    
            // Cleanup function
            return () => {
                // Check if wavesurfer still exists and has the 'off' method
                if (wavesurfer && typeof wavesurfer.off === 'function') {
                    wavesurfer.off('ready', onReady);
                    wavesurfer.off('destroy', onDestroy);
                }
            };
        }
    }, [wavesurfer, dispatch]);

    const handleClickOutside = (event) => {
        if (optionRef.current && !optionRef.current.contains(event.target)) {
            setShowOptions(false);
        }
    };

    useEffect(() => {
        // Add event listener on mount
        document.addEventListener('mousedown', handleClickOutside);

        // Remove event listener on component unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []); // Empty dependency array means this effect runs only once, on mount


    const handleDeleteAudioFile = () => {
        dispatch(deleteAllMediaFromSpecificSlide({
            id: audio?.id,
            slideId: slide.id,
            type: 'audio'
        }));
        dispatch(triggerRefreshAudioWaveform());
    }

    const handleDuplicateAudioFile = () => {
        const newAudio = new audioFile('');
        newAudio.loadAudioJSON(audio.getAudioJSON());
        dispatch(setAllMediaToSpecificSlide({
            file: newAudio,
            slideId: slide.id,
            type: 'audio'
        }));
        dispatch(triggerRefreshAudioWaveform());
    }

    return (
        <>
            <div
                className={`flex text-gray-600 items-center w-[100px] cursor-pointer hover:bg-gray-200 h-[30px] justify-between rounded-md bg-gray-100 ${showWaveform ? '' : 'hidden'}`}>
                <div ref={waveformRef} className="pointer-events-none ml-2"/>
                {
                    !showOptions && (
                        <div className="flex flex-grow items-center justify-center">
                            <button className="flex p-2 items-center mr-2 justify-center hover:bg-gray-300 rounded-full"
                                    onClick={() => setShowOptions(true)}
                            >
                                <FontAwesomeIcon icon={"ellipsis-h"} className="items-center justify-center size-[12px]"/>
                            </button>
                        </div>
                    )
                }
                {
                    showOptions && (
                        <div className="flex mr-2" ref={optionRef}>
                            
                            <button className="flex p-2 items-center justify-center hover:bg-gray-300 rounded-full"
                                    onClick={handleDeleteAudioFile}
                            >
                                <FontAwesomeIcon icon={"trash-alt"} className="items-center justify-center size-[12px]"/>
                            </button>
                            <button className="flex p-2 items-center justify-center hover:bg-gray-300 rounded-full"
                                    onClick={handleDuplicateAudioFile}
                            >
                                <FontAwesomeIcon icon={"copy"} className="items-center justify-center size-[12px]"/>
                            </button>
                        </div>
                    )
                }
            </div>
        </>
    );
}
export default AudioWaveform;