import {v4 as uuidv4} from "uuid";

export default class AudioFile {
    constructor(fileUrl, start = 0, end = 10) {
        this.id = uuidv4();
        this.url = fileUrl;
        this.name = '';
        // Initialize start and end properties
        this.start = start;
        this.end = end;

        let regex;
        if(this.url.includes('audio_list_recorded')) {
            regex = /recording_(\d+)\.webm/;
        } else {
            regex = /_([^_]+)\.mp3/;
        }
        const match = fileUrl.match(regex);
        if (match) {
            this.name = match[1];
        }
    }

    getId = () => {
        return this.id;
    }

    getUrl = () => {
        return this.url;
    }

    // Updated getAudioJSON to include start and end
    getAudioJSON = () => {
        return {
            id: this.id,
            url: this.url,
            name: this.name,
            start: this.start,
            end: this.end,
        }
    }

    // Updated loadAudioJSON to include start and end
    loadAudioJSON = (json) => {
        const {url, name, start, end, src = ''} = json;
        this.id = uuidv4();
        this.url = url || src;
        this.name = name;
        this.start = start;
        this.end = end;
    }
}
