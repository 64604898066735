export const characters = ["Female 1", "Male 2"];


// Function to truncate audio blob to the first 5 seconds
export async function truncateAudio(blob) {
    // Create an audio context
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    
    // Decode audio data from the blob
    const arrayBuffer = await blob.arrayBuffer();
    const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
    
    // Calculate the number of samples for 5 seconds
    const sampleRate = audioBuffer.sampleRate;
    const duration = 5; // 5 seconds
    const numberOfChannels = audioBuffer.numberOfChannels;
    
    // Create a new buffer with the 5-second segment
    const truncatedBuffer = audioContext.createBuffer(numberOfChannels, sampleRate * duration, sampleRate);
    
    // Copy data from the original buffer to the new buffer
    for (let channel = 0; channel < numberOfChannels; channel++) {
        const originalData = audioBuffer.getChannelData(channel);
        const truncatedData = truncatedBuffer.getChannelData(channel);
        truncatedData.set(originalData.subarray(0, sampleRate * duration));
    }
    
    // Export the truncated audio as a new blob
    const offlineAudioContext = new OfflineAudioContext(numberOfChannels, sampleRate * duration, sampleRate);
    const bufferSource = offlineAudioContext.createBufferSource();
    
    bufferSource.buffer = truncatedBuffer;
    bufferSource.connect(offlineAudioContext.destination);
    bufferSource.start();

    const renderedBuffer = await offlineAudioContext.startRendering();

    const truncatedBlob = await new Promise((resolve) => {
        renderedBuffer.copyFromChannel(new Float32Array(renderedBuffer.length), 0);
        const wavArrayBuffer = audioBufferToWav(renderedBuffer);
        resolve(new Blob([wavArrayBuffer], { type: 'audio/wav' }));
    });

    return truncatedBlob;
}

// Utility function to convert an AudioBuffer to WAV format
function audioBufferToWav(buffer) {
    const numOfChan = buffer.numberOfChannels;
    const length = buffer.length * numOfChan * 2 + 44;
    const bufferCopy = new ArrayBuffer(length);
    const view = new DataView(bufferCopy);

    // RIFF chunk descriptor
    writeString(view, 0, 'RIFF');
    view.setUint32(4, 36 + buffer.length * numOfChan * 2, true);
    writeString(view, 8, 'WAVE');

    // FMT sub-chunk
    writeString(view, 12, 'fmt ');
    view.setUint32(16, 16, true); // Chunk size
    view.setUint16(20, 1, true);  // Audio format: PCM
    view.setUint16(22, numOfChan, true);
    view.setUint32(24, buffer.sampleRate, true);
    view.setUint32(28, buffer.sampleRate * 4, true);
    view.setUint16(32, numOfChan * 2, true); // Block align
    view.setUint16(34, 16, true);            // Bits per sample

    // Data sub-chunk
    writeString(view, 36, 'data');
    view.setUint32(40, buffer.length * numOfChan * 2, true);

    // Write the PCM samples
    let offset = 44;
    for (let i = 0; i < buffer.numberOfChannels; i++) {
        const channelData = buffer.getChannelData(i);
        for (let j = 0; j < channelData.length; j++, offset += 2) {
            const s = Math.max(-1, Math.min(1, channelData[j]));
            view.setInt16(offset, s < 0 ? s * 32768 : s * 32767, true);
        }
    }

    return bufferCopy;
}

// Utility function to write a string to the DataView
function writeString(view, offset, string) {
    for (let i = 0; i < string.length; i++) {
        view.setUint8(offset + i, string.charCodeAt(i));
    }
}
