import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {useSelector} from "react-redux";

const LoaderPopup = () => {

    const {visibility, message} = useSelector(state => state.customLoader);

    return(
        visibility && <div className="flex items-center text-gray-600 z-50 shadow justify-center p-2 absolute text-xs right-0 top-0 bg-white rounded-md h-10 mr-2 mt-2">
            <FontAwesomeIcon icon={faSpinner} className="animate-spin text-gray-600 size-3 mr-1"/>{message}
        </div>
    )
}
export default LoaderPopup;