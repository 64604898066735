import React from "react";
import {shapes, ShapesHelper} from "../../../utils/shapesHelper";
import {useSelector} from "react-redux";
import ShapeFill from "./ShapeFill";
import SearchBar from "../../SearchBar/SearchBar";
import LayerAndLinkControl from "../TextPanel/LayerAndLinkControl";
import SvgPagination from "./SvgsPagination";
import Spinner from "../../Spinner";
import AnimationControl from "../AnimationControl";


const ShapesPanel = () => {
    const {activeSlide} = useSelector(state => state.slideList);
    const {activeObject} = useSelector(state => state.fabricCanvas);
    const {svgs, isLoading, filteredSvgs} = useSelector(state => state.slideList);
    // ShapesHelper class initialized
    const shapesHelper = new ShapesHelper({activeSlide})
    const addShape = (shape) => {
        shapesHelper.add(shape.type,shape.path)
    };


    return (
        <div className="flex flex-col w-[280px] overflow-y-auto bg-gray-50 p-4">
            <div className="items-center justify-center">
                <label htmlFor="fontSelector" className="mb-2 block text-xs font-medium text-gray-600">Select Shapes</label>
                <div className="flex items-center justify-center">
                        <div className="grid grid-cols-6 gap-1">
                            {shapes.map((shape) => (
                                <button
                                    key={shape.name}
                                    className="size-8 p-2"
                                    onClick={() => addShape(shape)}
                                >
                                    {shape.icon}
                                </button>
                            ))}
                        </div>
                    </div>
                    {/* <Divider label={'Icons'}/> */}
                    <label htmlFor="fontSelector" className="mb-2 mt-2 block text-xs font-medium text-gray-600">Search Icons</label>

                <div
                    className="mt-2 mb-6 items-start justify-center bg-white rounded-md h-[280px] overflow-y-auto overflow-x-hidden p-2 border">
                    <SearchBar svgs={svgs}/>
                    
                    {isLoading ? <Spinner/> : <SvgPagination svgs={filteredSvgs}/>}
                </div>
                {activeObject && (
                    <>
                        <ShapeFill/>
                        <LayerAndLinkControl/>
                        <AnimationControl/>
                    </>
                )}
            </div>
        </div>
    );
};

export default ShapesPanel;
