// @ts-nocheck
// image cropper
import { fabric } from "fabric";
export function loadImageFromURL(src) {
    return new Promise((resolve) => {
        const image = new Image();
        image.src = src;
        image.crossOrigin = "Anonymous";
        image.onload = () => {
            resolve(image);
        };
    });
}
const loadImage = (url) => {
    return new Promise((resolve) => {
        fabric.Image.fromURL(url, (img) => {
            resolve(img);
        }, { crossOrigin: "anonymous" });
    });
};
function rotatedPoint(point, angle, center) {
    angle = (Math.PI / 180) * angle;
    return {
        x: (point.x - center.x) * Math.cos(angle) -
            (point.y - center.y) * Math.sin(angle) +
            center.x,
        y: (point.x - center.x) * Math.sin(angle) +
            (point.y - center.y) * Math.cos(angle) +
            center.y,
    };
}
const multiply = fabric.util.multiplyTransformMatrices;
const invert = fabric.util.invertTransform;
export class StaticImageObject extends fabric.Image {
    static type = "image";
    role = "regular";
    _cropInfo;
    _cropper;
    _isCropping;
    _background;
    registerEventListeners() {
        this.on("mousedblclick", () => {
            // this.cropInit();
        });
        this.setControlsVisibility({
            mt: false, // middle top disable
            mb: false, // middle bottom disable
            ml: false, // middle left disable
            mr: false, // middle right disable
            mtr: false, //rotation point
        });
        // Disable scaling flip
        this.lockScalingFlip = true;
        // Add scaling event listener to lock aspect ratio
        this.on('scaling', function () {
            const w = this.width * this.scaleX;
            const h = this.height * this.scaleY;
            const aspectRatio = w / h;

            // Determine the aspect ratio based on initial scale
            if (aspectRatio > 1) {
                // Width is greater than height, lock horizontal scaling to vertical
                this.scaleX = this.scaleY;
            } else {
                // Height is greater than width, lock vertical scaling to horizontal
                this.scaleY = this.scaleX;
            }
        });
    }
    initialize(element, options) {
        this.role = element.role;
        options.type = "image";
        super.initialize(element, options);
        this.prepare();
        this.registerEventListeners();
        return this;
    }
    async prepare() {
        if(!this._cropInfo){
            const cropInfo = {
                top: 0,
                left: 0,
                width: this.width,
                height: this.height,
                initiated: false,
            };
            this._cropInfo = cropInfo;
        }

    }
    preventObjectsFromScalingOutParent(event) {
        const obj = event.transform.target;
        // let relationship = this.rect.relationship;
        // let newTransform = multiply(obj.calcTransformMatrix(), relationship);
        // let opt = fabric.util.qrDecompose(newTransform);
        // this.rect.set({
        //     flipX: false,
        //     flipY: false,
        // });
        // this.rect.setPositionByOrigin({ x: opt.translateX, y: opt.translateY }, "center", "center");
        // this.rect.set(opt);
        // this.rect.set({width:this._cropper.width * this._cropper.scaleX, height:this._cropper.height * this._cropper.scaleY, left:this._cropper.left, top:this._cropper.top})


        let objRect = {};
        let cropperCoords = obj.getBoundingRect(true, true);
        // return
        obj.setCoords();
        this.setCoords();
        if (obj.id === 'cropper') {
            objRect = obj._cropped.getBoundingRect(true, true);
        }
        else if (obj.id === 'nextCropped') {
            objRect = obj.getBoundingRect(true, true);
            cropperCoords = (obj._cropper || this._cropper).getBoundingRect(true, true);
        }
        if (((cropperCoords.width + cropperCoords.left) > objRect.width + objRect.left) || (((cropperCoords.height - 0.5) + cropperCoords.top) > objRect.height + objRect.top) || ((cropperCoords.left < objRect.left) || (cropperCoords.top < objRect.top))) {

            obj.left = (this.left1 || obj.left);
            obj.top = (this.top1 || obj.top);
            obj.width = (this.width1 || obj.width);
            obj.height = (this.height1 || obj.height);
            obj.scaleX = (this.scale1x || obj.scaleX);
            obj.scaleY = (this.scale1y || obj.scaleY);
            obj.angle = (this.angle1 || obj.angle);
            obj.setCoords();
            this.setCoords();
        }
        this.rect.set({width:obj.width * obj.scaleX, height:obj.height * obj.scaleY, left:obj.left, top:obj.top})

        this.left1 = obj.left;
        this.top1 = obj.top;
        this.scale1x = obj.scaleX;
        this.scale1y = obj.scaleY;
        this.width1 = obj.width;
        this.height1 = obj.height;
        this.angle1 = obj.angle;
    }
    ;
    async cropInit() {
        this._isCropping = true;
        this._oldAngle = this.angle;
        this.angle = 0;
        const canvas = this.canvas;
        const cropped = this;
        this.previousCrop = this;

        const cropInfo = cropped._cropInfo;
        const np = rotatedPoint({
            x: cropped.left - cropInfo.left * cropped.scaleX,
            y: cropped.top - cropInfo.top * cropped.scaleY,
        }, cropped.angle, { x: cropped.left, y: cropped.top });
        const background = await loadImage(this.src || this.getSrc());
        const nextCropped = await loadImage(this.src || this.getSrc());

        background.set({
            canvas:this.canvas,
            lockScalingX:true,
            lockScalingY:true,
            img:this,
            id: "background",
            state: "bg",
            left: np.x,
            opacity:0.3,
            top: np.y,
            scaleX: cropped.scaleX,
            scaleY: cropped.scaleY,
            angle: cropped.angle,
        });
        cropped.set({
            selectable: false,
            lockMovementX: true,
            lockMovementY: true,
            evented: false,
        });
        nextCropped.set({
            id: "nextCropped",
            img:this,
            state: "cropped",
            left: np.x,
            top: np.y,
            width: background.width,
            lockScalingX:true,
            lockScalingY:true,
            height: background.height,
            scaleX: cropped.scaleX,
            scaleY: cropped.scaleY,
            angle: cropped.angle,
            opacity: 1,
        });
        const cropper = new fabric.Rect({
            id: "cropper",
            canvas:this.canvas,
            img:this,
            state: "bg",
            absolutePositioned: true,
            backgroundColor: "rgba(0,0,0,0)",
            opacity: 0.00001,
            lockScalingFlip: true,
            lockMovementX: true,
            lockMovementY: true,
            padding:0,
            top: cropped.top,
            strokeWidth:0,
            left: cropped.left,
            width: cropped.width-2,
            height: cropped.height-2,
            scaleX: cropped.scaleX,
            scaleY: cropped.scaleY,
            angle: cropped.angle,
            selectable: false,
        });
        this._oldCropper = cropper;
        this._oldCropped = nextCropped;
        cropper.setControlsVisibility({
            mtr: false,
        });
        nextCropped.setControlsVisibility({
            mtr: false,
            mt: false,
            ml: false,
            mr: false,
            mb: false,
        });
        cropper.on("scaling", this.preventObjectsFromScalingOutParent.bind(this));
        nextCropped.on("scaling", this.preventObjectsFromScalingOutParent.bind(this));
        const getPositionAdjustment = function (_cropper, other, absolute, calculate) {
            const bgCheckpoints = other._getCoords(absolute, calculate);
            const containerCheckPoints = _cropper._getCoords(absolute, calculate);
            const positionAdjustment = { left: 0, top: 0 };
            if (containerCheckPoints.bl.y > bgCheckpoints.bl.y) {
                positionAdjustment.top = containerCheckPoints.bl.y - bgCheckpoints.bl.y;
            }
            else if (containerCheckPoints.tl.y < bgCheckpoints.tl.y) {
                positionAdjustment.top = containerCheckPoints.tl.y - bgCheckpoints.tl.y;
            }
            if (containerCheckPoints.tl.x < bgCheckpoints.tl.x) {
                positionAdjustment.left = containerCheckPoints.tl.x - bgCheckpoints.tl.x;
            }
            else if (containerCheckPoints.tr.x > bgCheckpoints.tr.x) {
                positionAdjustment.left = containerCheckPoints.tr.x - bgCheckpoints.tr.x;
            }
            return positionAdjustment;
        };
        const updateBackground = (event) => {
            nextCropped.setCoords();
            let relationship = background.relationship;
            let newTransform = multiply(nextCropped.calcTransformMatrix(), relationship);
            let opt = fabric.util.qrDecompose(newTransform);
            background.set({
                flipX: false,
                flipY: false,
            });
            background.setPositionByOrigin({ x: opt.translateX, y: opt.translateY }, "center", "center");
            background.set(opt);
            background.setCoords();
            background.setCoords();
            cropper.setCoords();
            background.setCoords();
            background.saveState();
            if (event.transform.action === "drag") {
                const positionAdjustment = getPositionAdjustment(cropper, background, true, true);
                background.left += positionAdjustment.left;
                background.top += positionAdjustment.top;
                nextCropped.left += positionAdjustment.left;
                nextCropped.top += positionAdjustment.top;
                background.setCoords();
                background.saveState();
            }
        };
        nextCropped.on("moving", updateBackground);
        nextCropped.on("rotating", updateBackground);
        nextCropped.on("scaling", updateBackground);
        let bossTransform = nextCropped.calcTransformMatrix();
        let invertedBossTransform = invert(bossTransform);
        // background
        let desiredTransform = multiply(invertedBossTransform, background.calcTransformMatrix());
        // save the desired relation here.
        background.relationship = desiredTransform;
        nextCropped.clipPath = cropper;
        canvas.add(background);
        const overlay = new fabric.Rect({
            id: "overlay",
            state: "crop_middleman",
            width: (this.canvas.width / this.canvas.getZoom())*2,
            height: (this.canvas.height / this.canvas.getZoom())*2,
            fill: "#000000",
            opacity: 0.5,
            selectable: false,
            evented: false,
        });
        this.overlay = overlay;
        overlay.center();
        overlay.left -= 1;
        overlay.top -=1;
        canvas.add(overlay);
        overlay.sendBackwards();

        canvas.add(nextCropped);
        nextCropped.bringToFront();
        canvas.requestRenderAll();
        cropper.hasBorders = false;
        canvas.on("mouse:up", this.onMouseUp.bind(this));
        cropper._cropped = nextCropped;
        cropped.set({
            top: -100000,
            left: -100000,
        });
        // var brightnessFilter = new fabric.Image.filters.Brightness({
        //     brightness: -0.25 // You can adjust the brightness value as needed
        // });
        // background.filters.push(brightnessFilter);
        // background.applyFilters();
        canvas.setActiveObject(cropper);

        // Marching ants effect

        var rect = new fabric.Rect({
            left: cropper.left,
            top: cropper.top,
            evented:false,
            width: cropper.width*cropper.scaleX,
            height: cropper.height*cropper.scaleY,
            fill: 'transparent',
            selectable: true,
        });

        // Add rectangle to canvas
        this.setRectRelation(cropper,rect);
        this.rect = rect;
        canvas.add(rect);

        // Create marching ants effect


        // Start marching ants effect
        this.marchingAnts(rect);

        this._cropper = cropper;
        this._background = background;
        canvas.croppingObject = this;
        this.canvas = canvas;
    }

    setRectRelation(cropper,rect){
        let bossTransform = cropper.calcTransformMatrix();
        let invertedBossTransform = invert(bossTransform);
        // background
        let desiredTransform = multiply(invertedBossTransform, rect.calcTransformMatrix());
        // save the desired relation here.
        rect.relationship = desiredTransform;
    }


    marchingAnts(rect) {
        if(!this._isCropping &&  this.animationId){
            this.stopAnimation();
            return
        }
        rect.set({
            strokeWidth:4,
            strokeDashArray: [5, 5],
            stroke: '#ffffff' //color of cropping rect
        });

        this.canvas.renderAll();

        this.animationId = fabric.util.requestAnimFrame((function() {
            rect.set({
                strokeDashOffset: (rect.strokeDashOffset + 1) % 10
            });
            this.marchingAnts(rect);
        }).bind(this));
    }
    stopAnimation() {
        cancelAnimationFrame(this.animationId);
    }
    onMouseUp(e) {
        if (this._cropper && this._background && this._isCropping) {
            // if (isInCropper) {
                const canvas = this.canvas;
                canvas.discardActiveObject();
                canvas.setActiveObject(this._cropper);
                canvas.requestRenderAll();
            // }
            // if (!isInBackground && !isInCropper) {
            //     this.cropApply();
            //     setTimeout(()=>{
            //         this.canvas.renderAll();
            //     },1)
            // }
        }
    }
    async cropApply(isCanceled=false) {
        const canvas = this.canvas;
        canvas.off("mouse:up", this.onMouseUp.bind(this));
        if (!this._isCropping)
            return;
        this._isCropping = false;
        // const cropper = isCanceled?this._oldCropper:this._cropper;
        // const cropped = isCanceled?this.previousCrop:cropper._cropped;
        if(!isCanceled){
            const cropper = this._cropper;
            const cropped = cropper._cropped;
            const sX = cropped.scaleX;
            const sY = cropped.scaleY;
            cropper.set({
                width: cropper.width * cropper.scaleX,
                height: cropper.height * cropper.scaleY,
                scaleX: 1,
                scaleY: 1,
            });
            canvas.remove(cropped);
            const np = rotatedPoint({ x: cropper.left, y: cropper.top }, -cropper.angle, { x: cropped.left, y: cropped.top });
            const cropInfo = {
                top: (np.y - cropped.top) / sY,
                left: (np.x - cropped.left) / sX,
                width: cropper.width / cropped.scaleX,
                height: cropper.height / cropped.scaleY,
            };
            this.set({
                left: cropper.left,
                top: cropper.top,
                angle: cropper.angle,
                lockScalingFlip: true,
                scaleX: sX,
                scaleY: sY,
                width: cropper.width / sX,
                height: cropper.height / sY,
                cropX: cropInfo.left,
                cropY: cropInfo.top,
            });
            this._cropInfo = { ...cropInfo, initiated: true };
            canvas.getObjects().forEach((o) => {
                if (o.state === "bg") {
                    canvas.remove(o);
                }
            });
            this.set({
                selectable: true,
                lockMovementX: false,
                lockMovementY: false,
                evented: true,
            });
            canvas.setActiveObject(this);
            canvas.remove(this.rect);
            canvas.remove(cropper);
            canvas.remove(this.overlay);
            if (cropper) {
                cropper.set({
                    width: 0,
                    height: 0,
                    opacity: 0,
                    controls: false,
                    top: -1000,
                    left: -1000,
                });
            }
            this.set({angle:this._oldAngle});
            canvas.set('dirty', true);
            canvas.requestRenderAll();
        }
        else{
            const cropper = this._oldCropper;
            const cropped = cropper._cropped;
            // this._cropInfo= null;
            this.set({
                left:cropper.left,
                top:cropper.top,
                angle:this._oldAngle,
                selectable: true,
                lockMovementX: false,
                lockMovementY: false,
                evented: true,
            })
            this.left = cropper.left;
            this.top = cropper.top;
            this.setCoords();
            this.angle = this._oldAngle;
            canvas.remove(this.rect);
            canvas.remove(cropped);
            canvas.remove(this.overlay);
            canvas.getObjects().forEach((o) => {
                if (o.state === "bg") {
                    canvas.remove(o);
                }
            });
            canvas.discardActiveObject();
            canvas.setActiveObject(this);
            setTimeout(()=>{
                canvas.renderAll();
            },100)

        }

    }
    cropCancel() { }
    static fromObject(options, callback) {
        fabric.util.loadImage(options.src, function (img) {
            return callback && callback(new fabric.StaticImage(img, options));
        }, null, { crossOrigin: "anonymous" });
    }
    toObject(propertiesToInclude = []) {
        return fabric.util.object.extend(super.toObject(propertiesToInclude), {
            cropInfo: this._cropInfo,
        });
    }
    toJSON(propertiesToInclude = []) {
        return fabric.util.object.extend(super.toJSON(propertiesToInclude), {
            cropInfo: this._cropInfo,
        });
    }
}
fabric.StaticImage = fabric.util.createClass(StaticImageObject, {
    type: StaticImageObject.type,
});
fabric.StaticImage.fromObject = StaticImageObject.fromObject;
