import React, { useEffect, useState } from 'react';
import Button from './Button.jsx'
import TextInput from './TextInput.jsx'
import Card from './Card.jsx';
import { useSelector } from 'react-redux';
import { upperFirst } from 'lodash';
import AnimationControl from '../AnimationControl.jsx';

const ComponentsPanel = ({ onAdd }) => {
  const [elementType, setElementType] = useState('');
  const { activeObject } = useSelector(
    (state) => state.fabricCanvas
  );

  const { show: showCropper } = useSelector((state) => state.cropper);

  const renderElementOptions = () => {
    switch (elementType) {
      case 'Button':
        return <Button/>;
      case 'TextInput':
        return <TextInput/>;
        case 'Card':
          return <Card/>;
      // Add cases for other components here
      default:
        return null;
    }
  };

  useEffect(() => {
    if (activeObject && !showCropper && ['button', 'textInput', 'card'].includes(activeObject?.type)) {
      setElementType(upperFirst(activeObject?.type) || "")
    }}, [showCropper, activeObject])

  return (
    <div className="flex flex-col w-[280px] svg-box overflow-y-auto bg-gray-50 p-4">
              <label htmlFor="fontSelector" className="mb-2 block text-xs font-medium text-gray-600">Select Component </label>
              <select onChange={e => setElementType(e.target.value)}>
                <option value="">Select element</option>
                <option value="TextInput">TextInput</option> {/* Corrected typo here */}
                <option value="Button">Button</option>
                <option value="Card">Card</option>
                {/* Add other elements here */}
                </select>
     
      {/* Add inputs for height, width, borderColor, backgroundColor */}
      {/* <button onClick={handleAddElement}>Add to Canvas</button> */}
      {renderElementOptions()}
      {activeObject && ['button', 'textInput', 'card'].includes(activeObject?.type) && <AnimationControl/>}
    </div>
  );
};
export default ComponentsPanel;