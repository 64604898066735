import React, { useEffect, useState } from 'react';

function TrueFalseQuestionsFrom({ setData, data }) {
    const [formData, setFormData] = useState({
        question: data?.question || '',
        trueAnsPropmt: data?.trueAnsPropmt || '',
        falseAnsPropmt: data?.falseAnsPropmt || '',
        trueBtnText: data?.trueBtnText || 'True',
        falseBtnText: data?.falseBtnText || 'False',
        addToScoring: data?.addToScoring || false
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, ...data, [name]: value });
    };

    useEffect(() => {
        if (
            formData?.question ||
            formData?.trueAnsPropmt ||
            formData?.falseAnsPropmt ||
            formData?.trueBtnText ||
            formData?.falseBtnText
        ) {
            setData(formData);
        }
    }, [formData, setData]);

    return (
        <div>
            <div className='my-2'>
                <label
                    htmlFor='question'
                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                >
                    Enter Your Question
                </label>
                <input
                    type='text'
                    id='question'
                    name='question'
                    value={data?.question || formData?.question || ''}
                    onChange={handleChange}
                    className='block w-full p-2 text-gray-900 border border-gray-300 rounded bg-gray-50 text-sm focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                />
            </div>
            <div className='flex items-center justify-center my-5 gap-[10px] flex-wrap'>
                <button
                    type='button'
                    className='px-4 mr-0 bg-blue-500 text-white rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600'
                >
                    <input
                        type='text'
                        id='trueBtnText'
                        name='trueBtnText'
                        value={data?.trueBtnText || formData?.trueBtnText || ''}
                        onChange={handleChange}
                        className='block w-full p-2 text-white text-center bg-inherit text-sm focus:outline-none'
                    />
                </button>
                <button
                    type='button'
                    className='px-4 bg-red-500 text-white rounded-lg shadow-md hover:bg-red-600 focus:outline-none focus:bg-red-600'
                >
                    <input
                        type='text'
                        id='falseBtnText'
                        name='falseBtnText'
                        value={data?.falseBtnText || formData?.falseBtnText || ''}
                        onChange={handleChange}
                        className='block w-full p-2 text-white text-center bg-inherit text-sm focus:outline-none'
                    />
                </button>
            </div>
            <div className='my-2'>
                <input
                    type='text'
                    id='trueAnsPropmt'
                    name='trueAnsPropmt'
                    value={data?.trueAnsPropmt || formData?.trueAnsPropmt || ''}
                    onChange={handleChange}
                    placeholder='Enter prompt for correct answer'
                    className='block w-full p-2 text-gray-900 border border-gray-300 rounded bg-gray-50 text-sm focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                />
            </div>
            <div className='my-2'>
                <input
                    type='text'
                    id='falseAnsPropmt'
                    name='falseAnsPropmt'
                    value={data?.falseAnsPropmt || formData?.falseAnsPropmt || ''}
                    onChange={handleChange}
                    placeholder='Enter prompt for incorrect answer'
                    className='block w-full p-2 text-gray-900 border border-gray-300 rounded bg-gray-50 text-sm focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                />
            </div>
        </div>
    );
}

export default TrueFalseQuestionsFrom;
