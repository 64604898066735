import { createSlice } from "@reduxjs/toolkit";

const projectList = createSlice({
  name: "projectList",
  initialState: {
    projects: [],
  },
  reducers: {
    setProjects: (state, action) => {
      state.projects = action.payload;
    },
    addProject: (state, action) => {
      const newProject = {
        id: action.payload?.id,
        ...action.payload?.attributes,
        project_image: {
          id: action.payload?.attributes?.project_image?.data?.id,
          ...action.payload?.attributes?.project_image?.data?.attributes,
        },
      };
      state.projects.push(newProject);
    },

    deleteProject: (state, action) => {
      const tempProjectList = [...state.projects];
      state.projects = tempProjectList.filter(
        (project) => project.project_id !== action.payload
      );
    },
    updateProject: (state, action) => {
      const updatedProject = {
        id: action.payload?.id,
        ...action.payload?.attributes,
        project_image: {
          id: action.payload?.attributes?.project_image?.data?.id,
          ...action.payload?.attributes?.project_image?.data?.attributes,
        },
      };

      const tempProjectList = [...state.projects];
      state.projects = tempProjectList.map((project) => {
        if (project.project_id === updatedProject.project_id) {
          return updatedProject;
        }
        return project;
      });
    },
  },
});

export const { addProject, deleteProject, updateProject, setProjects } =
  projectList.actions;
export default projectList.reducer;
