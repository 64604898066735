import { createSlice } from "@reduxjs/toolkit";

const avatarSlice = createSlice({
  name: "avatarSlice",
  initialState: {
    avatars: [],
    avatarLoading: false
  },
  reducers: {
    addNewAvatar: (state, action) => {
      const data = action.payload.data;
      state.avatars = [...state.avatars, data];
    },
    setLoading: (state, action) => {
      const avatarLoading = action.payload.avatarLoading
      state.avatarLoading = avatarLoading
    }
  },
});

export const { addNewAvatar } = avatarSlice.actions;

export default avatarSlice.reducer;
