import React from 'react';
import { Link } from 'react-router-dom';

const Breadcrumbs = ({ category }) => {
  return (
    <nav className="text-sm mb-4">
      <Link to="/" className="text-blue-500">Home</Link> &gt; 
      <Link to="/docs" className="text-blue-500">Docs</Link> &gt; 
      <span className="text-gray-500"> {category}</span>
    </nav>
  );
};

export default Breadcrumbs;