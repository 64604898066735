import React, { useEffect, useState } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import RightPanel from '../../components/RightPanel/RightPanel';
import RevealSlide from '../../components/RevealSlide/RevealSlide';
import LinkViewPopup from '../../components/LinkViewPopup';
import JumpToSlidePopup from '../../components/JumpToSlidePopup';
import Header from '../../components/Header/Header';
import { useSelector } from 'react-redux';
import ContextMenu from '../../components/ContextMenu/ContextMenu';
import VideoControl from '../../components/VideoControls/VideoControls';
import Sidebar from '../../components/Sidebar/Sidebar';

library.add(fas);

const EditorContainer = () => {
  const { activeObject, isContext, videoControl } = useSelector(
    (state) => state.fabricCanvas
  );
  const [activeComponent, setActiveComponent] = useState('text');
  const { show: showCropper } = useSelector((state) => state.cropper);
  useEffect(() => {
    if (activeObject && !showCropper) {
      switch (activeObject.type) {
        case 'text':
          setActiveComponent('text');
          break;
        case 'image':
          // case 'rect':
          if (activeObject.name === 'video') setActiveComponent('video');
          else setActiveComponent('image');
          break;
        case 'polygon':
        case 'circle':
        case 'path':
        case 'group':
        case 'line':
        case 'svg':
          setActiveComponent('shape');
          break;
        case 'audio':
          setActiveComponent('audio');
          break;
        case 'components':
          setActiveComponent('components');
          break;
        case 'docs':
          setActiveComponent('docs');
          break;
        case 'button':
        case 'textInput':
        case 'card':
          setActiveComponent('components');
          break;
        case 'quiz':
          setActiveComponent('quiz');
          break;
        case "avatar":
          setActiveComponent('avatar');
          break;
        default:
          setActiveComponent('text');
      }
    } else {
      setActiveComponent('text');
    }
  }, [activeObject, showCropper]);

  // Function to handle changes in activeComponent
  const handleActiveComponentChange = (component) => {
    setActiveComponent(component);
  };
  return  (
    <>
      <Header />
      <div
        className="flex h-screen"
        style={{ maxHeight: "calc(100vh - 80px)" }}
      >
        {isContext && <ContextMenu />}
        {videoControl && <VideoControl />}
        <LinkViewPopup />
        <JumpToSlidePopup />
        <Sidebar onActiveComponentChange={handleActiveComponentChange} />
        <RevealSlide />
        <RightPanel activeComponent={activeComponent} />
      </div>
    </>
  ) ;
}

export default EditorContainer;
