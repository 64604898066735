import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Topbar from '../../Topbar/Topbar';// Adjust the import path as necessary
import Breadcrumbs from '../../../../components/Breadcrumbs/Breadcrumbs'; // Adjust the import path as necessary
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import FontAwesomeIcon
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'; // Import faMagnifyingGlass icon

const CategoryArticles = () => {
  const { slug } = useParams(); // Get the category slug from the URL
  const [articles, setArticles] = useState([]);
  const [expandedArticle, setExpandedArticle] = useState(null);

  const toggleArticle = (id) => {
    setExpandedArticle(expandedArticle === id ? null : id);
  };

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await fetch(`https://backend.ailms.co/api/articles?filters[category][Slug][$eq]=${slug}`);
        const data = await response.json();
        setArticles(data.data); // https://backend.ailms.co/api/categories
      } catch (error) {
        console.error('Error fetching articles:', error);
      }
    };

    fetchArticles();
  }, [slug]);

  return (
    <div>
      <Topbar />
      <div className="p-4 md:p-8">
        <div className="flex flex-col md:flex-row justify-between items-center mb-4 bg-gray-100 p-4 rounded-lg">
          <div className="flex items-center h-full"> {/* Center breadcrumbs vertically */}
            <Breadcrumbs category={slug} />
          </div>
          <div className="relative w-full md:w-1/3 mt-4 md:mt-0 md:max-w-xs">
            <input
              type="text"
              placeholder="Search articles..."
              className="w-full p-2 pl-10 rounded-full text-gray-800"
            />
            <FontAwesomeIcon icon={faMagnifyingGlass} className="w-4 h-4 absolute left-3 top-3 text-gray-400" />
          </div>
        </div>
        <h2 className="text-2xl font-semibold mb-4">Articles in {slug}</h2>
        <div className="flex flex-col md:flex-row">
          <div className="w-full md:w-2/3 pr-0 md:pr-4">
            {articles.length > 0 ? (
              articles.map((article) => (
                <div key={article.id} className="bg-white p-4 rounded-lg shadow mb-4">
                  <h3
                    className="text-lg font-semibold cursor-pointer"
                    onClick={() => toggleArticle(article.id)}
                  >
                    {article.attributes.Title}
                  </h3>
                  {expandedArticle === article.id && (
                    <div className="mt-2">
                      {article.attributes.Content ? (
                        article.attributes.Content.map((content, index) => (
                          <div key={index}>
                            {content.type === 'paragraph' && content.children.map((child, childIndex) => (
                              <span key={childIndex}>
                                {child.type === 'text' && child.text}
                                {child.type === 'link' && <a href={child.url}>{child.children[0].text}</a>}
                              </span>
                            ))}
                          </div>
                        ))
                      ) : (
                        <p>No content available</p>
                      )}
                    </div>
                  )}
                </div>
              ))
            ) : (
              <p>No articles found for this category.</p>
            )}
          </div>
          <div className="p-4">
              <h2 className="text-2xl font-semibold mb-4">Need Support?</h2>
              <p>Can't find the answer you're looking for? We're here to help!</p>
              <button className="mt-4 bg-teal-500 text-white p-4 rounded-lg w-full">Contact Support</button>
            </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryArticles;
